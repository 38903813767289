import { orderApi } from '@/slices/gom/apis/orderApi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stat } from 'fs';
import { get } from 'lodash';
import { FlightData, Schedules } from '../../flightData/slices/index.d';

export interface ISelectedFlight {
  flight: FlightData | null;
  returnFlight: FlightData | null;
  flightPriceDiff: number;
  discountAmount: number;
  returnDiscountAmount: number;
  returnFlightPriceDiff: number;
  flightId?: string;
  returnFlightId?: string;
  totalFare?: number;
  departFare?: number;
  priceIsSet?: boolean;
}

const initialState: ISelectedFlight = {
  flight: null,
  returnFlight: null,
  flightPriceDiff: 0,
  discountAmount: 0,
  returnDiscountAmount: 0,
  returnFlightPriceDiff: 0,
  totalFare: 0,
  departFare: 0,
  priceIsSet: false,
};

export const slice = createSlice({
  name: 'selectedFlight',
  initialState,
  reducers: {
    addAllSelectedFlight: (state, action: PayloadAction<ISelectedFlight>) => {
      state = action.payload;
      return state;
    },
    addFlight: (state, action: PayloadAction<FlightData>) => {
      state.flight = action.payload as any;
      return state;
    },
    addReturnFlight: (state, action: PayloadAction<FlightData>) => {
      state.returnFlight = action.payload as any;
      return state;
    },
    setFlightPriceDiff: (state, action: PayloadAction<number>) => {
      state.flightPriceDiff = action.payload;
      state.returnFlightPriceDiff = 0;
      return state;
    },
    setFlightDiscountAmount: (state, action: PayloadAction<number>) => {
      state.discountAmount = action.payload;
      return state;
    },
    setReturnFlightDiscountAmount: (state, action: PayloadAction<number>) => {
      state.returnDiscountAmount = action.payload;
      return state;
    },
    setReturnFlightPriceDiff: (state, action: PayloadAction<number>) => {
      state.flightPriceDiff = 0;
      state.returnFlightPriceDiff = action.payload;
      return state;
    },
    removeFlight: (state) => {
      state.flight = null;
      state.flightPriceDiff = 0;
      return state;
    },
    removeReturnFlight: (state) => {
      state.returnFlight = null;
      state.flightPriceDiff = 0;
      return state;
    },
    updatePriceForFlight: (state, action: PayloadAction<TPriceObj>) => {
      if (state.flight && get(state, 'flight.route.schedules[0]')) {
        state.flight = updatePriceHelper(state.flight, action.payload);
      }
      return state;
    },
    updatePriceForReturnFlight: (state, action) => {
      if (state.returnFlight && get(state, 'returnFlight.route.schedules[0]')) {
        state.returnFlight = updatePriceHelper(
          state.returnFlight,
          action.payload,
        );
      }
      return state;
    },
    setTotalFare: (state, action: PayloadAction<number>) => {
      state.totalFare = action.payload;
      return state;
    },
    setDepartFare: (state, action: PayloadAction<number>) => {
      state.departFare = action.payload;
      return state;
    },
    setIsChangedFlightPrice: (state, action: PayloadAction<number>) => {
      state.totalFare = action.payload;
      state.priceIsSet = true;
      return state;
    },
    resetIsChangedPrice: (state) => {
      state.priceIsSet = false;
      return state;
    },
    updateFlightId: (state, action: PayloadAction<{ idChd: boolean }>) => {
      if (action?.payload?.idChd && state?.flight) {
        state.flight.idIndex = state?.flight?.idIndex?.replace('adt', 'chd-adt');
      } else if (!action?.payload?.idChd && state?.flight) {
        state.flight.idIndex = state?.flight?.idIndex?.replace('chd-adt', 'adt');
      }
      return state;
    },
    updateReturnFlightId: (state, action: PayloadAction<{ idChd: boolean }>) => {
      if (action?.payload?.idChd && state?.returnFlight && state?.returnFlight?.idIndex) {
        state.returnFlight.idIndex = state?.returnFlight?.idIndex?.replace('adt', 'chd-adt');
      } else if (!action?.payload?.idChd && state?.returnFlight && state?.returnFlight?.idIndex) {
        state.returnFlight.idIndex = state?.returnFlight?.idIndex?.replace('chd-adt', 'adt');
      }
      return state;
    },
    resetFlightDiff: (state) => {
      state.flightPriceDiff = 0;
      state.returnFlightPriceDiff = 0;
      state.discountAmount = 0;
      state.returnDiscountAmount = 0;
    },
  },
});

const updatePriceHelper = (flight: FlightData, payload: TPriceObj) => {
  const {
    fareAdt: fareAdultOriginal,
    fareChd: fareChildOriginal,
    fareInf,
    feeAdt,
    feeChd,
    feeInf,
    serviceFeeAdt,
    serviceFeeChd,
    serviceFeeInf,
    taxAdt,
    taxChd,
    taxInf,
  } = payload;
  // const discountAmount = get(flight, 'route.schedules[0].discountAmount') || 0;
  const { airline } = flight;
  const VXR_MARKUP = flight.route.schedules[0].markupAmount;

  const fareAdt = fareAdultOriginal + VXR_MARKUP;
  const fareChd = fareChildOriginal + VXR_MARKUP;

  const totalPriceAdult = fareAdt + feeAdt + serviceFeeAdt + taxAdt;
  const totalPriceChild = fareChd + feeChd + serviceFeeChd + taxChd;
  const totalPriceInfant = fareInf + feeInf + serviceFeeInf + taxInf;
  const totalPrice = totalPriceAdult + totalPriceChild + totalPriceInfant;

  const totalPriceAdultOriginal = totalPriceAdult - fareAdt + fareAdultOriginal;
  const totalPriceChildOriginal = totalPriceChild - fareChd + fareChildOriginal;

  const schedule: Schedules = {
    ...flight.route.schedules[0],
    fareAdult: fareAdt,
    fareChild: fareChd,
    fareInfant: fareInf,
    feeAdult: feeAdt,
    feeChild: feeChd,
    feeInfant: feeInf,
    serviceFeeAdult: serviceFeeAdt,
    serviceFeeChild: serviceFeeChd,
    serviceFeeInfant: serviceFeeInf,
    taxAdult: taxAdt,
    taxChild: taxChd,
    taxInfant: taxInf,
    totalPriceAdult,
    totalPriceChild,
    totalPriceInfant,
    totalPrice,
    fareAdultOriginal,
    fareChildOriginal,
    totalPriceAdultOriginal,
    totalPriceChildOriginal,
  };
  flight.route.schedules[0] = schedule;
  return flight;
};

export type TPriceObj = {
  fareAdt: number;
  fareChd: number;
  fareInf: number;
  taxAdt: number;
  taxChd: number;
  taxInf: number;
  feeAdt: number;
  feeChd: number;
  feeInf: number;
  serviceFeeAdt: number;
  serviceFeeChd: number;
  serviceFeeInf: number;
};

export const { reducer: selectedFlightReducer } = slice;
export const {
  addFlight,
  addReturnFlight,
  removeFlight,
  removeReturnFlight,
  setFlightPriceDiff,
  addAllSelectedFlight,
  setReturnFlightPriceDiff,
  setFlightDiscountAmount,
  setReturnFlightDiscountAmount,
  updatePriceForFlight,
  updatePriceForReturnFlight,
  setTotalFare,
  setDepartFare,
  setIsChangedFlightPrice,
  resetIsChangedPrice,
  updateFlightId,
  updateReturnFlightId,
  resetFlightDiff,
} = slice.actions;
