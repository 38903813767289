import { uniqueId } from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TBagage } from '../slices/type';
/* eslint-disable @typescript-eslint/no-var-requires */

const camelcaseKeysDeep = require('camelcase-keys-deep');

type TFlightValue = {
  flightValue: string;
};

type TfareRuleApiParams = {
  session: string;
  listFlight: TFlightValue[];
  fareDataId: number;
};

export const baggageApi = createApi({
  reducerPath: 'baggageApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getBaggages: builder.query<TBagage, TfareRuleApiParams[]>({
      query: (data) => ({
        url: '/api/getBaggage',
        method: 'POST',
        body: { ListFareData: data },
      }),
      transformResponse: (response) => {
        const mapData = camelcaseKeysDeep(response);
        mapData.listBaggage = mapData.listBaggage.map((baggage: any) => ({
          ...baggage,
          id: uniqueId(),
        }));
        return mapData;
      },
      keepUnusedDataFor: 3,
    }),

  }),

});

export const { useGetBaggagesQuery } = baggageApi;
