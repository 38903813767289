/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { IBusResultItem } from '@/containers/Bus/SearchBusTab/SearchBusResultItem/type';
import { IPassengerSearchForm } from '@/slices/passenger/slices/type';
// import { LAST_SEARCH_FIND_ROUTE_BUS } from '@/utils/constants';
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
/* eslint-disable max-len */
import type { BookingType } from '../../../../containers/Bus/SearchBusTab/ChooseBookingType/type';

export const searchAdapter = createEntityAdapter();

export interface ISearchBusRouteForm {
  startPoint: IBusResultItem | null;
  toPoint: IBusResultItem | null;
  startPointHome: IBusResultItem | null;
  startPointHome2: IBusResultItem | null;
  toPointHome: IBusResultItem | null;
  toPointHome2: IBusResultItem | null;
  passenger: IPassengerSearchForm;
  bookingType: BookingType
}

// const recentSearchKey = LAST_SEARCH_FIND_ROUTE_BUS;
const recentSearch: { [key: string]: any } = {};
// if (typeof window !== 'undefined') {
//   const { localStorage } = window;
//   const recentSearchString = localStorage.getItem(recentSearchKey);
//   recentSearch = JSON.parse(recentSearchString || '{}');
// }

const initialState: ISearchBusRouteForm = {
  startPoint: null,
  toPoint: null,
  startPointHome: recentSearch?.tmpStartPoint || null,
  startPointHome2: recentSearch?.tmpStartPoint || null,
  toPointHome: recentSearch?.tmpToPoint || null,
  toPointHome2: recentSearch?.tmpToPoint || null,
  passenger: recentSearch?.tmpPassenger || {
    adt: 1,
    chd: 0,
    inf: 0,
  },
  bookingType: recentSearch?.bookingType || 'OW',
};

export const slice = createSlice({
  name: 'searchBusForm',
  initialState,
  reducers: {
    clearPoints: (
      state,
      action: PayloadAction<{ type: 'from' | 'to' | 'all' }>,
    ) => {
      const { payload } = action;

      if (payload.type === 'all') {
        state.startPoint = null;
        state.startPointHome = null;
        state.toPoint = null;
        state.toPointHome = null;
      } else if (payload.type === 'from') {
        state.startPoint = null;
        state.startPointHome = null;
      } else {
        state.toPoint = null;
        state.toPointHome = null;
      }
    },
    addPoints: (
      state,
      action: PayloadAction<{
        data: IBusResultItem | null;
        type: 'from' | 'to';
      }>,
    ) => {
      const { payload } = action;

      if (payload.type === 'from') {
        state.startPoint = payload.data;
      } else {
        state.toPoint = payload.data;
      }
    },
    addAllPoints: (
      state,
      action: PayloadAction<
      { data: IBusResultItem | null; type: 'from' | 'to' }[]
      >,
    ) => {
      const { payload } = action;
      payload.forEach((point) => {
        if (point.type === 'from') {
          state.startPoint = point.data;
        } else {
          state.toPoint = point.data;
        }
      });
    },
    swapPoints: (state) => {
      const _startPoint = state.startPoint;
      const _toPoint = state.toPoint;

      state.startPoint = _toPoint;
      state.toPoint = _startPoint;
    },
    addHomePoints: (
      state,
      action: PayloadAction<{
        data: IBusResultItem | null;
        type: 'from' | 'to';
      }>,
    ) => {
      const { payload } = action;

      if (payload.type === 'from') {
        state.startPointHome = payload.data;
      } else {
        state.toPointHome = payload.data;
      }
    },
    addAllHomePoints: (
      state,
      action: PayloadAction<
      { data: IBusResultItem | null; type: 'from' | 'to' }[]
      >,
    ) => {
      const { payload } = action;

      payload.forEach((point) => {
        if (point.type === 'from') {
          state.startPointHome = point.data;
        } else {
          state.toPointHome = point.data;
        }
      });
    },
    addAllPointsHomePoints: (
      state,
      action: PayloadAction<
      { data: IBusResultItem | null; type: 'from' | 'to' }[]
      >,
    ) => {
      const { payload } = action;

      payload.forEach((point) => {
        if (point.type === 'from') {
          state.startPoint = point.data;
          state.startPointHome = point.data;
        } else {
          state.toPoint = point.data;
          state.toPointHome = point.data;
        }
      });
    },
    addFallbackPoints: (state) => {
      state.startPointHome2 = state.startPointHome;
      state.toPointHome2 = state.toPointHome;
    },
    loadFallbackPoints: (state) => {
      state.startPointHome = state.startPoint || state.startPointHome2;
      state.toPointHome = state.toPoint || state.toPointHome2;
    },
    clearHomePoints: (
      state,
      action: PayloadAction<{ type: 'from' | 'to' | 'all' }>,
    ) => {
      const { payload } = action;

      if (payload.type === 'all') {
        state.startPointHome = null;
        state.toPointHome = null;
      } else if (payload.type === 'from') {
        state.startPointHome = null;
      } else {
        state.toPointHome = null;
      }
    },
    adjustPassenger: (state, action: PayloadAction<IPassengerSearchForm>) => {
      const { payload } = action;
      if (payload) {
        state.passenger.adt = Number(payload.adt);
      }
    },
    toggleArea: (state) => {
      const { startPoint } = state;
      state.startPoint = state.toPoint;
      state.toPoint = startPoint;
    },
  },
});

export type TAddPoint = (params: IBusResultItem) => void;

export const { reducer: searchBusReducer } = slice;
export const {
  clearPoints,
  addPoints,
  adjustPassenger,
  toggleArea,
  addHomePoints,
  clearHomePoints,
  swapPoints,
  addFallbackPoints,
  loadFallbackPoints,
  addAllHomePoints,
  addAllPoints,
  addAllPointsHomePoints,
} = slice.actions;
