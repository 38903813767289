import { TSelectedBaggageItem } from '@/slices/baggage/slices/type';
import { TPassenger } from '@/slices/passenger/slices/type';
import { ISearchFlightForm } from '@/slices/area/slices/searchFlightFormSlice';
import { ISelectedFlight } from '@/slices/selectedFlight/slices/selectedFlightSlice';
import { TSelectedDate } from '../../calendar/index.d';

export enum BookingFlightStatusEnum {
  ISSUED = 'issued',
  CANCEL = 'cancel',
  PENDING = 'pending',
  PENDING_PAY = 'PENDING_PAY',
  PENDING_VERIFY = 'PENDING_VERIFY',
  EXIPRED_PAY = 'EXIPRED_PAY',
  NOT_ISSUED = 'not issued',
  USED = 'used',
}

export enum BookingGomStatusEnum {
  NEW = 1,
  PAID = 2,
  REFUNDED = 3,
  CANCELED = 4,
  PARTIAL_PAID = 5,
  EXPIRED = 6,
}

export enum BookingBusVapiStatusEnum {
  USED = 0,
  NEW = 1,
  PARTIAL_PAID = 3,
  PAID = 4,
  CANCELED = 5,
}

export type TFlightBookingIssued = {
  status: BookingFlightStatusEnum.ISSUED;
};
export type TFlightBookingNotIssued = {
  status: BookingFlightStatusEnum.NOT_ISSUED;
};

export type TFLightBookingCancel = {
  status: BookingFlightStatusEnum.CANCEL;
};
export type TFightBookingPending = {
  status: BookingFlightStatusEnum.PENDING;
  timeReserveTicket: string;
  timeExpireTicket: string;
};
export type TFightBookingUsed = {
  status: BookingFlightStatusEnum.USED;
};
export type TFightPendingPay = {
  status: BookingFlightStatusEnum.PENDING_PAY;
};
export type TFightPendingVerify = {
  status: BookingFlightStatusEnum.PENDING_VERIFY;
  timeClick: string;
};
export type TFightExiprePay = {
  status: BookingFlightStatusEnum.EXIPRED_PAY;
};

export type TMyFlightBookingInfo =
  | TFightBookingPending
  | TFlightBookingIssued
  | TFlightBookingNotIssued
  | TFLightBookingCancel
  | TFightBookingUsed
  | TFightExiprePay
  | TFightPendingVerify
  | TFightPendingPay;

export type TMyFlightBooking = {
  id: number;
  bookingCode: string;
  flightsData: ISelectedFlight;
  bookingInfo: TMyFlightBookingInfo;
  paymentQueryPages: {
    phone: string;
    email: string;
    value: string;
    code: string;
    id: number;
  };
  calendar: {
    selectedDate: TSelectedDate,
    chooseReturn: boolean,
  },
  searchFlightForm: ISearchFlightForm,
  paymentGateway?: string;
  bankCode?: string;
  isComfirmAlreadyPaid?: boolean;
  selectedBaggages: {
    depart: TSelectedBaggageItem[],
    return: TSelectedBaggageItem[]
  }
  selectedPassengerArr: TPassenger[];
  surveyCount?: number
  totalDiscountDepartPrice?: number
  totalDiscountReturnPrice?: number
};

export type TMyBookingSlice = {
  flights: TMyFlightBooking[];
};
