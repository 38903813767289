import { pr } from '@/utils/pxToRem';

export const style = {
  color: {
    product1: '#0E8685',
    product2: '#109795',
    product3: '#12A8A6',
    product4: '#ECF8F7',
    white: '#FFFFFF',
    white1: '#E8EDF1',
    white2: '#EFF2F5',
    white3: '#F5F7F9',
    white4: '#FFFFFF',
    black1: '#252A31',
    black2: '#425062',
    black3: '#667487',
    black4: '#8897A5',
    black5: '#B2BDC4',
    black6: '#D5DADD',
    black7: '#EEF2F4',
    black8: '#B2BDC3',
    red1: '#F5222D',
    red2: '#970C0C',
    red3: '#D21C1C',
    red4: '#FAEAEA',
    green2: '#2B7336',
    green3: '#2BA138',
    green4: '#EBF4EC',
    green5: '#00BD9E',
    orange2: '#AE5700',
    orange3: '#E98305',
    orange4: '#FDF0E3',
    orange5: '#F97C00',
    blue2: '#005AA3',
    blue3: '#0172CB',
    blue4: '#E8F4FD',
    blue5: '#D0E9FB',
    yellow2: '#FFC600',
  },
  boxShadow: {
    boxShadow01: '0px 4px 24px rgba(7, 65, 57, 0.08)',
    boxShadow02: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    customShadow: '0px 0px 10px rgba(50, 50, 50, 0.1)',
    popupShadow:
      '0px 0px 0px 1px rgba(28, 59, 84, 0.05), 0px 2px 6px -2px rgba(28, 59, 84, 0.06), 0px 8px 12px -3px rgba(28, 59, 84, 0.1)',
  },
  text: {
    base: '14px',
    content: {
      regular: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: pr`14px`,
        lineHeight: pr`20px`,
      },
      regularBold: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: pr`14px`,
        lineHeight: pr`20px`,
      },
      bold: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: pr`14px`,
        lineHeight: pr`20px`,
      },
      hyperlink: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: pr`14px`,
        lineHeight: pr`20px`,
        color: '#12A8A6',
        textDecoration: 'underline',
      },
    },
    caption: {
      smallBold: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: pr`10px`,
        lineHeight: pr`16px`,
      },
      medium: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: pr`12px`,
        lineHeight: pr`16px`,
        letterSpacing: '0.03rem',
      },
      regular: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: pr`12px`,
        lineHeight: pr`16px`,
      },
      italic: {
        fontSize: pr`12px`,
        lineHeight: pr`16px`,
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
    },
    title: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pr`18px`,
      lineHeight: pr`24px`,
    },
    heading: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pr`22px`,
      lineHeight: pr`28px`,
    },
    heading1: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: pr`28px`,
      lineHeight: pr`36px`,
    },
    heading2: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: pr`22px`,
      lineHeight: pr`28px`,
    },
    heading3: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: pr`20px`,
      lineHeight: pr`28px`,
    },
    vexHeading2: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: pr`24px`,
      lineHeight: pr`32px`,
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: pr`22px`,
      lineHeight: pr`28px`,
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: pr`18px`,
      lineHeight: pr`24px`,
    },
  },
  spacingSize: {
    xxSmall: pr`4px`, // 4px
    xSmall: pr`8px`,
    small: pr`12px`,
    medium: pr`16px`, // 16px
    large: pr`24px`,
    xLarge: '2.2125rem',
    xxLarge: '2.875rem',
    xxxLarge: '5rem', // 80px
  },
  spacing: {
    listBadgeLabel: pr`4px`,
    divier: pr`8px`,
    button: pr`8px`,
    iconDescription: pr`8px`,
    padding: {
      small: pr`8px`,
      card: pr`16px`,
      button: pr`12px`,
      alert: pr`12px`,
      modal: pr`12px`,
      section: pr`12px`,
      popOverOthers: pr`16px`,
      popOverTop: pr`24px`,
      pageLeftRight: pr`16px`,
      pageTop: pr`46px`,
      pageBottom: pr`34px`,
      bodyTop: pr`16px`,
    },
    input: pr`8px`,
    inputWithTitle: pr`16px`,
    inputToButton: pr`24px`,
    listItemWithDivider: pr`16px`,
    listItemClickable: pr`24px`,
    listItemReadOnly: pr`16px`,
    sectionSameBackground: pr`24px`,
    sectionHeaderToBody: pr`16px`,
    sectionBodyToBody: pr`8px`,
    image: pr`80px`,
    loading: pr`80px`,
  },
  borderRadius: {
    searchBox: pr`48px`,
    modal: pr`16px`,
    popup: pr`16px`,
    tab: pr`16px`,
    badge: pr`12px`,
    button: pr`6px`,
    checkbox: pr`6px`,
    input: pr`6px`,
  },
  primary: {
    blue: '#2474E5',
    navy: '#0D2E59',
    yellow: '#FFD333',
    black: '#000',
    blueLight: '#7FAEF0',
  },
  neutral: {
    white: '#fff',
    dark: '#474747',
    black: '#141414',
    gray: '#858585',
    border: '#E0E0E0',
    lightBorder: '#E0E0E0',
  },
  semantic: {
    information: '#2F80ED',
    informationLightest: '#ECF4FD',
  },
};
