import { TSelectedBaggageItem } from '@/slices/baggage/slices/type';
import { TSelectedDate } from '@/slices/calendar';
import { TPassenger } from '@/slices/passenger/slices/type';
import { ISearchBusRouteForm } from '../../area/slices/searchBusFormSlice';
import { ISelectedBusRoute } from '../../selectedBusRoute/slices/selectedBusRouteSlice';

export const BookingBusRouteStatusMap = [
  'NONE', 'NEW', 'PAID', 'REFUNDED', 'CANCELED', 'PARTIALLY_PAID', 'EXPIRED',
];

export enum BookingBusRouteStatusEnum {
  ISSUED = 'issued',
  CANCEL = 'cancel',
  PENDING = 'pending',
  NOT_ISSUED = 'not issued',
  USED = 'used',
}

export type TBusRouteBookingIssued = {
  status: BookingBusRouteStatusEnum.ISSUED;
};
export type TBusRouteBookingNotIssued = {
  status: BookingBusRouteStatusEnum.NOT_ISSUED;
};

export type TBusRouteBookingCancel = {
  status: BookingBusRouteStatusEnum.CANCEL;
};
export type TBusRouteBookingPending = {
  status: BookingBusRouteStatusEnum.PENDING;
  timeReserveTicket: string;
};
export type TBusRouteBookingUsed = {
  status: BookingBusRouteStatusEnum.USED;
};

export type TMyBusRouteBookingInfo =
  | TBusRouteBookingPending
  | TBusRouteBookingIssued
  | TBusRouteBookingNotIssued
  | TBusRouteBookingCancel
  | TBusRouteBookingUsed;

export type TMyBusRouteBooking = {
  bookingReturnInfo?: {
    ticketCode: string;
    bookingCode: string;
    departureTime: string;
    arrivalTime: string;
    baseCode: string;
    status: string;
    statusCode: string;
    timeReserveTicket: string;
  };
  bookingType: string;
  id: number;
  bookingCode: string;
  ticketCode: string;
  busRoutesData: ISelectedBusRoute;
  bookingInfo: TMyBusRouteBookingInfo & {
    statusCode: number | string;
    departureTime: string;
    arrivalTime: string;
  };
  paymentQueryPages: {
    phone: string;
    email: string;
    value: string;
    code: string;
    id: number;
  };
  calendar: {
    selectedDate: TSelectedDate,
    chooseReturn: boolean,
  },
  searchBusRouteForm?: ISearchBusRouteForm,
  paymentGateway?: any;
  selectedBaggages: {
    depart: TSelectedBaggageItem[],
    return: TSelectedBaggageItem[]
  }
  selectedPickDropPoint: any,
  selectedPassengerArr: TPassenger[];
  createDate: string;
};

export type TMyBookingSlice = {
  busRoutes: TMyBusRouteBooking[];
};
