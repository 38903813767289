// import { LIST_BUS_BOOKING_SURVEY } from '@/utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TMyBookingSurveySlice = {
  url: string;
  vexHostPath: string;
  finalPath: string;
  hostSeo?: string
};

// if (typeof window !== 'undefined') {
//   const { localStorage } = window;
//   const listBookingSurvey = localStorage.getItem(LIST_BUS_BOOKING_SURVEY);
//   myBookingSurveyData = JSON.parse(listBookingSurvey || '[]');
// }

// const setState = (state: TMyBookingSurveySlice[]) => {
//   localStorage.removeItem(LIST_BUS_BOOKING_SURVEY);
//   localStorage.setItem(LIST_BUS_BOOKING_SURVEY, JSON.stringify(state));
// };

const initialState: TMyBookingSurveySlice = {
  url: '',
  vexHostPath: '',
  finalPath: '',
  hostSeo: '',
};

export const slice = createSlice({
  name: 'vex',
  initialState,
  reducers: {
    updateUrlVexToGoyolo: (state, action: PayloadAction<{ url: string, }>) => {
      state.url = action.payload.url;
    },
    updateVexHostPath: (state, action: PayloadAction<{ vexHostPath: string, finalPath: string, hostSeo?: string }>) => {
      state.vexHostPath = action.payload.vexHostPath;
      state.finalPath = action.payload.finalPath;
      state.hostSeo = action.payload.hostSeo;
    },
  },
});

export const { reducer: vexSeoReducer } = slice;
export const {
  updateUrlVexToGoyolo,
  updateVexHostPath,
} = slice.actions;
