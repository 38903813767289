import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '../../common/fetchAuthedQuery';
import {
  BankResult, IBusVexBooking, PolicyData,
} from './type';

export const vApi = createApi({
  reducerPath: 'vApi',
  baseQuery: fetchAuthedQuery({
    baseUrl: `${process.env.VAPI_URL}/v3`,
  }),
  endpoints: (builder) => ({
    getListBanks: builder.query<BankResult[], void>({
      query: () => '/resource/bank',
      transformResponse: (response: { data: BankResult[] }) => response.data,
      keepUnusedDataFor: 600,
    }),
    getPolicy: builder.query<PolicyData, { tripCode: string }>({
      query: (query) => `cancellation/policy?trip_code=${query.tripCode}`,
      transformResponse: (response: { data: PolicyData }) => response.data,
      keepUnusedDataFor: 600,
    }),
    getVexBusBooking: builder.query<IBusVexBooking[], { code: string }>({
      query: ({ code }) => `/booking?code=${code}`,
      transformResponse: (response: { data: IBusVexBooking[] }) => response.data,
      keepUnusedDataFor: 600,
    }),
    cancelBusBookingByCode: builder.query<{ data: any }, { code: string }>({
      query: ({ code }) => ({
        url: `/booking/${code}/refund`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetListBanksQuery, useLazyGetPolicyQuery, useLazyGetVexBusBookingQuery, useGetVexBusBookingQuery, useLazyCancelBusBookingByCodeQuery,
} = vApi;
