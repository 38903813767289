export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID || 'G-Y9WYGGXDNY';
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (!(window as any).gtag) return;
  (window as any)?.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagEvent = ({
  action, data,
}: { action: string, data: any }) => {
  if (!(window as any).gtag) return;
  (window as any).gtag('event', action, data);
};
