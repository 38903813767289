/* eslint-disable import/no-cycle */
import {
  createEntityAdapter, createSlice,
} from '@reduxjs/toolkit';
import type { RootState } from '../../../utils/store';
import type { FlightData } from '.';
import { flightDataApi, TFlightCountResponse } from '../apis/flightDataApi';
import { concat, get, isEmpty } from 'lodash';
import { PriceTypeEnum } from '@/slices/gom/apis/index.d';
import FlightStatisticsInterval from '@/utils/FlightStatisticsInterval';

export const loadingInterval = new FlightStatisticsInterval();
export const flightDataAdapter = createEntityAdapter<FlightData>();
const initialState = flightDataAdapter.getInitialState({
  seoCountResponse: null as any,
  flightData: [] as FlightData[] | [],
  dataStatistics: null as TFlightCountResponse | null,
  dataStatistics2: null as TFlightCountResponse | null,
  oldFlightData: null as FlightData[] | null,
  oldDataStatistics: null as TFlightCountResponse | null,
  oldDataStatistics2: null as TFlightCountResponse | null,
  newFlightData: [] as FlightData[] | [],
  newDataStatistics: null as TFlightCountResponse | null,
  isDone: false,
  total: 0 as number,
  page: 0 as number,
  totalPages: 0 as number,
  requestQueryString: '' as string,
  lastRequestObjStr: '' as string,
  flightErrorId: null as string | null,
  minFareToday: null as number | null,
  progressLoading: null as { [key: string]: { [key: string]: string } } | null,
});

export const slice = createSlice({
  name: 'flightData',
  initialState,
  reducers: {
    removeFlightData: flightDataAdapter.removeOne,
    addSeoResponse: (state, action) => {
      state.seoCountResponse = action.payload;
    },
    resetFlightData: (state) => {
      state.isDone = false;
      state.flightData = [];
      state.dataStatistics = null;
      state.dataStatistics2 = null;
      state.newFlightData = [];
      state.newDataStatistics = null;
      state.oldFlightData = state.flightData;
      state.oldDataStatistics = state.dataStatistics;
      state.oldDataStatistics2 = state.dataStatistics2;
      state.flightErrorId = null;
      state.minFareToday = null;
    },
    resetDataStatistics: (state) => {
      state.dataStatistics = null;
      state.dataStatistics2 = null;
      state.newDataStatistics = null;
      state.oldDataStatistics = null;
      state.oldDataStatistics2 = null;
    },
    setRequestQueryString: (state, action) => {
      state.requestQueryString = action.payload || '';
    },
    setLastRequestObjStr: (state, action) => {
      state.lastRequestObjStr = action.payload || '';
    },
    setFlightDataIsDone: (state, action) => {
      state.isDone = action.payload || false;
    },
    setFlightErrorId: (state, action) => {
      state.flightErrorId = action.payload;
    },
    updateFlight: (state, action) => {
      if (action.payload?.idIndex) {
        const idIndex = state.flightData?.findIndex((flight) => flight?.idIndex === action.payload?.idIndex);
        if (idIndex >= 0) {
          state.flightData[idIndex] = action.payload;
        }
      }
    },
    updateMinFareToday: (state, action) => {
      state.minFareToday = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(flightDataApi.endpoints.getFlightData2.matchFulfilled, (state, action) => {
        if (action.payload.data) {
          const updateFlightData = concat(
            state.flightData.slice(0, (action.payload.page - 1) * 20),
            action.payload.data,
            state.flightData.slice((action.payload.page + 1) * 20),
          );
          state.newFlightData = updateFlightData;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.total = action.payload.total;
          state.minFareToday = action.payload.minPriceData?.finalPrice;
          addMinPriceToState(state);
        }
        if (action.payload.data && action.payload.data?.length === 0 && action.payload.page === 1) {
          state.flightData = [];
          state.newFlightData = [];
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.total = action.payload.total;
          state.isDone = true;
          state.minFareToday = null;
        }
        if (action.payload.progressLoading) {
          state.progressLoading = action.payload.progressLoading;
        }
      })
      .addMatcher(flightDataApi.endpoints.getFlightStatistics.matchFulfilled, (state, action) => {
        // loadingInterval.triggerTimeout();
        state.newDataStatistics = action.payload;
        addMinPriceToState(state);
      })
      .addMatcher(flightDataApi.endpoints.getFlightStatistics2.matchFulfilled, (state, action) => {
        if (isEmpty(get(action.payload, 'grouped_by_date'))) {
          state.oldDataStatistics2 = state.dataStatistics2;
        }
        state.dataStatistics2 = action.payload;
      });
  },
});
export function addMinPrice(flightData: FlightData[] | null, dataStatistics: TFlightCountResponse | null) {
  return flightData && flightData?.length > 0 ? flightData.map((flight) => {
    const { airline } = flight;
    const VXR_MARKUP = flight.route.schedules[0].markupAmount;
    if (get(flight, 'route.schedules[0].totalPriceAdultOriginal')) {
      return flight;
    }
    if (get(flight, 'route.schedules[0].fareClass')) {
      flight.route.schedules[0].fareClass = (flight.route.schedules[0].fareClass || '').toUpperCase();
    }

    flight.route.schedules[0].totalPriceAdultOriginal = flight.route.schedules[0].totalPriceAdult;
    flight.route.schedules[0].totalPriceAdult += VXR_MARKUP;
    flight.route.schedules[0].fareAdultOriginal = flight.route.schedules[0].fareAdult;
    flight.route.schedules[0].fareAdult += VXR_MARKUP;
    flight.route.schedules[0].totalPriceChildOriginal = flight.route.schedules[0].totalPriceChild;
    flight.route.schedules[0].totalPriceChild += VXR_MARKUP;
    flight.route.schedules[0].fareChildOriginal = flight.route.schedules[0].fareChild;
    flight.route.schedules[0].fareChild += VXR_MARKUP;

    return flight;
  }) : flightData;
}

function addMinPriceToState(state: typeof initialState) {
  if (!(state.newFlightData && state.newDataStatistics)) {
    return;
  }
  const { newFlightData: flightData, newDataStatistics: dataStatistics } = state;

  if (!flightData || !dataStatistics || isEmpty(flightData) || isEmpty(dataStatistics)) {
    return;
  }

  const flightDate = flightData[0].date;
  const matchingStatistic = dataStatistics.find((statistic) => Object.keys(statistic.grouped_by_date).includes(flightDate));
  // if (!matchingStatistic) {
  //   return;
  // }

  const minFareArr = matchingStatistic?.grouped_by_date[flightDate].min_fare_arr;
  // if (!minFareArr || isEmpty(minFareArr)) {
  //   return;
  // }

  // Min fare sorted at vroute

  state.oldFlightData = state.flightData;
  state.oldDataStatistics = state.dataStatistics;
  state.isDone = true;
  state.dataStatistics = dataStatistics;
  state.flightData = flightData.map((flight) => {
    const { airline } = flight;
    const VXR_MARKUP = flight.route.schedules[0].markupAmount;
    if (get(flight, 'route.schedules[0].totalPriceAdultOriginal')) {
      return flight;
    }
    if (get(flight, 'route.schedules[0].fareClass')) {
      flight.route.schedules[0].fareClass = (flight.route.schedules[0].fareClass || '').toUpperCase();
    }
    flight.route.schedules[0].priceTypeArr = [PriceTypeEnum.VXR_MARKUP];

    flight.route.schedules[0].totalPriceAdultOriginal = flight.route.schedules[0].totalPriceAdult;
    flight.route.schedules[0].totalPriceAdult += VXR_MARKUP;
    flight.route.schedules[0].fareAdultOriginal = flight.route.schedules[0].fareAdult;
    flight.route.schedules[0].fareAdult += VXR_MARKUP;
    flight.route.schedules[0].totalPriceChildOriginal = flight.route.schedules[0].totalPriceChild;
    flight.route.schedules[0].totalPriceChild += VXR_MARKUP;
    flight.route.schedules[0].fareChildOriginal = flight.route.schedules[0].fareChild;
    flight.route.schedules[0].fareChild += VXR_MARKUP;

    return flight;
  });

  state.newFlightData = [];
  state.newDataStatistics = [];
}

export const { reducer: flightDataReducer } = slice;
export const {
  removeFlightData, addSeoResponse, resetFlightData, setFlightDataIsDone, setRequestQueryString, setLastRequestObjStr, setFlightErrorId, updateFlight, updateMinFareToday, resetDataStatistics,
} = slice.actions;
export const {
  selectById: selectFlightDataById,
  selectIds: selectFlightDataIds,
  selectEntities: selectFlightDataEntities,
  selectAll: selectAllFlightDatas,
  selectTotal: selectTotalFlightDatas,
} = flightDataAdapter.getSelectors((state: RootState) => state.flightData);
