import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TGtagItems = {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_category3: string;
  item_category4: string;
  item_category5: string;
  item_category6: string;
  item_variant: string;
  location_id: any;
  discount: number;
  quantity: number;
  price: number;
};

type TGtagProduct = {
  item_list_id: string;
  item_list_name: string;
};

type TGtagShippingTier = string;

type TGtag = {
  currency: string;
  value: number;
  coupon: any;
  shipping_tier?: TGtagShippingTier;
  payment_type?: string;
  transaction_id?: string;
  affiliation?: string;
  shipping?: number;
  items: TGtagItems[];
};

type TGtagSlice = {
  gtag: TGtag | null;
  isTrackedTripDetailDepart: boolean;
  isTrackedTripDetailReturn: boolean;
  isTrackedPassengerPage: boolean;
  isTrackedBaggagePage: boolean;
  isTrackedPaySuccessPage: boolean;
};

const initalState: TGtagSlice = {
  gtag: null,
  isTrackedPassengerPage: false,
  isTrackedBaggagePage: false,
  isTrackedPaySuccessPage: false,
  isTrackedTripDetailDepart: false,
  isTrackedTripDetailReturn: false,
};

export const slice = createSlice({
  name: 'gtagSlice',
  initialState: initalState,
  reducers: {
    addGtagData: (state, action: PayloadAction<TGtag>) => {
      state.gtag = action.payload;
      state.isTrackedBaggagePage = false;
      state.isTrackedPassengerPage = false;
      state.isTrackedPaySuccessPage = false;
      state.isTrackedTripDetailDepart = false;
      state.isTrackedTripDetailReturn = false;
      return state;
    },
    updateGtagData: (state, action: PayloadAction<Partial<TGtag>>) => {
      state.gtag = {
        ...state.gtag,
        ...(action.payload as any),
      };
      return state;
    },
    setTrackedBaggagePage: (state) => {
      state.isTrackedBaggagePage = true;
      return state;
    },
    setTrackedPassengerPage: (state) => {
      state.isTrackedPassengerPage = true;
      return state;
    },
    setTrackedPaySuccessPage: (state) => {
      state.isTrackedPaySuccessPage = true;
      return state;
    },
    setTrackedTripDetailDepart: (state, action: PayloadAction<{ value?: boolean }>) => {
      state.isTrackedTripDetailDepart = !!action.payload.value;
      return state;
    },
    setTrackedTripDetailReturn: (state, action: PayloadAction<{ value?: boolean }>) => {
      state.isTrackedTripDetailReturn = !!action.payload.value;
      return state;
    },
    resetGtag: (state) => {
      state = initalState;
      return state;
    },
  },
});

export const { reducer: gtagSliceReducer } = slice;
export const {
  addGtagData,
  resetGtag,
  updateGtagData,
  setTrackedBaggagePage,
  setTrackedPassengerPage,
  setTrackedPaySuccessPage,
  setTrackedTripDetailDepart,
  setTrackedTripDetailReturn,
} = slice.actions;
