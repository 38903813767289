import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '@/slices/common/fetchAuthedQuery';

import {
  language,
  paySource,
  TPayInternalRequest,
  TPayRequest,
  TPayResult,
  TOrderParamsRequest,
  TOrderResult,
} from './type.d';

const getOrder = (response: TOrderResult[], isNotFilterPaid?: boolean) => {
  const order = response.filter((o) => {
    let check = o?.order?.merchant_name === 'goyolo';
    if (!isNotFilterPaid) {
      check = check && o?.order?.status === 'paid';
    }
    return check;
  });
  return order;
};
export const payApi = createApi({
  reducerPath: 'payApi',
  baseQuery: fetchAuthedQuery({
    baseUrl: `${process.env.VGATE_BASE_URL}`,
  }),
  endpoints: (builder) => ({
    createPayOrder: builder.mutation<TPayResult, TPayInternalRequest>({
      query: (body) => {
        const obj = ({
          url: '/v1/order',
          method: 'POST',
          body: {
            ...body,
            source: paySource.WEB,
            language: language.VN,
            merchant_name: 'goyolo',
            client_id: 'client_id',
          } as TPayRequest,
        });
        return obj;
      },
    }),
    updatePaymentMethod: builder.mutation<TPayResult, TPayInternalRequest>({
      query: (body) => {
        if (body.payment_gateway === 'pay_later') {
          body.vxr_payment_type = 1;
        }

        return {
          url: '/v1/order',
          method: 'POST',
          body: {
            ...body,
            source: paySource.WEB,
            language: language.VN,
          } as TPayRequest,
        };
      },
    }),
    getOrderById: builder.query<TOrderResult[], TOrderParamsRequest>({
      query: (filter) => `/v1/order/${filter?.order_id || ''}?get_all=true`,
      keepUnusedDataFor: 1,
      transformResponse: (
        response: TOrderResult[],
        meta: any,
        arg: TOrderParamsRequest,
      ) => getOrder(response, arg?.isNotFilterPaid),
    }),
  }),
});

export const {
  useCreatePayOrderMutation,
  useUpdatePaymentMethodMutation,
  useLazyGetOrderByIdQuery,
  useGetOrderByIdQuery,
} = payApi;
