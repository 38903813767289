import { createSlice } from '@reduxjs/toolkit';
import { Discount } from '.';

// export const discountAdapter = createEntityAdapter<Discount>();
// const emptyInitialState = discountAdapter.getInitialState();
// const filledState = discountAdapter.upsertMany(emptyInitialState,discounts );

const discounts = [
  {
    id: 1,
    name: 'Giảm 50%',
    brandLogo: 'images/momo-logo.png',
    brandName: 'momo',
    description: 'Cho lần bay đầu tiên',
    expDate: '30/12/2021',
    tag: ['KH mới', 'Chỉ cho App'],
  },
  {
    id: 2,
    name: 'Giảm 30%',
    brandLogo: 'images/zaloPay-logo.png',
    brandName: 'zalo',
    description: 'Cho lần bay đầu tiên',
    expDate: '30/11/2021',
    tag: ['KH mới', 'Chỉ cho App'],
  },
  {
    id: 3,
    name: 'Giảm 40%',
    brandLogo: 'images/momo-logo.png',
    brandName: 'VNPay',
    description: 'Cho lần bay đầu tiên',
    expDate: '20/01/2022',
    tag: ['KH mới', 'Chỉ cho App'],
  },
] as Discount[];

export const slice = createSlice({
  name: 'discount',
  initialState: { codes: discounts },
  reducers: {},
});

export const { reducer: discountReducer } = slice;
// export const {
//   selectAll: selectAllDiscounts,
// } = discountAdapter.getSelectors((state: RootState) => state.discount);
