import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '@/slices/common/fetchAuthedQuery';

/* eslint-disable @typescript-eslint/no-var-requires */

export const myBusRouteBookingApi = createApi({
  reducerPath: 'myBusRouteBookingApi',
  baseQuery: fetchAuthedQuery({
    baseUrl: `${process.env.VAPI_URL}/v3/`,
  }),
  endpoints: (builder) => ({
    getBaggages: builder.query<any, any>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: { ListFareData: data },
      }),
    }),
    getStatusByCode: builder.query<any, { code: string }>({
      query: ({ code }) => ({
        url: `booking?code=${code}`,
        method: 'GET',
      }),
    }),
  }),
});
export const { useLazyGetStatusByCodeQuery } = myBusRouteBookingApi;
