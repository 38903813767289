/* eslint-disable import/no-duplicates */
import { PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

export type TBotDialogType = { type: keyof TBotDialogSlice };

interface TBotDialogSlice {
  openSortMenu: boolean;
  openFilterTime: boolean,
  openFilterCompanies: boolean,
  openFilterOther: boolean;
  openFilterMinFare: boolean,
  openFilterAirline: boolean;
}

const initialState: TBotDialogSlice = {
  openFilterMinFare: false,
  openFilterAirline: false,
  openFilterOther: false,
  openFilterTime: false,
  openFilterCompanies: false,
  openSortMenu: false,
};

export const slice = createSlice({
  name: 'botDialog',
  initialState,
  reducers: {
    closeBotDialog: (state) => {
      state = initialState;
      return state;
    },
    openBotDialog: (state, action: PayloadAction<TBotDialogType>) => {
      state[action.payload.type] = true;
    },
  },
});

export const { reducer: botDialogReducer } = slice;
export const { closeBotDialog, openBotDialog } = slice.actions;
