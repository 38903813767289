import { get, groupBy, isEmpty } from 'lodash';
import {
  add,
  isBefore,
  differenceInDays,
  format,
  sub,
  startOfDay,
  getMonth,
} from 'date-fns';

// slices

import { IPassengerSearchForm } from '@/slices/passenger/slices/type.d';
import { IFlightResultItem } from '@/containers/SearchFlightTab/SearchFlightResultItem/index.d';

// utils
import { getAreaPointArr } from '@/utils';
import AIRLINES_VN from '@/mappings/airlines_vn.json';
import { calendarVN } from '@/utils/calendarVietNam';

import {
  TFlightCountSeoResponse, IGroupedByFlightNameSeoRoute, IItemMinFare,
} from './type';
import { FlightData } from '@/slices/flightData/slices';
import { TGenerateFlightDataRequest } from '@/utils/generateFlightRequest';

export const generateDays = (
  {
    date, minDate, maxDate, dayLimit,
  }:
  { date: string, minDate?: string | null, maxDate?: string | null, dayLimit?: number },
) => {
  const selectedDate = new Date(date);
  const today = new Date();
  let startDate: Date = today;
  const checkingMinDate = minDate ? new Date(minDate) : new Date();
  let checkingMaxDate = maxDate ? new Date(maxDate) : new Date();
  if (getMonth(checkingMinDate) === getMonth(checkingMaxDate)) {
    checkingMaxDate = add(checkingMaxDate, { months: 1 });
  } else {
    checkingMaxDate = add(checkingMaxDate, { days: 15 });
  }

  const farthestPastDate = sub(selectedDate, { days: 15 });
  if (isBefore(today, farthestPastDate)) startDate = farthestPastDate;
  if (minDate) startDate = checkingMinDate;

  const flightDayLimit = maxDate
    ? (differenceInDays(startOfDay(new Date(checkingMaxDate)), startOfDay(startDate)) + 1)
    : parseInt(process.env.NEXT_PUBLIC_FLIGHT_CALENDAR_DAY_LIMIT || '30', 10);

  let checkingDate = sub(startDate, { days: 1 });

  const days = new Array(dayLimit || (flightDayLimit < 0 ? -flightDayLimit : flightDayLimit) || 0).fill({}).map(() => {
    checkingDate = add(checkingDate, { days: 1 });
    const year = checkingDate.getFullYear();
    const month = checkingDate.getMonth() + 1;
    const dateNo = checkingDate.getDate();
    const dayVN = calendarVN.fromGregorian(year, month, dateNo) as any;
    return {
      date: checkingDate,
      dateStr: format(checkingDate, 'yyyy-MM-dd'),
      minFare: '',
      id: '',
      dayVN: dayVN.day,
      monthVN: dayVN.month,
      airline: {
        name: '',
      },
    };
  });

  return days;
};

export const groupItemByDate = (data: TFlightCountSeoResponse, routeCode: string) => {
  if (!data) return null;
  const temp: any = [];
  const groupedByFlightNameSeoRoute: IGroupedByFlightNameSeoRoute = data?.grouped_by_flight_name_seo_route;
  if (!groupedByFlightNameSeoRoute) {
    return {};
  }
  const itemObj = groupedByFlightNameSeoRoute[routeCode];
  const AIRLINES: { [key: string]: any } = AIRLINES_VN;
  if (itemObj) {
    Object.keys(itemObj?.min_flight_by_airline).filter((key) => !!key && key !== 'BL').map((key: string) => {
      const value = itemObj?.min_flight_by_airline[key]?.map((item: IItemMinFare) => ({
        ...item,
        airline: AIRLINES[key as string],
      }));
      temp.push(...value);
      return true;
    });
  }
  return groupBy(temp, 'pickup_date');
};

export const groupFlightByDate = (data: FlightData[]) => {
  if (isEmpty(data)) return null;
  const AIRLINES: { [key: string]: any } = AIRLINES_VN;
  const temp = data.map((flightData) => {
    const value = {
      pickup_date: flightData.date,
      pickup_time: flightData.time,
      pickup_time_number: Number(flightData.time.replace(':', '')),
      total_price_adult: get(flightData, 'route.schedules[0].totalPriceAdult') || 0,
      id: flightData.idIndex,
      dropoff_time: flightData.arrivalTime,
      airline: AIRLINES[flightData.airline],
    };
    return value;
  });
  return groupBy(temp, 'pickup_date');
};

export const generateMinFareRequestSeo = (
  {
    fromDateStr,
    toDateStr,
    startPoint,
    toPoint,
    passenger,
    is_count_tet_price,
    airline,
  }: {
    fromDateStr: string,
    toDateStr: string,
    startPoint: IFlightResultItem,
    toPoint: IFlightResultItem,
    passenger: IPassengerSearchForm,
    airline?: string;
    is_count_tet_price?: number;

  },
) => ({
  from: getAreaPointArr(startPoint),
  to: getAreaPointArr(toPoint),
  date_range: {
    min: fromDateStr,
    max: toDateStr,
  },
  is_count_tet_price,
  quantity: passenger.adt + passenger.chd,
  airline: [airline || ''],
});

export const generateFlightRequestSeo = (
  {
    fromDateStr,
    startPoint,
    toPoint,
    passenger,
    airline,
  }: {
    fromDateStr: string,
    startPoint: IFlightResultItem,
    toPoint: IFlightResultItem,
    passenger: IPassengerSearchForm,
    airline?: any;
  },
): TGenerateFlightDataRequest => ({
  from: getAreaPointArr(startPoint),
  to: getAreaPointArr(toPoint),
  date: fromDateStr,
  quantity: passenger.adt + passenger.chd,
  airline: [airline || ''],
  page: 1,
  pagesize: 10,
  flight_class: 'PT',
});

export const getTetDate = (year: number) => {
  const newYear = calendarVN.newYear(year);
  calendarVN.fromJDE(newYear);

  const gDate = (calendarVN as any).toGregorian();
  return gDate;
};

export const getRangeCountTet = () => {
  const today = new Date();
  let yearTet = today.getFullYear();
  const curMonth = today.getMonth();
  if (+curMonth >= 8 && curMonth <= 12) {
    yearTet += 1;
  }
  const { year, month, day } = getTetDate(yearTet);

  return {
    startDate: format(new Date(year, day > 15 ? month - 1 : month - 2, 1), 'yyyy-MM-dd'),
    endDate: format(new Date(year, day > 15 ? month + 1 : month, 0), 'yyyy-MM-dd'),
  };
};
