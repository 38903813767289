import { RootState } from '@/utils/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { route } from 'next/dist/server/router';

import {
  GoyoloFlexiEntityData,
  GoyoloSeoTemplateData,
  HeadingContent,
  ILangObj,
  ListAreasItem,
} from './sliceType';

export type TGoyoloSeoState = {
  templateData?: GoyoloSeoTemplateData | null;
  templateParamObj?: any;
  flexiEntities?: GoyoloFlexiEntityData[];
  calculatedParams?: any;
  tableContentTitle?: HeadingContent[];
  listAreas?: ListAreasItem[];
  dataStatisticsTetSeo?: any;
  lunarNewYearPolularRoute?: any;
  lunarNewYearByAirline?: any;
  newYearPolularRoute?: any;
  lang?: ILangObj;
};
const initialState: TGoyoloSeoState = {};

export const slice = createSlice({
  name: 'goyoloSeo',
  initialState,
  reducers: {
    addGoyoloTemplateData: (state, action: PayloadAction<TGoyoloSeoState>) => {
      if (action.payload) {
        state.templateData = action.payload.templateData || state.templateData || ({} as any);
        state.flexiEntities = action.payload.flexiEntities || state.flexiEntities || [];
        state.calculatedParams = action.payload.calculatedParams || state.calculatedParams || {};
        state.templateParamObj = action.payload.templateParamObj || state.templateParamObj || {};
        state.lang = action.payload.lang || state.lang || undefined;
      }
    },
    setTableContent: (state, action: PayloadAction<HeadingContent[]>) => {
      if (action.payload) {
        state.tableContentTitle = action.payload;
      }
    },
    updateTableContent: (state, action: PayloadAction<HeadingContent>) => {
      let tableContent = state.tableContentTitle || [];
      const checkIndex = tableContent.findIndex(
        (tc) => tc.parentIdx === action.payload.parentIdx
          && tc?.idx === action.payload.idx,
      );

      if (checkIndex < 0) {
        const index = tableContent.findIndex(
          (tc) => tc.parentIdx > action.payload.parentIdx
            && tc?.idx > action.payload.idx,
        );
        if (index < 0) {
          tableContent.push(action.payload);
        }
        if (index >= 0) {
          // tableContent.push(action.payload);
          tableContent = [
            // part of the array before the specified index
            ...tableContent.slice(0, index),
            // inserted items
            action.payload,
            // part of the array after the specified index
            ...tableContent.slice(index),
          ];
          state.tableContentTitle = tableContent;
          // state.tableContentTitle = action.payload;
        }
      }
    },
    updateListAreas: (state, action: PayloadAction<any>) => {
      state.listAreas = action.payload;
    },
    updateLunarNewYearPolularRoute: (
      state,
      action: PayloadAction<{ route: string; data: any }>,
    ) => {
      if (!state.lunarNewYearPolularRoute) {
        state.lunarNewYearPolularRoute = {};
      }
      state.lunarNewYearPolularRoute[action.payload.route] = action.payload.data;
    },
    updateLunarNewYearByAirline: (
      state,
      action: PayloadAction<{ route: string; data: any }>,
    ) => {
      if (!state.lunarNewYearByAirline) {
        state.lunarNewYearByAirline = {};
      }
      state.lunarNewYearByAirline[action.payload.route] = action.payload.data;
    },
    updateNewYearPolularRoute: (
      state,
      action: PayloadAction<{ route: string; data: any }>,
    ) => {
      if (!state.newYearPolularRoute) {
        state.newYearPolularRoute = {};
      }
      state.newYearPolularRoute[action.payload.route] = action.payload.data;
    },
  },
  // lunarNewYearPolularRoute?: any;
  // LunarNewYearByAirline?: any;
  // NewYearPolularRoute:?: any;
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.goyoloSeo,
    }),
  },
});

export const { reducer: goyoloSeoReducer } = slice;
export const {
  addGoyoloTemplateData,
  setTableContent,
  updateTableContent,
  updateListAreas,
  updateLunarNewYearPolularRoute,
  updateLunarNewYearByAirline,
  updateNewYearPolularRoute,
} = slice.actions;
export const goyoloSeo = (state: RootState) => state.goyoloSeo;
