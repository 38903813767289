import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import {
  BookingBusRouteStatusEnum,
  TMyBookingSlice,
  TMyBusRouteBooking,
} from './type';

const myBookingData: TMyBookingSlice = {
  busRoutes: [],
};

const initialState: TMyBookingSlice = myBookingData;
export const slice = createSlice({
  name: 'myBusRouteBooking',
  initialState,
  reducers: {
    addBusRouteToMyBooking: (state, actionOriginal: PayloadAction<TMyBusRouteBooking>) => {
      const action = JSON.parse(JSON.stringify(actionOriginal));
      const { bookingInfo, id } = action.payload;
      if (!action.payload.id) {
        action.payload.id = get(action, 'payload.paymentQueryPages.id');
      }
      const existBusRoute = checkBusRouteExists(state.busRoutes, {
        id: action.payload.id,
        code: action.payload.bookingCode,
      });
      if (existBusRoute) return;

      if (bookingInfo.status === BookingBusRouteStatusEnum.ISSUED) {
        state.busRoutes = state.busRoutes.filter((busRoute) => busRoute.id !== id);
      }
      state.busRoutes = state.busRoutes.slice(0, 10);
      action.payload.busRoutesData.busRouteId = action.payload.busRoutesData.busRoute?.idIndex;
      action.payload.busRoutesData.returnBusRouteId = action.payload.busRoutesData.returnBusRoute?.idIndex;
      state.busRoutes.unshift(action.payload);
      // state.busRoutes = state.busRoutes.filter((busRoute) => isAfter(new Date(get(busRoute, 'busRoutesData.busRoute.route.departureDate') || null), sub(new Date(), { days: 3 })));
    },

    updateExpireBusRoute: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const updateBusRoute = state.busRoutes.find((busRoute) => busRoute.id === id);
      if (
        updateBusRoute?.bookingInfo.status === BookingBusRouteStatusEnum.PENDING
      ) {
        updateBusRoute.bookingInfo = {
          ...updateBusRoute.bookingInfo,
          status: BookingBusRouteStatusEnum.CANCEL,
        };
      }
    },
    deleteBusRoute: (state, action: PayloadAction<{ code: string }>) => {
      const { code } = action.payload;
      const index = state.busRoutes.findIndex((busRoute) => busRoute.bookingCode === code);
      if (
        index !== -1
      ) {
        state.busRoutes.splice(index, 1);
      }
      return state;
    },

    updatePendingBusRouteToNotIssued: (
      state,
      action: PayloadAction<{ id?: number; code?: string }>,
    ) => {
      const updateBusRoute = checkBusRouteExists(state.busRoutes, action.payload, BookingBusRouteStatusEnum.PENDING);

      if (updateBusRoute) {
        updateBusRoute.bookingInfo = {
          status: BookingBusRouteStatusEnum.NOT_ISSUED,
        };
      }
    },
    updateBusMyBookingStatus: (
      state,
      action: PayloadAction<{ code: string, status: number, isPayAtOffice?: boolean }>,
    ) => {
      const { code, status, isPayAtOffice } = action.payload;
      const updateBusRoute = state.busRoutes.find((bus) => bus.ticketCode === code);
      if (updateBusRoute) {
        switch (status.toString()) {
          case '4':
            updateBusRoute.bookingInfo.status = BookingBusRouteStatusEnum.ISSUED;
            break;
          case '5':
            updateBusRoute.bookingInfo.status = BookingBusRouteStatusEnum.CANCEL;
            break;
          case '1':
            if (isPayAtOffice) {
              updateBusRoute.bookingInfo.status = BookingBusRouteStatusEnum.ISSUED;
            }
            break;
          default:
            break;
        }
      }
      return state;
    },

    updateBusRouteToIssued: (state, action: PayloadAction<{ id?: number; code?: string }>) => {
      const updateBusRoute = checkBusRouteExists(state.busRoutes, action.payload, BookingBusRouteStatusEnum.PENDING);

      if (updateBusRoute) {
        updateBusRoute.bookingInfo = {
          status: BookingBusRouteStatusEnum.NOT_ISSUED,
        };
      }
    },

    updateIssuedBusRoute: (state, action: PayloadAction<{ ids: number[] }>) => {
      const { ids } = action.payload;

      const updateBusRoutes = state.busRoutes.filter((busRoute) => ids.includes(busRoute.id));

      // updateBusRoutes.forEach((updateBusRoute) => {
      //   updateBusRoute.bookingInfo = {
      //     status: BookingBusRouteStatusEnum.ISSUED,
      //   };
      // });
    },
    updatePaymentGateway: (
      state,
      action: PayloadAction<{ id?: number; code?: string; paymentGateway: string }>,
    ) => {
      const { paymentGateway } = action.payload;

      const updateBusRoute = checkBusRouteExists(state.busRoutes, action.payload);

      if (updateBusRoute) {
        updateBusRoute.paymentGateway = paymentGateway;
      }
    },
    addUnloadedBusRoute: (state, action: PayloadAction<{ busRoute: any, unloadedBusRouteId?: string }>) => {
      const unloadedId = action.payload.unloadedBusRouteId;
      state.busRoutes.every((busRoute) => {
        if (busRoute.busRoutesData.busRouteId === unloadedId) {
          busRoute.busRoutesData.busRoute = action.payload.busRoute;
          return true;
        }
        if (busRoute.busRoutesData.returnBusRouteId === unloadedId) {
          busRoute.busRoutesData.returnBusRoute = action.payload.busRoute;
          return true;
        }
        return false;
      });
    },
  },
});

const checkBusRouteExists = (busRoutes: any[], { id, code }: { id?: number, code?: string }, checkingStatus?: any) => {
  if (!id && !code) {
    return false;
  }
  return busRoutes.find((busRoute) => {
    const queryId = get(busRoute, 'paymentQueryPages.id');
    const queryCode = get(busRoute, 'paymentQueryPages.code');
    if (checkingStatus) {
      return get(busRoute, 'bookingInfo.status') === checkingStatus && ((queryId && queryId === id) || (queryCode && queryCode === code));
    }
    return ((queryId && queryId === id) || (queryCode && queryCode === code));
  });
};

export const { reducer: myBusRouteBookingReducer } = slice;
export const {
  addBusRouteToMyBooking,
  updateExpireBusRoute,
  updateIssuedBusRoute,
  updatePaymentGateway,
  updateBusMyBookingStatus,
  updatePendingBusRouteToNotIssued,
  addUnloadedBusRoute,
  deleteBusRoute,
} = slice.actions;
