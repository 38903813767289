/* eslint-disable max-len */
import {
  // LAST_SEARCH_FIND_ROUTE_BUS,
  // LAST_SEARCH_FIND_ROUTE_FLIGHT,
  TransportationEnum,
} from '@/utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { add, compareAsc, differenceInCalendarDays } from 'date-fns';
import type { TSelectedDate } from '.';

// const extractDateFromCache = (keyObj: { [key: string]: string }) => {
//   const dataObj = {} as { [key: string]: any };

//   if (typeof window !== 'undefined') {
//     const { localStorage } = window;
//     Object.keys(keyObj).forEach((key) => {
//       const recentSearchString = localStorage.getItem(keyObj[key]);
//       let recentSearch = JSON.parse(recentSearchString || '{}');
//       if (recentSearch?.tmpSelectedDate?.depart && recentSearch?.tmpSelectedDate?.depart < new Date().toISOString()) {
//         recentSearch = {
//           ...recentSearch,
//           tmpSelectedDate: {
//             ...recentSearch.tmpSelectedDate,
//             depart: new Date().toISOString(),
//           },
//         };
//       }

//       if (recentSearch?.tmpSelectedDate?.return && recentSearch?.tmpSelectedDate?.return < new Date().toISOString()) {
//         recentSearch = {
//           ...recentSearch,
//           tmpSelectedDate: {
//             ...recentSearch.tmpSelectedDate,
//             return: new Date().toISOString(),
//           },
//         };
//       }

//       dataObj[key] = recentSearch;
//     });
//   }
//   return dataObj;
// };
// const { flight: flightRecentSearch, bus: busRecentSearch } = extractDateFromCache({
//   flight: LAST_SEARCH_FIND_ROUTE_FLIGHT,
//   bus: LAST_SEARCH_FIND_ROUTE_BUS,
// });
const flightRecentSearch = {} as any;
const busRecentSearch = {} as any;

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    selectedDate: flightRecentSearch?.tmpSelectedDate || {
      depart: new Date().toISOString(),
      return: null,
    } as TSelectedDate,
    selectedDateFlight: flightRecentSearch?.tmpSelectedDate || {
      depart: new Date().toISOString(),
      return: null,
    } as TSelectedDate,
    selectedDateBus: busRecentSearch?.tmpSelectedDate || {
      depart: new Date().toISOString(),
      return: null,
    } as TSelectedDate,
    chooseReturn: !!flightRecentSearch?.tmpSelectedDate?.return,
    showCalendarVietNam: flightRecentSearch?.isShowCalendarVietNam || false,
    calendarType: TransportationEnum.FLIGHT,
  },
  reducers: {
    setSelectedDate: (state, action: PayloadAction<{ dateObj: string }>) => {
      const selectedDate = JSON.parse(action.payload.dateObj) as TSelectedDate;
      const departDate = selectedDate.depart || '';
      const returnDate = selectedDate.return || '';
      const selectedDateObj = {
        depart: departDate ? new Date(departDate || '').toISOString() : new Date().toISOString(),
        return: returnDate ? new Date(returnDate || '').toISOString() : null,
      };
      switch (state.calendarType) {
        case TransportationEnum.BUS:
          state.selectedDateBus = selectedDateObj;
          break;
        default:
          state.selectedDateFlight = selectedDateObj;
          break;
      }
      applyChangesToMainCalendar(state);
      return state;
    },
    setCalendarType: (state, action: PayloadAction<{ calendarType: TransportationEnum }>) => {
      state.calendarType = action.payload.calendarType;
      applyChangesToMainCalendar(state);
    },
    checkDate: (state) => {
      // const departDate = state.selectedDate.depart;
      // const returnDate = state.selectedDate.return;
      switch (state.calendarType) {
        case TransportationEnum.BUS:
          if (state.selectedDateBus.depart < new Date().toISOString()) {
            state.selectedDateBus.depart = new Date().toISOString();
          }
          if (state.selectedDateBus.return && state.selectedDateBus.return < new Date().toISOString()) {
            state.selectedDateBus.return = add(new Date(), { days: 2 }).toISOString();
          }
          break;
        default:
          if (state.selectedDateFlight.depart < new Date().toISOString()) {
            state.selectedDateFlight.depart = new Date().toISOString();
          }
          if (state.selectedDateFlight.return && state.selectedDate.return < new Date().toISOString()) {
            state.selectedDateFlight.return = add(new Date(), { days: 2 }).toISOString();
          }
          break;
      }
      applyChangesToMainCalendar(state);
    },
    setDepartDate: (state, action: PayloadAction<{ depart: string }>) => {
      const oldDepartDate = new Date(state.selectedDate.depart);
      const oldReturnDate = state.selectedDate.return && new Date(state.selectedDate.return);
      const newDepartDate = new Date(action.payload.depart);
      let newReturnDate = state.selectedDate.return && new Date(state.selectedDate.return);
      if (oldReturnDate && compareAsc(newDepartDate, oldReturnDate) === 1) {
        newReturnDate = add(newDepartDate, { days: differenceInCalendarDays(oldReturnDate, oldDepartDate) });
      }

      const newDepartDateStr = newDepartDate.toISOString();
      const newReturnDateStr = newReturnDate ? newReturnDate.toISOString() : null;

      switch (state.calendarType) {
        case TransportationEnum.BUS:
          state.selectedDateBus.depart = newDepartDateStr;
          state.selectedDateBus.return = newReturnDateStr;
          break;
        default:
          state.selectedDateFlight.depart = newDepartDateStr;
          state.selectedDateFlight.return = newReturnDateStr;
          break;
      }
      applyChangesToMainCalendar(state);
    },
    setReturnDate: (state, action: PayloadAction<{ return: string | null }>) => {
      state.selectedDate.return = action.payload.return;
      switch (state.calendarType) {
        case TransportationEnum.BUS:
          state.selectedDateBus.return = action.payload.return;
          break;
        default:
          state.selectedDateFlight.return = action.payload.return;
          break;
      }
      applyChangesToMainCalendar(state);
    },
    setChooseReturn: (state, action: PayloadAction<{ chooseReturn: boolean }>) => {
      state.chooseReturn = action.payload.chooseReturn;
    },
    setShowCalendarVietNam: (state, action: PayloadAction<{ value: boolean }>) => {
      state.showCalendarVietNam = action.payload.value;
      // localStorage.setItem('IS_SHOW_CALENDAR_VN', action.payload.value ? '1' : '0');
    },
  },
});

export const { reducer: calendarReducer } = calendarSlice;
export const {
  setSelectedDate, setDepartDate, setReturnDate, setChooseReturn, setShowCalendarVietNam, checkDate, setCalendarType,
} = calendarSlice.actions;

function applyChangesToMainCalendar(state: any) {
  switch (state.calendarType) {
    case TransportationEnum.BUS:
      state.selectedDate = state.selectedDateBus;
      break;
    default:
      state.selectedDate = state.selectedDateFlight;
      break;
  }
}
