import { TFilterFlight } from '@/slices/filterFlight/filterFlightSlice';
import { FilterRoute, TFlightCountRequest } from '@/slices/flightData/apis/flightDataApi';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';
import { TSelectedSort } from '@/slices/sortingFlight/sortingFlightSlice';
import { generateTimeId } from './generateTimeId';
import { cabinMapping, fareClassMapping } from './seatClassMapping';

export type TGenerateFlightDataRequest = Partial<TFilterFlight> & {
  date: string;
  from: string[]; // e.g. ['HAN', 'SGN]
  to: string[];
  quantity?: number;
  sort?: TSelectedSort;
  flight_class?: 'PT' | 'PTDB' | 'TG';
  child_infant_count?: number;
  interval_loading?: boolean;
};

export const generateFlightDataRequest = ({
  from, to, date, quantity, sort, flight_class, child_infant_count, airline, arrivalTime, departureTime, duration, fare, isCleared, page, pagesize,
}: TGenerateFlightDataRequest): FilterRoute => ({
  date,
  from,
  to,
  // from: (from.length === 1 && !Number.isNaN(from[0])) ? from[0] : from,
  // to: (to.length === 1 && !Number.isNaN(to[0])) ? to[0] : to,
  quantity: quantity || 1,
  sort: sort || {
    type: 'fare',
    value: 'asc',
  },
  fare_class: flight_class ? fareClassMapping[flight_class] : flight_class,
  cabin: flight_class ? cabinMapping[flight_class] : flight_class,
  time_id: generateTimeId(),
  child_infant_count: child_infant_count || 0,
  airline,
  arrivalTime,
  departureTime,
  duration,
  fare,
  isCleared,
  page,
  pagesize,
});

export type TGenerateFlightCountRequest = {
  from: string[];
  to: string[];
  date?: string;
  date_range?: {
    min: string;
    max: string;
  };
  month_years?: string[];
  quantity?: number;
  flight_class: 'PT' | 'PTDB' | 'TG';
  disable_update_filter?: number;
  is_count_tet_price?: number;
  child_infant_count?: number;
  airline?: string[];
  selected_date?: string;
  lang?: ILangObj;
};

export const generateFlightCountReqeust = ({
  from, to, date, quantity, flight_class, date_range, month_years, airline, disable_update_filter, child_infant_count, is_count_tet_price, selected_date,
}: TGenerateFlightCountRequest): TFlightCountRequest => ({
  from,
  to,
  // from: (from.length === 1 && !Number.isNaN(from[0])) ? from[0] : from,
  // to: (to.length === 1 && !Number.isNaN(to[0])) ? to[0] : to,
  date,
  date_range,
  month_years,
  quantity: quantity || 1,
  fare_class: fareClassMapping[flight_class],
  cabin: cabinMapping[flight_class],
  child_infant_count: child_infant_count || 0,
  disable_update_filter,
  airline,
  is_count_tet_price: is_count_tet_price || 0,
  time_id: generateTimeId(),
  selected_date,
});
