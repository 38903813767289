import { setFlightDataIsDone } from '@/slices/flightData/slices/flightDataSlice';
import { format } from 'date-fns';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useGetFlightData2Query } from '../slices/flightData/apis/flightDataApi';
import type { AppDispatch, RootState } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

let lastRequestObjStr = '';
export const useGetFlightDataWithFilter = (pollingInterval = 0, isReturn?: boolean) => {
  const dispatch = useAppDispatch();
  const toPoint = useAppSelector((state) => state.searchFlightForm.toPoint);
  const startPoint = useAppSelector(
    (state) => state.searchFlightForm.startPoint,
  );
  const selectedDate = useAppSelector((state) => state.calendar.selectedDate);
  const passengerObj = useAppSelector(
    (state) => state.searchFlightForm.passenger,
  );
  const seatClass = useAppSelector((state) => state.searchFlightForm.seatClass);
  const fromAirports = startPoint?.airports?.map((airport) => airport.airportCode) || [];
  const toAirports = toPoint?.airports?.map((airport) => airport.airportCode) || [];
  const sort = useAppSelector((state) => state.sortingFlight.selectedSort);
  const filterFlight = useAppSelector((state) => state.filterFlight.filter);
  const dataSelected = (isReturn && selectedDate.return)
    ? selectedDate.return : selectedDate.depart;
  // useEffect(() => {
  //   resetTimeoutSearchFlight();
  // }, [toPoint, startPoint, selectedDate, passengerObj, seatClass, sort, filterFlight]);
  const requestObj = {
    from: isReturn ? toAirports : fromAirports,
    to: isReturn ? fromAirports : toAirports,
    date: format(new Date(dataSelected), 'yyyy-MM-dd'),
    ...filterFlight,
    quantity: passengerObj.adt + passengerObj.chd,
    child_infant_count: passengerObj.chd + passengerObj.inf,
    sort,
    flight_class: seatClass,
    interval_loading: false,
  };
  const requestObjStr = JSON.stringify(requestObj);
  const intervalLoading = requestObjStr === lastRequestObjStr;
  if (!intervalLoading) {
    dispatch(setFlightDataIsDone(false));
  }
  lastRequestObjStr = requestObjStr;

  useGetFlightData2Query(requestObj, {
    pollingInterval,
  });
};
