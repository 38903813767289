import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Images {
  image: string
  url?: string
}

export interface ImagesSeo {
  Source: string
  Alt?: string,
  slogan?: string
}

export interface IStrapiData {
  imagesPromotion: {
    BANNER_IMAGE_DESKTOP_HOME_BUS?: Images[],
    BANNER_IMAGE_DESKTOP_HOME_FLIGHT?: Images[],
    BANNER_IMAGE_MOBILE_HOME_BUS?: Images[],
    BANNER_IMAGE_MOBILE_HOME_FLIGHT?: Images[],
    BANNER_IMAGE_DESKTOP_ROUTE_BUS?: Images[],
    BANNER_IMAGE_DESKTOP_ROUTE_FLIGHT?: Images[],
    BANNER_IMAGE_MOBILE_ROUTE_BUS?: Images[],
    BANNER_IMAGE_MOBILE_ROUTE_FLIGHT?: Images[],
    BANNER_IMAGE_SEO?: {
      'vi': ImagesSeo[],
      'en': ImagesSeo[]
    }
  };
}

const initialState: IStrapiData = {
  imagesPromotion: {},
};

export const slice = createSlice({
  name: 'strapi',
  initialState,
  reducers: {
    addImagesPromotion: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.imagesPromotion = action.payload;
      }
    },
  },
});

export const { reducer: strapiReducer } = slice;
export const {
  addImagesPromotion,
} = slice.actions;
