import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '@/slices/common/fetchAuthedQuery';
import type {
  IMedia, IUtility, IBusPolicy, IBusTripMap, CompanyPolicyConfig, CompanyPolicyGroup,
} from './types';
import { get } from 'lodash';

export const tripDetailApi = createApi({
  reducerPath: 'tripDetailApi',
  baseQuery: fetchAuthedQuery({ baseUrl: process.env.VAPI_URL }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getImages: builder.query<IMedia, { companyId?: string;resourceId?: string }>({
      query: ({ companyId, resourceId }) => ({
        url: `/v3/company/${companyId}/media?resource_id=${resourceId}`,
        method: 'GET',
      }),
      transformResponse: (res: { data: IMedia }) => res.data,
    }),
    getUtility: builder.query<IUtility[], { companyId?: string; }>({
      query: ({ companyId }) => ({
        url: `/v1/company/${companyId}/utility?type=1`,
        method: 'GET',
      }),
      transformResponse: (res: { data: IUtility[] }) => res.data,
    }),
    getPolicy: builder.query<IBusPolicy, { tripCode?: string; }>({
      query: ({ tripCode }) => ({
        url: `/v3/cancellation/policy?trip_code=${tripCode}`,
        method: 'GET',
      }),
      transformResponse: (res: { data: IBusPolicy }) => res.data,
    }),
    getPolicyConfig: builder.query<CompanyPolicyConfig[], { tripCode?: string }>({
      query: ({ tripCode }) => ({
        url: `/v3/company_policy/config/${tripCode}`,
        method: 'GET',
      }),
      transformResponse: (res: { data: any }) => (get(res, 'data.data') || []) as CompanyPolicyConfig[],
    }),
    getPolicyGroup: builder.query<CompanyPolicyGroup[], void>({
      query: () => ({
        url: '/v3/company_policy/group',
        method: 'GET',
      }),
      transformResponse: (res: { data: any }) => res.data,
    }),
    getSeatMap: builder.query<IBusTripMap, { tripCode?: string; }>({
      query: ({ tripCode }) => ({
        url: `/v3/trip/seat_map?trip_code=${tripCode}`,
        method: 'GET',
      }),
      transformResponse: (res: { data: IBusTripMap }) => res.data,
    }),
    getRoute: builder.query<any, { routeId?: string; }>({
      query: ({ routeId }) => ({
        url: `/v2/route/${routeId}`,
        method: 'GET',
      }),
      transformResponse: (res: { data: any }) => res.data?.[0],
    }),
  }),
});

export const {
  useGetImagesQuery,
  useGetUtilityQuery,
  useGetPolicyQuery,
  useGetSeatMapQuery,
  useGetRouteQuery,
  useGetPolicyGroupQuery,
  useGetPolicyConfigQuery,
  useLazyGetSeatMapQuery,
} = tripDetailApi;
