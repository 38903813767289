import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/example' }),
  endpoints: (builder) => ({
    getExample: builder.query<{ [key: string]: any }, null>({
      query: () => '',
    }),
  }),
});

export const { useGetExampleQuery } = userApi;
