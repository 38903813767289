import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusRouteData } from '../../busData/slices/type';

export interface ISelectedBusRoute {
  busRoute: BusRouteData | null;
  returnBusRoute: BusRouteData | null;
  busRoutePriceDiff: number;
  discountAmount: number;
  returnDiscountAmount: number;
  returnBusRoutePriceDiff: number;
  busRouteId?: string;
  returnBusRouteId?: string;
  totalFare?: number
}

const initialState: ISelectedBusRoute = {
  busRoute: null,
  returnBusRoute: null,
  busRoutePriceDiff: 0,
  discountAmount: 0,
  returnDiscountAmount: 0,
  returnBusRoutePriceDiff: 0,
  totalFare: 0,
};

export const slice = createSlice({
  name: 'selectedBusRoute',
  initialState,
  reducers: {
    addAllSelectedBusRoute: (state, action: PayloadAction<ISelectedBusRoute>) => {
      state = action.payload;
      return state;
    },
    addBusRoute: (state, action: PayloadAction<BusRouteData>) => {
      state.busRoute = action.payload as any;
      return state;
    },
    addReturnBusRoute: (state, action: PayloadAction<BusRouteData>) => {
      state.returnBusRoute = action.payload as any;
      return state;
    },
    resetReturnBusRoute: (state) => {
      state.returnBusRoute = null;
      return state;
    },
    setBusRoutePriceDiff: (state, action: PayloadAction<number>) => {
      state.busRoutePriceDiff = action.payload;
      return state;
    },
    setBusRouteDiscountAmount: (state, action: PayloadAction<number>) => {
      state.discountAmount = action.payload;
      return state;
    },
    setReturnBusRouteDiscountAmount: (state, action: PayloadAction<number>) => {
      state.returnDiscountAmount = action.payload;
      return state;
    },
    setReturnBusRoutePriceDiff: (state, action: PayloadAction<number>) => {
      state.returnBusRoutePriceDiff = action.payload;
      return state;
    },
    removeBusRoute: (state) => {
      state.busRoute = null;
      state.busRoutePriceDiff = 0;
      return state;
    },
    removeReturnBusRoute: (state) => {
      state.returnBusRoute = null;
      state.busRoutePriceDiff = 0;
      return state;
    },
    setTotalFare: (state, action: PayloadAction<number>) => {
      state.totalFare = action.payload;
      return state;
    },
  },
});

export const { reducer: selectedBusRouteReducer } = slice;
export const {
  addBusRoute,
  addReturnBusRoute,
  removeBusRoute,
  removeReturnBusRoute,
  setBusRoutePriceDiff,
  addAllSelectedBusRoute,
  setReturnBusRoutePriceDiff,
  setBusRouteDiscountAmount,
  setReturnBusRouteDiscountAmount,
  resetReturnBusRoute,
  setTotalFare,
} = slice.actions;
