import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TSortingFlightValue = 'asc' | 'desc';
export type TSortingFlightType = 'departureTime' | 'fare' | 'arrivalTime';

export type TSortingFlight = {
  departureTime: TSortingFlightValue;
  fare: TSortingFlightValue;
  arrivalTime: TSortingFlightValue;
};

export type TSelectedSort = {
  type: TSortingFlightType;
  value: TSortingFlightValue;

};

export type TSortingFlightSlice = {
  sort: TSortingFlight;
  selectedSort: TSelectedSort
};

const initialState: TSortingFlightSlice = {
  sort: {
    departureTime: 'asc',
    fare: 'asc',
    arrivalTime: 'desc',
  },
  selectedSort: {
    type: 'fare',
    value: 'asc',
  },
};

export const sortingFlight = createSlice({
  name: 'sortingFlight',
  initialState,
  reducers: {
    setSortingFlight: (
      state,
      action: PayloadAction<TSelectedSort>,
    ) => {
      state.sort = {
        ...initialState.sort,
        [action.payload.type]: action.payload.value,
      };
      state.selectedSort = {
        type: action.payload.type,
        value: action.payload.value,
      };
    },
    resetSortingFlight: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const checkIsDefaultSortingFlight = (state: any) => JSON.stringify(state) === JSON.stringify(initialState);

export const { reducer: sortingFlightReducer } = sortingFlight;
export const { setSortingFlight, resetSortingFlight } = sortingFlight.actions;
