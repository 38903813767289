import { fetchAuthedQuery } from '@/slices/common/fetchAuthedQuery';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { InvoiceResult, TInvoiceParamsRequest } from '.';

export const invoiceApi = createApi({
  reducerPath: 'invoice',
  baseQuery: fetchAuthedQuery({
    baseUrl: process.env.GOM_SERVICE_URL,
  }),
  endpoints: (builder) => ({
    getInvoiceByOrder: builder.query<InvoiceResult, TInvoiceParamsRequest>({
      query: (filter) => `/v1/invoice?order_code=${filter?.order_code || ''}&order_id=${filter?.order_id || ''}`,
      transformResponse: (response: InvoiceResult) => response,
    }),
  }),

});
export const {
  useLazyGetInvoiceByOrderQuery,
} = invoiceApi;
