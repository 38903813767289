/* eslint-disable no-prototype-builtins */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export function camelCaseKeysToUnderscore(obj: any) {
  if (typeof obj !== 'object') return obj;

  for (const oldName in obj) {
    // Camel to underscore
    const newName = oldName.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);

    // Only process if names are different
    if (newName !== oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (obj.hasOwnProperty(oldName)) {
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
    }

    // Recursion
    if (typeof obj[newName] === 'object') {
      obj[newName] = camelCaseKeysToUnderscore(obj[newName]);
    }
  }
  return obj;
}

export function camelCaseKeysToSnake(obj: any) {
  if (typeof (obj) !== 'object') return obj;
  const newObj = { ...obj };
  for (const oldName in newObj) {
    // Camel to underscore
    const newName = oldName.replace(/([A-Z])/g, function ($1) { return `_${$1.toLowerCase()}`; });

    // Only process if names are different
    if (newName !== oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (newObj.hasOwnProperty(oldName)) {
        newObj[newName] = newObj[oldName];
        delete newObj[oldName];
      }
    }

    // Recursion
    if (typeof (newObj[newName]) === 'object') {
      newObj[newName] = camelCaseKeysToSnake(newObj[newName]);
    }
  }
  return newObj;
}
