/* eslint-disable no-nested-ternary */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TFilterPriceValue = {
  min: number;
  max?: number;
};

export type TFilterTimeValue = {
  min: string;
  max: string;
};

export type TFilterType = 'time' | 'fare' | 'airline';

export type TFilterAirlineValue = 'VN' | 'VJ' | 'QH' | 'VU' | 'BL' | '0V';

export type TFilterFlight = {
  isCleared?: boolean
  fare?: TFilterPriceValue;
  duration?: TFilterTimeValue;
  departureTime?: TFilterTimeValue;
  arrivalTime?: TFilterTimeValue;
  airline?: TFilterAirlineValue[];
  page: number;
  pagesize?: number;
};

export type TFilterFlightSlice = {
  filter: TFilterFlight;
  filterList: TFilterType[];
  oldAirlines: string[];
};

export type TPayloadFilter = {
  type: keyof TFilterFlight;
  value: TFilterFlight[TPayloadFilter['type']];
};

export const initialState: TFilterFlightSlice = {
  filter: { page: 1 },
  filterList: ['fare', 'time', 'airline'],
  oldAirlines: [],
};

export const filterFlight = createSlice({
  name: 'filterFlight',
  initialState,
  reducers: {
    setFilterFlight: (state, action: PayloadAction<Partial<TFilterFlight>>) => {
      const keys = Object.keys(action.payload);
      const { isCleared } = action.payload;
      if (!isCleared) {
        state.filter = {
          ...state.filter,
          ...action.payload,
        };
      }
      // let newListVal;
      // if (keys.includes('fare')) {
      //   newListVal = new Set(['fare', ...state.filterList]);
      // } else if (keys.includes('airline')) {
      //   newListVal = new Set(['airline', ...state.filterList]);
      // } else {
      //   newListVal = new Set(['time', ...state.filterList]);
      // }
      state.filterList = [...state.filterList].sort((first, second) => {
        const firstDiff = keys.includes(first) ? (!isCleared ? 1 : -1) : 0;
        const secondDiff = keys.includes(second) ? (!isCleared ? 1 : -1) : 0;
        return secondDiff - firstDiff;
      });
      return state;
    },
    clearFilterFlight: (
      state,
      action: PayloadAction<{ type: TFilterType }>,
    ) => {
      if (action.payload.type === 'time') {
        delete state.filter.arrivalTime;
        delete state.filter.departureTime;
        delete state.filter.duration;
      } else {
        delete state.filter[action.payload.type];
      }
      state.filterList = state.filterList.filter(
        (item) => item !== action.payload.type,
      );
      const origialIndex = initialState.filterList.findIndex(
        (item) => item === action.payload.type,
      );
      state.oldAirlines = [];
      state.filterList.splice(origialIndex, 0, action.payload.type);
    },
    addOldAilines: (state, action: PayloadAction<string[]>) => {
      state.oldAirlines = action.payload;
    },
    resetFilter: (state) => {
      state = initialState;
      return state;
    },
    changePage: (state, action: PayloadAction<{ page: number }>) => {
      state.filter.page = action.payload.page;
    },
    updateFilter: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { reducer: filterFlightReducer } = filterFlight;
export const {
  setFilterFlight, clearFilterFlight, resetFilter, addOldAilines, changePage, updateFilter,
} = filterFlight.actions;
