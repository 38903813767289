import qs from 'qs';
/* eslint-disable @typescript-eslint/no-var-requires */
import { IBusTripResponse, IBusTrip, IBusTripParams } from './type';
import { fetchAuthedQuery } from '@/slices/common/fetchAuthedQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export const pickDropPointApi = createApi({
  reducerPath: 'pickDropPoint',
  baseQuery: fetchAuthedQuery({
    baseUrl: process.env.VAPI_URL,
  }),
  endpoints: (builder) => ({
    getBusTrip: builder.query<IBusTrip, IBusTripParams>({
      query: (params) => {
        const { tripCode, ...rest } = params;
        return `/v3/trip/${tripCode}?${qs.stringify(rest)}`;
      },
      transformResponse: (response: IBusTripResponse) => camelcaseKeysDeep(response.data),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetBusTripQuery, useLazyGetBusTripQuery } = pickDropPointApi;
