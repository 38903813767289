import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '../../common/fetchAuthedQuery';
import { AreaResult } from '.';

export const areaApi = createApi({
  reducerPath: 'areaApi',
  baseQuery: fetchAuthedQuery({
    baseUrl: `${process.env.VROUTE_BASE_URL}`,
  }),
  endpoints: (builder) => ({
    getAreaByName: builder.query<AreaResult[], string>({
      query: (name) => `/v1/goyolo/area?q=${encodeURI(name)}`,
      transformResponse: (response: { data: AreaResult[] }) => response.data,
      keepUnusedDataFor: 600,
    }),
    getAreaBusByName: builder.query<AreaResult[], string>({
      // query: (name) => `/v2/area?q=${encodeURI(name)}&full_name_search=1`,
      query: (name) => `/v2/area?q=${encodeURI(name)}`,
      transformResponse: (response: { data: AreaResult[] }, _, arg) => {
        const filteredArr = response.data.filter((loc) => [loc.full_name, loc.name, loc.name_token].includes(arg));
        if (filteredArr.length) {
          return filteredArr;
        } if (response.data.length) {
          return response.data;
        }
        return [];
      },
      keepUnusedDataFor: 600,
    }),
    getAreaById: builder.query<AreaResult, string>({
      query: (areaId) => `/v2/area/${areaId}?skip_mapping=1`,
      transformResponse: (response: AreaResult) => response,
      keepUnusedDataFor: 600,
    }),
    getAreaById2: builder.query<AreaResult, string>({
      query: (areaId) => `v1/goyolo/area/${areaId}`,
      transformResponse: (response: { data: AreaResult }) => response.data,
      keepUnusedDataFor: 600,
    }),
  }),
});

export const {
  useLazyGetAreaByNameQuery, useLazyGetAreaBusByNameQuery, useLazyGetAreaByIdQuery, useLazyGetAreaById2Query,
} = areaApi;
