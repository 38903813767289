import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'headerTabsSlice',
  initialState: 0,
  reducers: {
    setHeaderTabsValue: (state, action: PayloadAction<number>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { reducer: headerTabsReducer } = slice;
export const { setHeaderTabsValue } = slice.actions;
