/* eslint-disable import/no-cycle */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../../utils/store';
import { TBusRouteDataResponse } from '../apis/type';
import { BusRouteData } from './type';
import { busDataApi } from '../apis/busDataApi';
import {
  concat, get, isEmpty, sortBy, sortedUniq,
} from 'lodash';
import { getMappingBusRouteCardData } from '@/utils/busRouteCardMapping';
import FlightStatisticsInterval from '@/utils/FlightStatisticsInterval';

export const loadingInterval = new FlightStatisticsInterval();
export const busDataAdapter = createEntityAdapter<BusRouteData>();
const initialState = busDataAdapter.getInitialState({
  busData: [] as BusRouteData[] | [],
  dataStatistics: null as TBusRouteDataResponse[] | null,
  dataStatistics2: null as TBusRouteDataResponse[] | null,
  oldBustData: null as BusRouteData[] | null,
  oldDataStatistics: null as TBusRouteDataResponse[] | null,
  oldDataStatistics2: null as TBusRouteDataResponse[] | null,
  newBusData: [] as BusRouteData[] | [],
  newDataStatistics: null as TBusRouteDataResponse[] | null,
  isDone: false,
  total: 0 as number,
  sortedBest: false,
});

export const slice = createSlice({
  name: 'busData',
  initialState,
  reducers: {
    removeBusRouteData: busDataAdapter.removeOne,
    resetBusData: (state) => {
      state.isDone = false;
      state.busData = [];
      state.dataStatistics = null;
      state.dataStatistics2 = null;
      state.newBusData = [];
      state.newDataStatistics = null;
      state.oldBustData = state.busData;
      state.oldDataStatistics = state.dataStatistics;
      state.oldDataStatistics2 = state.dataStatistics2;
    },
    setBusDataIsDone: (state, action) => {
      state.isDone = action.payload || false;
    },
    setSortedBest: (state, action) => {
      state.sortedBest = action.payload || false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(busDataApi.endpoints.getBusData.matchFulfilled, (state, action) => {
        const updateBusData = action.payload.page === 1
          ? action.payload.data
          : concat(
            state.busData.slice(0, (action.payload.page - 1) * 20),
            action.payload.data,
            state.busData.slice((action.payload.page + 1) * 20),
          );
        state.newBusData = updateBusData;
        state.total = action.payload.total;
        addMinPrice(state);
      })
      .addMatcher(busDataApi.endpoints.getBusRouteStatistics.matchFulfilled, (state, action) => {
        state.newDataStatistics = action.payload;
      })
      .addMatcher(busDataApi.endpoints.getBusRouteStatistics2.matchFulfilled, (state, action) => {
        if (isEmpty(get(action.payload, 'grouped_by_date'))) {
          state.oldDataStatistics2 = state.dataStatistics2;
        }
        state.dataStatistics2 = action.payload;
      });
  },
});

function addMinPrice(state: typeof initialState) {
  if (!(state.newBusData && state.newDataStatistics)) {
    return;
  }

  let { newBusData: busData } = state;

  if (!busData || isEmpty(busData)) {
    return;
  }
  state.oldBustData = state.busData;
  state.oldDataStatistics = state.dataStatistics;
  state.dataStatistics = state.newDataStatistics;
  state.isDone = true;

  if (busData && busData.length) {
    const sortedData = sortedUniq(sortBy(busData.map((busRoute) => {
      const routeMapping = getMappingBusRouteCardData(busRoute);
      return routeMapping ? routeMapping.price : 0;
    }), (val) => val));
    const cheapestPrice = get(sortedData, '0');
    const cheapestPrice2 = get(sortedData, '1');

    busData = busData.map((busRoute) => {
      const routeMapping = getMappingBusRouteCardData(busRoute);
      if (routeMapping) {
        const {
          price: routePrice, originalPrice, schedule, discountAmount,
        } = routeMapping;

        schedule.discountAmount = 0;
        schedule.priceTypeArr = [];
        if (routePrice === cheapestPrice) {
          busRoute.route.schedules[0].isCheapest = 1;
        } else if (routePrice === cheapestPrice2) {
          busRoute.route.schedules[0].isCheapest2 = 1;
        }

        schedule.lastMinutePercent = 0;
        if (schedule.isLastMinuteActivated && routePrice) {
          schedule.discountAmount = discountAmount;
          schedule.lastMinutePercent = 100 - Math.round(((originalPrice - discountAmount) / originalPrice) * 100);
        }

        schedule.finalPrice = routePrice;
        busRoute.route.schedules = [schedule];
      }
      return busRoute;
    });
    if (state.sortedBest) {
      busData = busData.sort((d1, d2) => {
        const isCheapestValue = (d1.route.schedules[0].isCheapest || 0) * -100;
        const isCheapest2Value = (d1.route.schedules[0].isCheapest2 || 0) * -10;
        const isCheapestValue2 = (d2.route.schedules[0].isCheapest || 0) * -100;
        const isCheapest2Value2 = (d2.route.schedules[0].isCheapest2 || 0) * -10;
        return (isCheapestValue + isCheapest2Value) - (isCheapestValue2 + isCheapest2Value2);
      });
    }
    busData = busData.filter((route) => route.company);
  }
  state.busData = busData;
  state.newBusData = [];
  state.newDataStatistics = [];
}

export const { reducer: busDataReducer } = slice;
export const { removeBusRouteData, setBusDataIsDone, setSortedBest } = slice.actions;
export const {
  selectById: selectBusRouteDataById,
  selectIds: selectBusRouteDataIds,
  selectEntities: selectBusRouteDataEntities,
  selectAll: selectAllBusRouteDatas,
  selectTotal: selectTotalBusRouteDatas,
} = busDataAdapter.getSelectors((state: RootState) => state.busData);
