import { style } from '@/styles/globals';
import { Typography, Button } from '@mui/material';
import { useAppDispatch } from '@/utils/hook';
import {
  clearFilterFlight,
  setFilterFlight,
  TFilterType,
} from '@/slices/filterFlight/filterFlightSlice';
import {
  clearFilterBusRoute,
  setFilterBusRoute,
  TFilterType as TBusFilterType,
} from '@/slices/bus/filterBusRoute/filterBusRouteSlice';
import { pr } from '@/utils/pxToRem';

const FilterHeader = ({
  title,
  type,
  busType,
  hideResetFilter,
  onResetClick,
  isMarginDisabled,
}: {
  title?: string,
  type?: TFilterType,
  busType?: TBusFilterType,
  hideResetFilter?: boolean,
  onResetClick?: any,
  isMarginDisabled?: any,
  Header?: any
}) => {
  const dispatch = useAppDispatch();
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
        marginBottom: isMarginDisabled ? 0 : '8px',
        // height: pr`20px`,
      }}
    >
      {title
        ? (
          <Typography
            variant="title"
          >
            {title}
          </Typography>
        )
        : null}
      <Button
        sx={{
          display: hideResetFilter ? 'none' : 'unset',
        }}
        onClick={(e) => {
          e.stopPropagation();
          onResetClick();
          if (type) {
            dispatch(clearFilterFlight({ type }));
            dispatch(setFilterFlight({
              [type]: {},
              isCleared: true,
            }));
          } else if (busType) {
            dispatch(clearFilterBusRoute({ type: busType }));
            dispatch(setFilterBusRoute({
              [busType]: {},
              isCleared: true,
            }));
          }
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: style.color.product3,
            visibility: hideResetFilter ? 'hidden' : 'visible',
            textDecoration: 'underline',
          }}
        >
          Bỏ lọc
        </Typography>
      </Button>
    </div>
  );
};

export default FilterHeader;
