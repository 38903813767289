import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TSortingBusRouteValue = 'asc' | 'desc' | 'local';
export type TSortingBusRouteType = 'time' | 'fare' | 'best' | 'arrival_time' | 'rating';

export type TSortingBusRoute = {
  best: TSortingBusRouteValue;
  time: TSortingBusRouteValue;
  arrival_time: TSortingBusRouteValue;
  fare: TSortingBusRouteValue;
  rating: TSortingBusRouteValue;
};

export type TSelectedSort = {
  type: TSortingBusRouteType;
  value: TSortingBusRouteValue;

};

export type TSortingBusRouteSlice = {
  sort: TSortingBusRoute;
  selectedSort: TSelectedSort
};

const initialState: TSortingBusRouteSlice = {
  sort: {
    best: 'local',
    time: 'asc',
    arrival_time: 'asc',
    fare: 'asc',
    rating: 'asc',
  },
  selectedSort: {
    type: 'best',
    value: 'local',
  },
};

export const sortingBusRoute = createSlice({
  name: 'sortingBusRoute',
  initialState,
  reducers: {
    setSortingBusRoute: (
      state,
      action: PayloadAction<TSelectedSort>,
    ) => {
      state.sort = {
        ...initialState.sort,
        [action.payload.type]: action.payload.value,
      };
      state.selectedSort = {
        type: action.payload.type,
        value: action.payload.value,
      };
    },
    resetSortingBusRoute: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const checkIsDefaultSortingBusRoute = (state: any) => JSON.stringify(state) === JSON.stringify(initialState);

export const { reducer: sortingBusRouteReducer } = sortingBusRoute;
export const { setSortingBusRoute, resetSortingBusRoute } = sortingBusRoute.actions;
