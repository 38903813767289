import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '@/slices/common/fetchAuthedQuery';
import { IBusReview, IBusCompany } from './types';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchAuthedQuery({ baseUrl: process.env.USER_PROFILE_URL }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getReviews: builder.query<{ items: IBusReview[], total: number }, { companyId?: string; }>({
      query: ({ companyId }) => ({
        url: `/company/${companyId}/reviews?company_id=${companyId}&per_page=10&page=1&detailReview=false`,
        method: 'GET',
      }),
      transformResponse: (res: { data: { items: IBusReview[], total: number } }) => res.data,
    }),
    getCompanyData: builder.query<IBusCompany, { companyId?: string; }>({
      query: ({ companyId }) => ({
        url: `/company/${companyId}`,
        method: 'GET',
      }),
      transformResponse: (res: { data: IBusCompany }) => res.data,
    }),
  }),
});

export const { useGetReviewsQuery, useGetCompanyDataQuery } = profileApi;
