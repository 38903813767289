import { BusRouteSchedule } from '@/slices/bus/busData/slices/type';
import { isEmpty } from 'lodash';

export const getMatchingSchedule = (schedules?: BusRouteSchedule[] | null) => {
  if (!schedules || isEmpty(schedules)) {
    return null;
  }
  // return schedules.filter((s) => s.config === 'ONLINE')[0];
  return schedules[0];
};
