/* eslint-disable no-nested-ternary */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupedByDateResponseBusCompany, Point } from '../busData/apis/type';

export type TFilterRangeValue = {
  min: number;
  max?: number;
};

export type TFilterTimeValue = {
  min: string;
  max: string;
};

export type TFilterType = 'time' | 'other' | 'companies';

export type TFilterTime = {
  departureTime?: TFilterTimeValue;
  arrivalTime?: TFilterTimeValue;
};

export type TFilterOther = {
  rating?: TFilterRangeValue;
  seatPos?: string[];
  pickupPoints?: TFilterPoint[];
  dropoffPoints?: TFilterPoint[];
  availableSeat?: { [key: string]: number };
};

export type TFilterBusRoute = {
  onlineReserved?: number;
  isCleared?: boolean;
  pagesize?: number;
  time?: TFilterTime;
  other?: TFilterOther;
  companies?: number[];
  arrivalTime?: any;
  departureTime?: any;
  page: number;
  [key: string]: any;
};

export type TFilterPoint = {
  name: string;
  district: string;
};

export type StringKeyObj = { [key: string]: string };

export type TFilterBusRouteSlice = {
  filter: TFilterBusRoute;
  filterList: TFilterType[];
  filterData: TFilterData;
};

export type TPayloadFilter = {
  type: keyof TFilterBusRoute;
  value: TFilterBusRoute[TPayloadFilter['type']];
};

const initialState: TFilterBusRouteSlice = {
  filter: {
    page: 1,
  },
  filterList: ['time', 'companies', 'other'],
  filterData: {},
};

export type TFilterData = {
  companies?: GroupedByDateResponseBusCompany[];
  pickupPoints?: Point[];
  dropoffPoints?: Point[];
};

export const filterBusRoute = createSlice({
  name: 'filterBusRoute',
  initialState,
  reducers: {
    setFilterBusRoute: (state, action: PayloadAction<Partial<TFilterBusRoute>>) => {
      const keys = Object.keys(action.payload);
      const { isCleared } = action.payload;
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
      state.filterList = [...state.filterList].sort((first, second) => {
        const firstDiff = keys.includes(first) ? (!isCleared ? 1 : -1) : 0;
        const secondDiff = keys.includes(second) ? (!isCleared ? 1 : -1) : 0;
        return secondDiff - firstDiff;
      });
      state.filter.page = 1;
      return state;
    },
    setFilterBusRouteData: (state, action: PayloadAction<TFilterData>) => {
      state.filterData = {
        ...state.filterData,
        ...action.payload,
      };
      state.filter.page = 1;
    },
    clearFilterBusRoute: (
      state,
      action: PayloadAction<{ type: TFilterType }>,
    ) => {
      state.filterList = state.filterList.filter(
        (item) => item !== action.payload.type,
      );
      const originalIndex = initialState.filterList.findIndex(
        (item) => item === action.payload.type,
      );
      state.filterList.splice(originalIndex, 0, action.payload.type);
      state.filter.page = 1;
    },
    resetFilter: (state) => {
      state = initialState;
      return state;
    },
    changePage: (state, action: PayloadAction<{ page: number }>) => {
      state.filter.page = action.payload.page;
    },
  },
});

export const { reducer: filterBusRouteReducer } = filterBusRoute;
export const {
  setFilterBusRoute, clearFilterBusRoute, resetFilter, setFilterBusRouteData, changePage,
} = filterBusRoute.actions;
