import { generateDays } from '@/components/RoutePage/SectionManager/utils';

export const GENDER = ['Nữ', 'Nam'];

export const PRONOUN = {
  '1-adt': 'Anh',
  '0-adt': 'Chị',
  '1-chd': 'Bé trai',
  '0-chd': 'Bé gái',
  '1-inf': 'Em bé trai',
  '0-inf': 'Em bé gái',
};

export const TYPE = {
  adt: 'người lớn',
  inf: 'em bé',
  chd: 'trẻ em',
};

export const noDataDescriptionVi = 'Hiện tại VeXeRe chưa có thông tin chuyến bay, sẽ cập nhật trong thời gian sớm nhất';
export const noDataDescriptionVex = 'Hiện tại Vexere chưa có thông tin chuyến bay, sẽ cập nhật trong thời gian sớm nhất';
export const noDataDescriptionEn = 'Hiện tại VeXeRe chưa có thông tin chuyến bay, sẽ cập nhật trong thời gian sớm nhất';

export enum TransportationEnum {
  FLIGHT = 'flight',
  BUS = 'bus',
  TRAIN = 'train',
}

export const TransportationEnumVN = {
  flight: 'Máy bay',
  bus: 'Xe khách',
  train: 'Tàu hỏa',
} as { [key: string]: string };

export const FAVICON = 'https://storage.googleapis.com/goyolo-production/favicon.ico';

export const SEAT_TYPE_MAPPING: { [key: string]: string } = {
  1: 'Ghế ngồi',
  2: 'Giường nằm',
  3: 'Ghế ngã',
  7: 'Giường nằm đôi',
};

export const MAX_PEOPLE_PER_BOOKING = 9;

export const DEFAULT_TIME_OBJ = {
  min: '00:00',
  max: '24:00',
};
export const PAY_TIMER_LIMIT = process.env.PAY_TIMER_LIMIT ? +process.env.PAY_TIMER_LIMIT : (60 * 60 * 1000);
export const BUS_PAY_TIMER_LIMIT = process.env.BUS_PAY_TIMER_LIMIT ? +process.env.BUS_PAY_TIMER_LIMIT : (60 * 10 * 1000);
export const BUS_PAY_TIMER_LIMIT_SYSTEM = process.env.BUS_PAY_TIMER_LIMIT_SYSTEM ? +process.env.BUS_PAY_TIMER_LIMIT_SYSTEM : (60 * 14 * 1000);

export const LAST_SEARCH_FIND_ROUTE_BUS = 'LAST_SEARCH_FIND_ROUTE_BUS_2';
export const LAST_SEARCH_FIND_ROUTE_FLIGHT = 'LAST_SEARCH_FIND_ROUTE_FLIGHT_2';
export const CONTACT_INFO = 'CONTACT_INFO_2';
export const CONTACT_LIST = 'CONTACT_LIST_2';
export const LIST_BUS_BOOKING_SURVEY = 'LIST_BUS_BOOKING_SURVEY_2';
export const RECENT_AREAS_KEY = 'RECENT_AREAS_2';
export const IS_SHOW_CALENDAR_VN = 'IS_SHOW_CALENDAR_VN_2';
export const LATEST_PAYMENT_METHOD = 'LATEST_PAYMENT_METHOD_2';

export const RANDOM_AUTH_KEY = 'TG9yZW0gSXBzdW0gaXMgc2ltcGx5IGR1bW15IHRleHQgb2YgdGhlIHByaW50aW5nIGFuZCB0eXBlc2V0dGluZyBpbmR1c3RyeS4gTG9yZW0gSXBzdW0gaGFzIGJlZW4gdGhlIGluZHVzdHJ5J3Mgc3RhbmRhcmQgZHVtbXkgdGV4dCBldmVyIHNpbmNlIHRoZSAxNTAwcywgd2hlbiBhbiB1bmtub3duIHByaW50ZXIgdG9vayBhIGdhbGxleSBvZiB0eXBlIGFuZCBzY3JhbWJsZWQgaXQgdG8gbWFrZSBhIHR5cGUgc3BlY2ltZW4gYm9vay4gSXQgaGFzIHN1cnZpdmVkIG5vdCBvbmx5IGZpdmUgY2VudHVyaWVzLCBidXQgYWxzbyB0aGUgbGVhcCBpbnRvIGVsZWN0cm9uaWMgdHlwZXNldHRpbmcsIHJlbWFpbmluZyBlc3NlbnRpYWxseSB1bmNoYW5nZWQuIEl0IHdhcyBwb3B1bGFyaXNlZCBpbiB0aGUgMTk2MHMgd2l0aCB0aGUgcmVsZWFzZSBvZiBMZXRyYXNldCBzaGVldHMgY29udGFpbmluZyBMb3JlbSBJcHN1bSBwYXNzYWdlcywgYW5kIG1vcmUgcmVjZW50bHkgd2l0aCBkZXNrdG9wIHB1Ymxpc2hpbmcgc29mdHdhcmUgbGlrZSBBbGR1cyBQYWdlTWFrZXIgaW5jbHVkaW5nIHZlcnNpb25zIG9mIExvcmVtIElwc3VtLgoK';

export enum SEO_PAGE_TYPE {
  AIRLINE_PAGE,
  AIRPORT_PAGE,
  AIRLINE_FROM_TO_PAGE,
  AIRLINE_TO_PAGE,
  FROM_TO_PAGE,
  TO_PAGE,
  TET_PAGE,
  TRAIN_TET_PAGE,
  FROM_TO_PAGE_EN,
}

export const LIST_AIRLINES_SLUG = ['vietnam-airlines', 'vietjet-air', 'bamboo-airways', 'vietravel-airlines', 'pacific-airlines'];

export const AIRLINE_CODE_TO_NAME: { [key: string]: string } = {
  VN: 'Vietnam Airlines',
  VJ: 'Vietjet Air',
  QH: 'Bamboo Airways',
  VU: 'Vietravel Airlines',
  BL: 'Pacific Airlines',
};

export const AIRLINE_CODE_TO_SLUG: { [key: string]: string } = {
  VN: 'vietnam-airlines',
  VJ: 'vietjet-air',
  QH: 'bamboo-airways',
  VU: 'vietravel-airlines',
  BL: 'pacific-airlines',
};

export const AIRLINE_SLUG_TO_CODE: { [key: string]: string } = {
  'vietnam-airlines': 'VN',
  'vietjet-air': 'VJ',
  'bamboo-airways': 'QH',
  'vietravel-airlines': 'VU',
  'pacific-airlines': 'BL',
};

export const VCMS_PAGE_SLUG: { [key: string]: string } = {
  [SEO_PAGE_TYPE.AIRLINE_PAGE]: '/vcms/vex/vn/ve-may-bay/{{operator.slug}}.vi',
  [SEO_PAGE_TYPE.AIRLINE_FROM_TO_PAGE]: '/vcms/vex/vn/ve-may-bay/{{operator.slug}}-tu-{{d.from.slug}}-di-{{d.to.slug}}.{{d.from.id}}.{{d.to.id}}.vi',
  [SEO_PAGE_TYPE.AIRLINE_TO_PAGE]: '/vcms/vex/vn/ve-may-bay/{{operator.slug}}-di-{{d.to.slug}}.{{d.to.id}}.vi',
  [SEO_PAGE_TYPE.FROM_TO_PAGE]: '/vcms/vex/vn/ve-may-bay/tu-{{d.from.slug}}-di-{{d.to.slug}}.{{d.from.id}}.{{d.to.id}}.vi',
  [SEO_PAGE_TYPE.TO_PAGE]: '/vcms/vex/vn/ve-may-bay/di-{{d.to.slug}}.{{d.to.id}}.vi',
  [SEO_PAGE_TYPE.AIRPORT_PAGE]: '/vcms/vex/vn/ve-may-bay/{{airport.slug}}.vi',
  [SEO_PAGE_TYPE.TET_PAGE]: '/vcms/vex/vn/ve-may-bay/ve-may-bay-tet.vi',
  [SEO_PAGE_TYPE.TRAIN_TET_PAGE]: '/vcms/vex/vn/ve-tau-hoa/ve-tau-hoa-tet.vi',
  [SEO_PAGE_TYPE.FROM_TO_PAGE_EN]: '/vcms/vex/vn/flights/from-{{d.from.slug}}-to-{{d.to.slug}}.{{d.from.id}}.{{d.to.id}}.en',
};

export const TET_MONTH = 1;
export const TET_YEAR = 2025;

export const tetDays = generateDays({
  date: new Date('2025-01-01').toISOString(),
  minDate: new Date('2025-01-01').toISOString(),
  maxDate: new Date('2025-02-15').toISOString(),
});
