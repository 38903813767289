/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
// import { LAST_SEARCH_FIND_ROUTE_FLIGHT } from '@/utils/constants';
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { PointRequest } from '.';
import type { SeatClassType } from '../../../containers/SearchFlightTab/ChooseSeatClass/type';
/* eslint-disable max-len */
import type { IFlightResultItem } from '../../../containers/SearchFlightTab/SearchFlightResultItem/index.d';
import type {
  IPassengerSearchForm,
  PassengerType,
} from '../../passenger/slices/type';

export const searchAdapter = createEntityAdapter();

export interface ISearchFlightForm {
  startPoint: IFlightResultItem | null;
  toPoint: IFlightResultItem | null;
  startPointHome: IFlightResultItem | null;
  startPointHome2: IFlightResultItem | null;
  toPointHome: IFlightResultItem | null;
  toPointHome2: IFlightResultItem | null;
  passenger: IPassengerSearchForm;
  seatClass: SeatClassType;
}

// const recentSearchKey = LAST_SEARCH_FIND_ROUTE_FLIGHT;
const recentSearch: { [key: string]: any } = {};
// if (typeof window !== 'undefined') {
//   const { localStorage } = window;
//   const recentSearchString = localStorage.getItem(recentSearchKey);
//   recentSearch = JSON.parse(recentSearchString || '{}');
// }

const initialState: ISearchFlightForm = {
  startPoint: null,
  toPoint: null,
  startPointHome: recentSearch?.tmpStartPoint || null,
  startPointHome2: recentSearch?.tmpStartPoint || null,
  toPointHome: recentSearch?.tmpToPoint || null,
  toPointHome2: recentSearch?.tmpToPoint || null,
  passenger: recentSearch?.tmpPassenger || {
    adt: 1,
    chd: 0,
    inf: 0,
  },
  seatClass: recentSearch?.seatClass || 'PT',
};

export const slice = createSlice({
  name: 'searchFlightForm',
  initialState,
  reducers: {
    // removePoints: (
    //   state,
    //   action: PayloadAction<{ data: IAirportItem; type: 'from' | 'to' }>,
    // ) => {
    //   const { payload } = action;
    //   if (payload.type === 'from') {
    //     state.startPoints = state.startPoints.filter(
    //       (point) => point.airportCode !== action.payload.data.airportCode,
    //     );
    //   } else {
    //     state.toPoints = state.toPoints.filter(
    //       (point) => point.airportCode !== action.payload.data.airportCode,
    //     );
    //   }
    // },
    clearPoints: (
      state,
      action: PayloadAction<{ type: 'from' | 'to' | 'all' }>,
    ) => {
      const { payload } = action;

      if (payload.type === 'all') {
        state.startPoint = null;
        state.startPointHome = null;
        state.toPoint = null;
        state.toPointHome = null;
      } else if (payload.type === 'from') {
        state.startPoint = null;
        state.startPointHome = null;
      } else {
        state.toPoint = null;
        state.toPointHome = null;
      }
    },
    addPoints: (
      state,
      action: PayloadAction<{
        data: IFlightResultItem | null;
        type: 'from' | 'to';
      }>,
    ) => {
      const { payload } = action;

      if (payload.type === 'from') {
        state.startPoint = payload.data;
      } else {
        state.toPoint = payload.data;
      }
    },
    addAllPoints: (
      state,
      action: PayloadAction<
      PointRequest[]
      >,
    ) => {
      const { payload } = action;
      payload.forEach((point) => {
        if (point.type === 'from') {
          state.startPoint = point.data;
        } else {
          state.toPoint = point.data;
        }
      });
    },
    swapPoints: (state) => {
      const _startPoint = state.startPoint;
      const _toPoint = state.toPoint;

      state.startPoint = _toPoint;
      state.toPoint = _startPoint;
    },
    addHomePoints: (
      state,
      action: PayloadAction<{
        data: IFlightResultItem | null;
        type: 'from' | 'to';
      }>,
    ) => {
      const { payload } = action;

      if (payload.type === 'from') {
        state.startPointHome = payload.data;
      } else {
        state.toPointHome = payload.data;
      }
    },
    addAllHomePoints: (
      state,
      action: PayloadAction<
      PointRequest[]
      >,
    ) => {
      const { payload } = action;

      payload.forEach((point) => {
        if (point.type === 'from') {
          state.startPointHome = point.data;
        } else {
          state.toPointHome = point.data;
        }
      });
    },
    addAllPointsHomePoints: (
      state,
      action: PayloadAction<
      PointRequest[]
      >,
    ) => {
      const { payload } = action;

      payload.forEach((point) => {
        if (point.type === 'from') {
          state.startPoint = point.data;
          state.startPointHome = point.data;
        } else {
          state.toPoint = point.data;
          state.toPointHome = point.data;
        }
      });
    },
    addFallbackPoints: (state) => {
      state.startPointHome2 = state.startPointHome;
      state.toPointHome2 = state.toPointHome;
    },
    loadFallbackPoints: (state) => {
      state.startPointHome = state.startPoint || state.startPointHome2;
      state.toPointHome = state.toPoint || state.toPointHome2;
    },
    clearHomePoints: (
      state,
      action: PayloadAction<{ type: 'from' | 'to' | 'all' }>,
    ) => {
      const { payload } = action;

      if (payload.type === 'all') {
        state.startPointHome = null;
        state.toPointHome = null;
      } else if (payload.type === 'from') {
        state.startPointHome = null;
      } else {
        state.toPointHome = null;
      }
    },
    adjustPassenger: (state, action: PayloadAction<TAdjustPassengerParams>) => {
      const { payload } = action;
      const key = payload.passengerType.toString().toLowerCase() as
        | 'adt'
        | 'chd'
        | 'inf';
      state.passenger[key] = Number(payload.value);
    },
    adjustAllPassengers: (
      state,
      action: PayloadAction<IPassengerSearchForm>,
    ) => {
      const { payload } = action;
      state.passenger = {
        adt: Number(payload?.adt || 0),
        chd: Number(payload?.chd || 0),
        inf: Number(payload?.inf || 0),
      };
    },
    adjustSeatClass: (state, action: PayloadAction<TAdjustSeatClass>) => {
      const { payload } = action;
      state.seatClass = payload.seatClass;
    },
    toggleArea: (state) => {
      const { startPoint } = state;
      state.startPoint = state.toPoint;
      state.toPoint = startPoint;
    },
  },
});

export type TAddPoint = (params: IFlightResultItem) => void;
export type TAdjustPassengerParams = {
  value: number;
  passengerType: PassengerType;
};
export type TAdjustSeatClass = { seatClass: SeatClassType };

export const { reducer: searchFlightReducer } = slice;
export const {
  clearPoints,
  addPoints,
  adjustPassenger,
  adjustAllPassengers,
  adjustSeatClass,
  toggleArea,
  addHomePoints,
  clearHomePoints,
  swapPoints,
  addFallbackPoints,
  loadFallbackPoints,
  addAllHomePoints,
  addAllPoints,
  addAllPointsHomePoints,
} = slice.actions;
