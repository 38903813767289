import { IBusPickupPoint } from '../apis/type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISelectedPickDropPointItem {
  pickUpPoint: IBusPickupPoint ;
  dropOffPoint: IBusPickupPoint;
  transfer: string | null;
  dropOffTransfer: string | null;
  isHaveTransfer: boolean;
  isHaveDropOffTransfer: boolean;
  pickUpSurcharge: number | null;
  dropOffSurcharge: number | null;

}

export interface ISelectedPickDropPoint{
  depart: ISelectedPickDropPointItem | null;
  return: ISelectedPickDropPointItem | null;

}

const initialState: ISelectedPickDropPoint = {
  depart: null,
  return: null,
};

const initialValue = {
  pickUpPoint: null,
  dropOffPoint: null,
  transfer: null,
  dropOffTransfer: null,
  isHaveTransfer: false,
  isHaveDropOffTransfer: false,
  dropOffSurcharge: null,
  pickUpSurcharge: null,
};

export const slice = createSlice({
  name: 'selectedPickDropPoint',
  initialState,
  reducers: {
    updateSurcharge: (state, action: PayloadAction<{ pickupSurcharge?: number, dropOffSurcharge?: number, type: 'return' | 'depart' }>) => {
      const { type, dropOffSurcharge, pickupSurcharge } = action.payload;

      if (state[type]) {
        if (pickupSurcharge) {
          (state as any)[type].pickUpSurcharge = pickupSurcharge;
        }
        if (dropOffSurcharge) {
          (state as any)[type].dropOffSurcharge = dropOffSurcharge;
        }
      }
    },
    addPickupPoint: (state, action: PayloadAction<{ data: IBusPickupPoint, type: 'return' | 'depart' }>) => {
      const { type, data } = action.payload;

      state[type] = {
        ...initialValue,
        ...state[type],
        pickUpPoint: data,
        pickUpSurcharge: (data.surchargeDescription === 'Vexere' && Number(data.surcharge) > 0) ? Number(data.surcharge) : null,
      } as any;
      return state;
    },
    addDropOffPoint: (state, action: PayloadAction<{ data: IBusPickupPoint, type: 'return' | 'depart' }>) => {
      const { type, data } = action.payload;
      state[type] = {
        ...initialValue,
        ...state[type],
        dropOffPoint: data,
        dropOffSurcharge: (data.surchargeDescription === 'Vexere' && Number(data.surcharge) > 0) ? Number(data.surcharge) : null,
      } as any;
      return state;
    },
    setTransfer: (state, action: PayloadAction<{ data: string, type: 'depart' | 'return' }>) => {
      const { type, data } = action.payload;
      state[type] = {
        ...state[type],
        transfer: data,
      } as any;
      return state;
    },
    removeTransfer: (state, action: PayloadAction<{ type: 'depart' | 'return' }>) => {
      const { type } = action.payload;
      state[type] = {
        ...state[type],
        transfer: null,
      } as any;
      return state;
    },
    setDropOffTransfer: (state, action: PayloadAction<{ data: string, type: 'depart' | 'return' }>) => {
      const { type, data } = action.payload;
      state[type] = {
        ...state[type],
        dropOffTransfer: data,
      } as any;
      return state;
    },
    setIsHaveTransfer: (state, action: PayloadAction<{ data: boolean, type: 'depart' | 'return' }>) => {
      const { type, data } = action.payload;
      state[type] = {
        ...state[type],
        isHaveTransfer: data,
      } as any;
      return state;
    },
    setIsHaveDropOffTransfer: (state, action: PayloadAction<{ data: boolean, type: 'depart' | 'return' }>) => {
      const { type, data } = action.payload;
      state[type] = {
        ...state[type],
        isHaveDropOffTransfer: data,
      } as any;
      return state;
    },

    removeDropOffTransfer: (state, action: PayloadAction<{ type: 'depart' | 'return' }>) => {
      const { type } = action.payload;
      state[type] = {
        ...state[type],
        dropOffTransfer: null,
      } as any;
      return state;
    },
    resetPickupDropOff: (state, action: PayloadAction<{ type: 'depart' | 'return' }>) => {
      const { type } = action.payload;
      state[type] = null;
      return state;
    },
  },
});

export const { reducer: selectedPickDropPointReducer } = slice;
export const {
  addDropOffPoint,
  addPickupPoint,
  setDropOffTransfer,
  setTransfer,
  removeDropOffTransfer,
  removeTransfer,
  resetPickupDropOff,
  setIsHaveDropOffTransfer,
  setIsHaveTransfer,
  updateSurcharge,
} = slice.actions;
