import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVerifyFlightData } from '.';
import { get } from 'lodash';
import { orderApi } from '../gom/apis/orderApi';
import { captureException } from '@sentry/nextjs';

export const slice = createSlice({
  name: 'global',
  initialState: {
    verifyFlightError: null as IVerifyFlightData | null,
    reserveOrderError: null as any,
    reserveResponse: null as any,
    orderId: '',
  },
  reducers: {
    setOrderId: (state, action: PayloadAction<{ orderId: string }>) => {
      state.orderId = action.payload.orderId;
    },
    addVerifyFlightError: (state, action: PayloadAction<{ error: any }>) => {
      state.verifyFlightError = action.payload.error;
      captureException(`verifyFlight error ${JSON.stringify(action.payload.error)}`);
      return state;
    },
    addReserveOrderError: (state, action: PayloadAction<{ error: any }>) => {
      state.reserveOrderError = action.payload.error;
      captureException(`addReserveOrderError error ${JSON.stringify(action.payload.error)}`);
      return state;
    },
    addReserveResponse: (state, action: PayloadAction<{ response: any }>) => {
      state.reserveResponse = action.payload.response;
      return state;
    },
    resetGlobalAll: (state) => {
      state.reserveOrderError = null;
      state.verifyFlightError = null;
      state.reserveResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      orderApi.endpoints.verifyFlight.matchFulfilled,
      (state, action) => {
        // ignore not found session data error, price change
        state.verifyFlightError = null;
        if (action.payload?.error?.length) {
          const errorArr = action.payload.error.map((err: string) => JSON.parse(err))
            .filter((errObj: any) => ![226].includes(+errObj.code));
          state.verifyFlightError = {
            message:
            errorArr.length ? errorArr.map(((errObj: any) => errObj.message)).join('.\n ') : null,
          };
        } else {
          const changedObj = get(action, 'payload.data') || {};
          state.verifyFlightError = get(changedObj, 'changed.priceDiff') ? action.payload.data : null;
        }
        if (state.verifyFlightError) {
          captureException(new Error(`[globalSlice] verifyFlight error = ${JSON.stringify(action.payload)}`));
        }
      },
    ).addMatcher(
      orderApi.endpoints.verifyFlight.matchRejected,
      (state, action) => {
        // ignore not found session data error, price change
        if (action.payload) {
          const data = action.payload?.data;
          if (typeof data === 'string') {
            state.verifyFlightError = JSON.parse(action.payload?.data as string || '{}');
          } else {
            state.verifyFlightError = action.payload.data as any;
          }
        } else {
          state.verifyFlightError = null;
        }
      },
    ).addMatcher(
      orderApi.endpoints.verifyFlight.matchPending,
      (state) => { state.verifyFlightError = null; },
    );
  },
});

export const { reducer: globalReducer } = slice;
export const {
  addReserveOrderError, addVerifyFlightError, addReserveResponse, resetGlobalAll, setOrderId,
} = slice.actions;
