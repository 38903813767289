import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  generalMenu: 0,
  headerTab: '',
};

export const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setGeneralMenu: (state, action: PayloadAction<number>) => {
      state.generalMenu = action.payload;
    },
    setHeaderTab: (state, action: PayloadAction<string>) => {
      state.headerTab = action.payload;
    },
  },
});

export const { setGeneralMenu, setHeaderTab } = slice.actions;
export const { reducer: menuReducer } = slice;
