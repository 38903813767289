import { TFlightCountResponse } from '@/slices/flightData/apis/flightDataApi';
import { get, isArray, isEmpty } from 'lodash';

export const transformCountResponse = (countResponse: {
  data: TFlightCountResponse;
}) => {
  if (Array.isArray(countResponse?.data)) {
    countResponse.data?.forEach((listDay) => {
      Object.values(listDay.grouped_by_date).forEach((statisticsData) => {
        statisticsData.min_fare = statisticsData.final_min_fare;
      });
    });
  }
  return countResponse.data;
};

export const transformCountResponseSeo = (countResponse: TFlightCountResponse) => {
  countResponse?.forEach((listDay) => {
    Object.values(listDay.grouped_by_date).forEach((statisticsData) => {
      statisticsData.min_fare = statisticsData.final_min_fare;
    });
  });
  return countResponse;
};
