export interface IContact {
  gender?: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  address?: string;
  note?: string;
}
export interface IBaggage {
  airline?: string;
  leg?: number;
  route?: string;
  code?: string;
  currency?: string;
  name?: string;
  price?: number;
  value?: string;
}

export interface IPassenger {
  index?: number;
  first_name?: string;
  last_name?: string;
  type?: string;
  gender?: number;
  birthday?: string;
  list_baggage?: IBaggage[];
}

export interface IFlight {
  airline: string;
  operation: string;
  start_point: string;
  end_point: string;
  depart_date: string;
  depart_time: string;
  flight_number: string;
  fare_class: string;
  price: number;
  leg: number;
}

export interface IFareData {
  session?: string;
  fare_data_id?: number;
  auto_issue?: number;
  route: string;
}

export interface IOrderPriceDetails {
  route: string;
  fare: number;
  fee: number;
  service_fee: number;
  tax: number;
  updated_price_diff: number;
  pt: number[];
  total_price: number;
}

export enum PriceTypeEnum {
  DISCOUNT_CHEAPEST_2 = 7,
  DISCOUNT_CHEAPEST_1 = 10,
  ALLOWED_SAME_DAY_ISSUE_PRICE_DIFF = 8,
  VXR_MARKUP = 9,
}

export interface IPassengerWithPrice extends IPassenger {
  list_price_detail: IOrderPriceDetails[];
}

export interface IOrderPaymentTransfer {
  id?: number;
  code?: string;
  bankId: string;
}

export interface IOrder {
  contact?: IContact;
  list_passenger?: IPassengerWithPrice[];
  list_flight?: Partial<IFlight>[];
  list_bus_route?: any[];
  type?: number,
  session?: any;
}

export interface IResultOrder {
  id?: number;
  code?: string;
  status?: number;
  amount?: number;
  provider?: string;
  operatorCode?: string;
  baseCode?: string | null;
  info?: string;
  routeInfo?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactGender?: number;
  contactPhone?: string;
  contactEmail?: string;
  expireTime?: Date | string;
  actions?: any[];
  orderInvoices?: any[];
  orderLines?: any[];
  tickets?: any[];
}

export interface IReserveddOrderResult {
  orders: IResultOrder[];
  changed?: { price_diff: { [route: string]: number } };
  list_fare_data?: IFareData[],
  session?: any,
}

// getOrderByCode
export interface IActionParam {
  id: number;
  actionId: number;
  paramName: string;
  paramValue: string;
  action: string;
}

export interface IAction {
  id: number;
  actionName: string;
  orderId: number;
  order: string;
  actionParams: IActionParam[];
}

export interface IInvoiceLine {
  id: number;
  invoiceId: number;
  amount: number;
  info: string;
  itemId: number;
  itemType: string;
  invoice: string;
}

export interface IPayment {
  id: number;
  transactionId: string;
  amount: number;
  paymentMethod: number;
  paymentInfo: string;
  paymentInvoices: string[];
}

export interface IPaymentInvoice {
  id: number;
  paymentId: number;
  invoiceId: number;
  amount: number;
  isRefunded: number;
  invoice: string;
  payment: IPayment;
}

export interface IInvoice {
  id: number;
  status: string;
  amount: number;
  remainingAmount: number;
  invoiceLines: IInvoiceLine[];
  orderInvoices: string[];
  paymentInvoices: IPaymentInvoice[];
}

export interface IOrderInvoice {
  id: number;
  invoiceId: number;
  orderId: number;
  amount: number;
  invoice: IInvoice;
  order: string;
}

export interface IInvoiceLine2 {
  id: number;
  invoiceId: number;
  amount: number;
  info: string;
  itemId: number;
  itemType: string;
  invoice: string;
}

export interface ILineItemType {
  type: number;
  name: string;
  invoiceLines: IInvoiceLine2[];
  orderLines: string[];
}

export interface IOrderLine {
  id: number;
  orderId: number;
  amount: number;
  status: string;
  info: string;
  itemId: number;
  itemType: string;
  order: string;
  lineItemType: ILineItemType;
}

export interface ITicket {
  id: number;
  code: string;
  orderCode: string;
  status: string;
  price: number;
  issueDate: Date;
  info: string;
  route: string;
  departureTime: string;
  arrivalTime: string;
  passengerFirstName: string;
  passengerLastName: string;
  passengerGender: number;
  passengerBirthday: Date;
  order: string;
  fromLocation?: string;
  toLocation?: string;
}

export interface IOrderDetail {
  id: number;
  code: string;
  status: string;
  amount: number;
  provider: string;
  operatorCode: string;
  baseCode: string;
  info: any;
  routeInfo: string;
  contactFirstName: string;
  contactLastName: string;
  contactGender: number;
  contactPhone: string;
  contactEmail: string;
  expireTime: string;
  createdTime: Date;
  updatedTime: Date;
  actions: IAction[];
  orderInvoices: IOrderInvoice[];
  orderLines: IOrderLine[];
  tickets: ITicket[];
}

export enum OrderStatusEnum {
  NEW = 1,
  PAID = 2,
  REFUNDED = 3,
  CANCELED = 4,
  PARTIALLY_PAID = 5,
  EXPIRED = 6,
}

export interface IGetOrdersStatusResponse {
  id: number;
  status: OrderStatusEnum;
}

export interface IGetOrdersStatusBody {
  ids: number[];
}

export interface IListFlight extends IFlight {
  flight_id?: string;
  flight_value?: string;
}

export interface IPassengerCount {
  adt: number;
  chd: number;
  inf: number;
}

export interface IVerifyFlight {
  session?: string;
  fare_data_id?: number;
  auto_issue?: number;
  passenger_count_obj: IPassengerCount;
  list_flight: IListFlight[];
  tourcode?: string;
  cacode?: string;
  viptext?: string;
}

export interface IBooking {
  session?: string;
  fare_data_id?: number;
  auto_issue?: number;
  passenger_count_obj: IPassengerCount;
  list_flight: IListFlight[];
  tourcode?: string;
  cacode?: string;
  viptext?: string;
}

export interface IGetSession {
  startPoint: string;
  toPoint: string;
  adt: number,
  chd: number,
  inf: number,
  flight: {
    departDate: string,
    airline: string,
    flightValue?: string,
    time?: string,
    flightNumber?: string,
    groupClass?: string,
  },
  returnFlight?: {
    departDate?: string,
    airline?: string,
    flightValue?: string,
    time?: string,
    flightNumber?: string,
    groupClass?: string,
  }
  roundTrip?: boolean,
}
