import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '../../common/fetchAuthedQuery';
import {
  IOrder,
  IReserveddOrderResult,
  IOrderDetail,
  IGetOrdersStatusResponse,
  IGetOrdersStatusBody,
  IVerifyFlight,
  IOrderPaymentTransfer,
  IGetSession,
} from '.';
import camelcaseKeysDeep from 'camelcase-keys-deep';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchAuthedQuery({
    baseUrl: `${process.env.GOM_SERVICE_URL}`,
  }),
  endpoints: (builder) => ({
    orderReserve: builder.mutation<IReserveddOrderResult, Partial<IOrder>>({
      query: (body) => ({
        url: '/v1/order/reserve',
        method: 'POST',
        body,
      }),
    }),
    getSession: builder.mutation<any, Partial<IGetSession>>({
      query: (body) => ({
        url: '/v1/order/session',
        method: 'POST',
        body,
      }),
    }),
    getOrderByCode: builder.query<IOrderDetail[], any>({
      query: ({ code, id, disableGetAllCode }) => {
        if (id) {
          return `/v1/order?id=${id}${
            !disableGetAllCode ? '&is_get_all_same_base_code=1' : ''
          }`;
        }
        if (code) {
          return `/v1/order?code=${code}${
            !disableGetAllCode ? '&is_get_all_same_base_code=1' : ''
          }`;
        }
        return '';
      },
      transformResponse: (response: IOrderDetail[]) => response.sort((x, y) => x.id - y.id),
      keepUnusedDataFor: 60,
    }),

    getOrdersStatus: builder.query<
    IGetOrdersStatusResponse[],
    IGetOrdersStatusBody
    >({
      query: (body) => ({
        url: '/v1/order/list-status',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 1,
    }),

    verifyFlight: builder.mutation<any, IVerifyFlight>({
      query: (body) => ({
        url: '/v1/order/verifyFlight',
        method: 'POST',
        body,
      }),
      transformResponse: (response: any) => camelcaseKeysDeep(response),
    }),
    sendPaymentTransferEmail: builder.query<IReserveddOrderResult, Partial<IOrderPaymentTransfer>>({
      query: (body) => ({
        url: '/v1/order',
        method: 'PUT',
        body,
      }),
    }),

  }),
});

export const {
  useOrderReserveMutation,
  useGetSessionMutation,
  useLazyGetOrderByCodeQuery,
  useGetOrdersStatusQuery,
  useVerifyFlightMutation,
  useLazySendPaymentTransferEmailQuery,
} = orderApi;
