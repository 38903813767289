import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'appInfoDrawer',
  initialState: false,
  reducers: {
    setAppInfoDrawer: (state, action: PayloadAction<boolean>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { reducer: appInfoDrawerReducer } = slice;
export const { setAppInfoDrawer } = slice.actions;
