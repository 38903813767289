import {
  uniqueId,
} from 'lodash';
/* eslint-disable no-plusplus */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  format, isValid, parse,
} from 'date-fns';
// import moment from 'moment';
import type { TPassengerSlice, TContactInfo, TPassenger } from './type.d';
// import { CONTACT_INFO, CONTACT_LIST } from '@/utils/constants';

const contactInfo = null;
const contactList = [] as TPassenger[];
// if (typeof window !== 'undefined') {
//   const { localStorage } = window;
//   const contactInfoStorage = localStorage.getItem(CONTACT_INFO);
//   const contactListStorage = localStorage.getItem(CONTACT_LIST);
//   contactInfo = JSON.parse(contactInfoStorage || 'null') as TContactInfo;
//   contactList = JSON.parse(contactListStorage || 'null')
//     ? JSON.parse(contactListStorage || 'null')
//     : [];
//   contactList = contactList
//     .filter((c: any) => moment(c.birthday, 'DD/MM/YYYY').isValid())
//     .map((c: any, index: number) => {
//       c.index = index;
//       return c;
//     });
// }

const initialState: TPassengerSlice = {
  contactList,
  contactInfo,
  selectedPassengerArr: [],
};

export const slice = createSlice({
  name: 'passenger',
  initialState,
  reducers: {
    setContactInfo: (state, action: PayloadAction<TContactInfo>) => {
      state.contactInfo = action.payload;
      // localStorage.setItem(CONTACT_INFO, JSON.stringify(state.contactInfo));
    },

    addContact: (
      state,
      action: PayloadAction<{ contact: TPassenger; index: number }>,
    ) => {
      const { contact } = action.payload;
      const birthDay = contact.birthday
        ? parse(contact.birthday, 'dd/MM/yyyy', new Date())
        : new Date(contact.birthday);
      const newContact = {
        ...contact,
        birthday: format(isValid(birthDay) ? birthDay : new Date(contact.birthday), 'dd/MM/yyyy'),
        id: uniqueId(),
        index: action.payload.index,
      };
      state.contactList.push(newContact);

      const scIndex = state.selectedPassengerArr.findIndex(
        (sc) => sc.index === action.payload.index,
      );
      if (scIndex !== -1) {
        state.selectedPassengerArr[scIndex] = newContact;
      } else {
        state.selectedPassengerArr.push(newContact);
      }

      // localStorage.setItem(CONTACT_LIST, JSON.stringify(state.contactList));
      return state;
    },
    localSaveAndAddContact: (
      state,
      action: PayloadAction<{ contact: TPassenger; index: number }>,
    ) => {
      // const data = state.contactList?.map((x) => pick(x, ['birthday', 'firstName', 'lastName', 'gender', 'type']));
      // const actionContact = action.payload.contact as any;
      // const isExist = actionContact ? find(data, (contact: any) => every(['firstName', 'lastName', 'birthday', 'type', 'gender'], (field: any) => {
      //   if (field === 'birthday' && isValid(new Date(actionContact[field]))) {
      //     return contact[field] === format(new Date(actionContact[field]), 'dd/MM/yyyy');
      //   }
      //   return contact[field] === actionContact[field];
      // })) : false;
      const { contact } = action.payload;
      const birthDay = contact.birthday
        ? parse(contact.birthday, 'dd/MM/yyyy', new Date())
        : new Date(contact.birthday);
      const newContact = {
        ...contact,
        birthday: format(
          isValid(birthDay) ? birthDay : new Date(contact.birthday),
          'dd/MM/yyyy',
        ),
        id: uniqueId(),
        index: action.payload.index,
      };
      // if (!isExist) {
      //   state.contactList.push(newContact);
      //   localStorage.setItem(CONTACT_LIST, JSON.stringify(state.contactList));
      // }

      state.selectedPassengerArr = state.selectedPassengerArr.map((sc, idx) => { sc.index = idx; return sc; });
      const scIndex = state.selectedPassengerArr.findIndex(
        (sc) => sc.index === action.payload.index,
      );
      if (scIndex !== -1) {
        state.selectedPassengerArr[scIndex] = newContact;
        const { contactIndex } = state.selectedPassengerArr[scIndex];
        if (!Number.isNaN(contactIndex) && contactIndex > -1) {
          state.contactList[contactIndex] = newContact;
        } else {
          state.contactList.push(newContact);
        }
      } else {
        state.selectedPassengerArr.push(newContact);
        state.contactList.push(newContact);
      }
      // localStorage.setItem(CONTACT_LIST, JSON.stringify(state.contactList));
    },
    setSelectedContact: (
      state,
      action: PayloadAction<{ contact: TPassenger; index: number }>,
    ) => {
      // passenger 1 -> 1 is index
      const { contact, index } = action.payload;
      const scIndex = state.selectedPassengerArr.findIndex(
        (sc) => sc.index === action.payload.index,
      );
      if (scIndex !== -1) {
        const selectedPassengerArr = [...state.selectedPassengerArr];
        const birthDay = contact.birthday
          ? parse(contact.birthday, 'dd/MM/yyyy', new Date())
          : new Date(contact.birthday);
        selectedPassengerArr[scIndex] = {
          ...contact,
          birthday: format(
            isValid(birthDay) ? birthDay : new Date(contact.birthday),
            'dd/MM/yyyy',
          ),
          index,
          gender: contact.gender,
        };
        state.selectedPassengerArr = selectedPassengerArr;
        return;
      }
      state.selectedPassengerArr = [
        ...state.selectedPassengerArr,
        {
          ...contact,
          index,
          gender: contact.gender,
        },
      ];
    },

    setAllSelectedContact: (state, action: PayloadAction<TPassenger[]>) => {
      state.selectedPassengerArr = action.payload;
    },

    resetSelectedContact: (state) => {
      state.selectedPassengerArr = [];
    },

    removeContact: (state, action: PayloadAction<TPassenger>) => {
      state.contactList = state.contactList.filter(
        (contact) => contact.id !== action.payload.id,
      );
      state.selectedPassengerArr = state.selectedPassengerArr.filter(
        (sc) => sc.id !== action.payload.id,
      );
      // localStorage.setItem(CONTACT_LIST, JSON.stringify(state.contactList));
    },

    editContact: (state, action: PayloadAction<TPassenger>) => {
      const contactId = state.contactList.findIndex(
        (c) => c.id === action.payload.id,
      );

      const { index, ...rest } = action.payload;

      state.contactList[contactId] = action.payload;

      const scIndex = state.selectedPassengerArr.findIndex(
        (sc) => sc.id === rest.id,
      );
      if (scIndex !== -1) {
        state.selectedPassengerArr[scIndex] = {
          ...rest,
          index: state.selectedPassengerArr[scIndex].index,
        };
      }
      // localStorage.setItem(CONTACT_LIST, JSON.stringify(state.contactList));
      return state;
    },

    removeSelectedContact: (state, action: PayloadAction<{ id: string }>) => {
      state.selectedPassengerArr = state.selectedPassengerArr.filter(
        (contact) => contact.id !== action.payload.id,
      );
    },

    emptyContactInfoNote: (state) => {
      if (state.contactInfo) state.contactInfo.note = '';
    },
  },
});

export const { reducer: passengerReducer } = slice;
export const {
  emptyContactInfoNote,
  setContactInfo,
  addContact,
  setSelectedContact,
  removeSelectedContact,
  removeContact,
  editContact,
  setAllSelectedContact,
  resetSelectedContact,
  localSaveAndAddContact,
} = slice.actions;
