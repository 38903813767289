/* eslint-disable @typescript-eslint/dot-notation */
import { createApi } from '@reduxjs/toolkit/query/react';
import qs from 'qs';
/* eslint-disable @typescript-eslint/no-var-requires */
import { fetchAuthedQuery } from '@/slices/common/fetchAuthedQuery';
import { camelCaseKeysToUnderscore } from '@/utils/toSnakeCase';
import {
  get, isEmpty, sortBy, sortedUniq,
} from 'lodash';
import { BusRouteData, BusRouteResponse } from '../slices/type';
import { TBusRouteDataResponse } from './type';
import { getMappingBusRouteCardData } from '@/utils/busRouteCardMapping';
import { TFilterBusRoute } from '../../filterBusRoute/filterBusRouteSlice';
import { TSelectedSort } from '../../sortingBusRoute/sortingBusRouteSlice';
import { add, parse, sub } from 'date-fns';
import { DEFAULT_TIME_OBJ } from '@/utils/constants';
import { generateTimeId } from '@/utils/generateTimeId';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export type FilterRoute = Partial<TFilterBusRoute> & {
  date: string;
  from: number; // e.g. ['HAN', 'SGN]
  to: number;
  sort: TSelectedSort;
  time_id?: string;
};

export type TMinFareRespObj = {
  date: string;
  min_fare: string;
  last_updated_at: number;
  duration: number;
};
export type TMinFareObj = {
  [day: string]: number;
};
export type TMinFareRequest = {
  from: number;
  to: number;
  fromDate: string;
  toDate: string;
  mode: string;
};

export type TBusRouteCountRequest = {
  from: number;
  to: number;
  date?: string;
  date_range?: {
    min: string;
    max: string;
  };
  month_years?: string[];
  disable_update_filter?: number;
  companies?: string[];
  time_id: string;
};

export type TTriggerBusRouteBody = {
  from: number;
  to: number;
  date: string;
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const transformResponseBusData = (response: { data: any, page?: any }) => {
  const casedResponse = camelcaseKeysDeep(response) as BusRouteResponse;
  if (casedResponse.data && casedResponse.data.length) {
    const sortedData = sortedUniq(sortBy(casedResponse.data.map((busRoute) => {
      const routeMapping = getMappingBusRouteCardData(busRoute);
      return routeMapping ? routeMapping.price : 0;
    }), (val) => val));
    const cheapestPrice = get(sortedData, '0');
    const cheapestPrice2 = get(sortedData, '1');

    casedResponse.data = casedResponse.data.map((busRoute) => {
      const routeMapping = getMappingBusRouteCardData(busRoute);
      if (routeMapping) {
        const {
          price: routePrice, originalPrice, schedule, discountAmount,
        } = routeMapping;

        schedule.discountAmount = 0;
        schedule.priceTypeArr = [];
        if (routePrice === cheapestPrice) {
          busRoute.route.schedules[0].isCheapest = 1;
        } else if (routePrice === cheapestPrice2) {
          busRoute.route.schedules[0].isCheapest2 = 1;
        }

        schedule.lastMinutePercent = 0;
        if (schedule.isLastMinuteActivated && routePrice) {
          schedule.discountAmount = discountAmount;
          schedule.lastMinutePercent = 100 - Math.round(((originalPrice - discountAmount) / originalPrice) * 100);
        }

        // schedule.finalPrice = routePrice - schedule.discountAmount;
        schedule.finalPrice = routePrice;
        busRoute.route.schedules = [schedule];
      }
      return busRoute;
    });
    if (sortedBest) {
      casedResponse.data = casedResponse.data.sort((d1, d2) => {
        const isCheapestValue = (d1.route.schedules[0].isCheapest || 0) * -100;
        const isCheapest2Value = (d1.route.schedules[0].isCheapest2 || 0) * -10;
        const isCheapestValue2 = (d2.route.schedules[0].isCheapest || 0) * -100;
        const isCheapest2Value2 = (d2.route.schedules[0].isCheapest2 || 0) * -10;
        return (isCheapestValue + isCheapest2Value) - (isCheapestValue2 + isCheapest2Value2);
      });
    }
    return casedResponse.data.filter((busData) => busData.company);
  }
  return casedResponse.data;
};

export const transformFilter = ({ filter: filterSource, sort, disablePaginated = true }: { filter: any, sort?: any, disablePaginated?: boolean }) => {
  const filter = JSON.parse(JSON.stringify(filterSource)) as TFilterBusRoute;
  const sortObj = (!isEmpty(sort) && sort.type) ? { sort: `${camelToSnakeCase(sort.type)}:${sort.value}` } : {};
  const paginationObj = !disablePaginated ? {
    page: filter.page || 1,
    pagesize: filter.pagesize || process.env.NEXT_PUBLIC_FLIGHT_SEARCH_SIZE,
  } : {};
  if (filter.companies && !Array.isArray(filter.companies)) {
    filter.companies = [filter.companies];
  }
  if (isEmpty(filter.companies)) {
    filter.companies = [];
  }
  // if (filter.departureTime) {
  //   filter.departureTime.min = sub(new Date(filter.departureTime.min), { hours: 7 });
  //   filter.departureTime.max = sub(new Date(filter.departureTime.max), { hours: 7 });
  // }
  if (filter.arrivalTime) {
    const arrivalTimeMin = new Date(filter.arrivalTime.min);
    const arrivalTimeMax = new Date(filter.arrivalTime.max);
    filter.arrivalTime.min = sub(arrivalTimeMin, { hours: 7 });
    filter.arrivalTime.max = sub(arrivalTimeMax, { hours: 7 });
  }

  const decoratedFilter = {
    onlineTicket: 0,
    isPromotion: 0,
    covidUtility: 0,
    enabledGps: 0,
    useGoyoloConfig: 1,
    hasCop: 0,
    onlineReserved: 1,
    suggestion: 'DEFAULT',
    fare: {
      min: 0,
      max: 2000000,
    },
    availableSeat: {
      min: 1,
      max: 50,
      first: 0,
      middle: 0,
      last: 0,
    },
    rating: {
      min: 0,
      max: 5,
    },
    limousine: 0,
    hasUnfixedPoint: 0,
    ...filter,
  };
  return qs.stringify({
    filter: camelCaseKeysToUnderscore(decoratedFilter),
    time_id: generateTimeId(),
    ...sortObj,
    ...paginationObj,
    use_goyolo_config: 1,
  });
};

let sortedBest = false;

export const busDataApi = createApi({
  reducerPath: 'busDataApi',
  baseQuery: fetchAuthedQuery({
    baseUrl: process.env.VROUTE_BASE_URL,
  }),
  keepUnusedDataFor: 0,
  // refetchOnFocus: true,
  // refetchOnMountOrArgChange: true,
  // refetchOnReconnect: true,
  endpoints: (builder) => ({
    getBusData: builder.query<{ data: BusRouteData[], page: number, total: number, total_pages: number }, FilterRoute>({
      query: (filter) => {
        const { sort, filter: rest } = extractSortAndFilter(filter);
        return `/v2/route?${transformFilter({
          filter: rest,
          sort,
          disablePaginated: false,
        })}`;
      },
      transformResponse: (response: { data: any, page: number, total: number, total_pages: number }) => camelcaseKeysDeep(response),
    }),
    getBusRouteStatistics: builder.query<TBusRouteDataResponse[], TBusRouteCountRequest>({
      query: (filter) => `/v2/route/min_statistic?${transformFilter(extractSortAndFilter(filter))}`,
      transformResponse: (response: { data: any }) => (get(response, 'data')),
    }),
    getBusRouteStatistics2: builder.query<TBusRouteDataResponse[], TBusRouteCountRequest>({
      query: (filter) => `/v2/route/min_statistic?${transformFilter(extractSortAndFilter(filter))}`,
      transformResponse: (response: { data: any }) => (get(response, 'data')),
    }),
    getBusRouteById: builder.query<BusRouteData, { id: string }>({
      query: ({ id }) => `/v2/route/${id}`,
      transformResponse: (response: { data: any }) => camelcaseKeysDeep(response.data?.[0]),
    }),
  }),
});

function parseDateAndTime(date: string, time: string) {
  const dateTime = `${date} ${time}`;
  const parsedDate = add(parse(dateTime, 'yyyy-MM-dd HH:mm', new Date()), { hours: 7 });
  return parsedDate.toISOString();
}

function checkDefaultTime(timeObj?: any) {
  return (timeObj && JSON.stringify(timeObj) === JSON.stringify(DEFAULT_TIME_OBJ));
}

export function extractSortAndFilter(filter: any) {
  const { sort: oldSort, ...rest } = JSON.parse(JSON.stringify(filter));
  const sort = oldSort || {};
  if (sort.type === 'best') {
    sort.type = 'time';
    sort.value = 'asc';
    sortedBest = true;
  } else {
    sortedBest = false;
  }

  if (!isEmpty(rest.time)) {
    const date = rest.date.slice(0, 10);
    if (rest.time.arrivalTime) {
      if (checkDefaultTime(rest.time.arrivalTime)) {
        delete rest.time.arrivalTime;
      } else {
        rest.time.arrivalTime.min = parseDateAndTime(date, rest.time.arrivalTime.min);
        rest.time.arrivalTime.max = parseDateAndTime(date, rest.time.arrivalTime.max);
      }
    }
    if (rest.time.departureTime) {
      if (!checkDefaultTime(rest.time.departureTime)) {
        rest.time.time = {
          min: rest.time.departureTime.min,
          max: rest.time.departureTime.max,
        };
      }
      delete rest.time.departureTime;
    }
  }
  if (rest.date_range) {
    delete rest.date;
  }

  const newFilter = {
    ...rest,
    ...(rest.time || {}),
    ...(rest.other || {}),
  };

  if (!(rest.time && rest.time.time)) {
    delete newFilter.time;
  }
  delete newFilter.other;
  return {
    sort,
    filter: newFilter,
  };
}

export const {
  useLazyGetBusRouteStatisticsQuery,
  useLazyGetBusRouteStatistics2Query,
  useGetBusDataQuery,
  useGetBusRouteByIdQuery,
  useLazyGetBusRouteByIdQuery,
  endpoints,
  usePrefetch,
  util,
} = busDataApi;
