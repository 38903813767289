import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Promotion } from '.';

export interface IPayMethodState {
  promotions: Promotion[];
}
// const isProd = process.env.APP_ENV === 'production';

export const slice = createSlice({
  name: 'payPromotion',
  initialState: {
    promotions: [],
  } as IPayMethodState,
  reducers: {
    setPromotions: (state, action: PayloadAction<{ data: Promotion[] }>) => {
      const updatePromotion = action?.payload?.data.filter((promotion) => promotion.code.includes('GOYOLO'));
      state.promotions = updatePromotion;
    },
  },
});

export const { reducer: payPromotionReducer } = slice;
export const { setPromotions } = slice.actions;
