import { defaultAirlines } from '@/components/FilterAirline/desktop';
/* eslint-disable @typescript-eslint/dot-notation */
import { createApi } from '@reduxjs/toolkit/query/react';
import qs from 'qs';
import { TFlightCountSeoResponse } from '@/components/RoutePage/SectionManager/type';
import { TSelectedSort } from '../../sortingFlight/sortingFlightSlice';
/* eslint-disable @typescript-eslint/no-var-requires */
import { fetchAuthedQuery } from '../../common/fetchAuthedQuery';
import { FlightData } from '../slices';
import { TFilterFlight } from '../../filterFlight/filterFlightSlice';
import { camelCaseKeysToUnderscore } from '../../../utils/toSnakeCase';
import {
  camelCase,
  get,
  isEmpty, sortBy, sortedUniq,
} from 'lodash';
import { transformCountResponse } from '@/utils/transformCountResponse';
import {
  generateFlightCountReqeust, generateFlightDataRequest, TGenerateFlightCountRequest, TGenerateFlightDataRequest,
} from '@/utils/generateFlightRequest';
import { PriceTypeEnum } from '@/slices/gom/apis/index.d';
import { generateTimeId } from '@/utils/generateTimeId';

export const camelCaseDeep = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelCaseDeep(v));
  } if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelCaseDeep(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export type FilterRoute = Partial<TFilterFlight> & {
  date: string;
  from: string[]; // e.g. ['HAN', 'SGN]
  to: string[];
  quantity: number;
  sort: TSelectedSort;
  fare_class?: string[];
  cabin?: string[];
  time_id: string;
  child_infant_count: number;
};

export type TMinFareRespObj = {
  date: string;
  min_fare: string;
  last_updated_at: number;
  duration: number;
};
export type TMinFareObj = {
  [day: string]: number;
};
export type TMinFareRequest = {
  from: string[];
  to: string[];
  fromDate: string;
  toDate: string;
  mode: string;
};

export type TFlightCountRequest = {
  from: string[];
  to: string[];
  date?: string;
  date_range?: {
    min: string;
    max: string;
  };
  month_years?: string[];
  quantity: number;
  fare_class: string[];
  cabin: string[];
  child_infant_count: number,
  disable_update_filter?: number;
  is_count_tet_price?: number;
  airline?: string[];
  time_id: string;
  selected_date?: string;
};

export type TFlightCountSeoRequest = {
  from: string[];
  to: string[];
  date?: string;
  date_range?: {
    min: string;
    max: string;
  };
  quantity: number;
  child_infant_count?: number;
  disable_update_filter?: number;
  airline?: string[]
};

export type TFlightCountResponse = {
  grouped_by_date: {
    [depart_date: string]: GroupedByDateResponse
  }
}[];
export type GroupedByDateResponse = {
  count: number,
  min_fare: number
  final_min_fare: number,
  duration: number
  grouped_by_airlines: {
    [key: string]: {
      count: number,
      min_fare: number
    }
  },
  min_fare_arr?: number[]
};

export type TTriggerFlightBody = {
  from: string[];
  to: string[];
  date: string;
};
export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const transformFlightQueryResponse = (response: { data: FlightData[] }) => {
  const casedResponse = camelCaseDeep(response);
  if (casedResponse.data && casedResponse.data.length) {
    const sortedData = sortedUniq(sortBy(casedResponse.data.map((flight: any) => flight.route.schedules[0].totalPriceAdult), (val) => val));
    const cheapestPrice = get(sortedData, '0');
    const cheapestPrice2 = get(sortedData, '1');

    casedResponse.data = casedResponse.data.map((flight: any) => {
      const markup = flight.route.schedules[0].markupAmount;

      if (!flight.segments && flight.segment) {
        flight.segments = flight.segment;
      }
      if (get(flight, 'route.schedules[0].fareClass')) {
        flight.route.schedules[0].fareClass = (flight.route.schedules[0].fareClass || '').toUpperCase();
      }

      flight.route.schedules[0].totalPriceAdultOriginal = flight.route.schedules[0].totalPriceAdult;
      flight.route.schedules[0].totalPriceAdult += markup;
      flight.route.schedules[0].fareAdultOriginal = flight.route.schedules[0].fareAdult;
      flight.route.schedules[0].fareAdult += markup;
      flight.route.schedules[0].totalPriceChildOriginal = flight.route.schedules[0].totalPriceChild;
      flight.route.schedules[0].totalPriceChild += markup;
      flight.route.schedules[0].fareChildOriginal = flight.route.schedules[0].fareChild;
      flight.route.schedules[0].fareChild += markup;
      return flight;
    });
    return casedResponse.data.filter((flight: any) => flight.company);
  }
  return casedResponse.data;
};

export const transformFilter = (filterSource: any, sort?: any, disablePaginated?: boolean, test?: string) => {
  const filter = JSON.parse(JSON.stringify(filterSource || {}));
  const sortObj = sort ? { sort: `${camelToSnakeCase(sort.type)}:${sort.value}` } : {};
  const paginationObj = !disablePaginated ? {
    page: filter.page || 1,
    pagesize: filter.pagesize || process.env.NEXT_PUBLIC_FLIGHT_SEARCH_SIZE,
  } : {};
  if (!isEmpty(filter.airline)) {
    if (!Array.isArray(filter.airline)) {
      filter.airline = [filter.airline];
    }
    filter.airline = filter.airline.filter((al: string) => al);
    if (filter.airline.includes('VN') && !filter.airline.includes('BL')) {
      filter.airline.push('BL');
    }
  }
  if (!isEmpty(filter.airline)) {
    filter.airline = filter.airline.filter((item: any) => defaultAirlines.includes(item));
  }

  const filterObj = {
    filter: camelCaseKeysToUnderscore(filter),
    ...sortObj,
    ...paginationObj,
    time_id: generateTimeId(),
  };
  return qs.stringify(filterObj);
};

export type TFlightDataResponse = {
  data: FlightData[],
  page: number,
  total: number,
  minPriceData: { airline: string
    finalPrice:
    number,
    price:
    number }
  totalPages: number,
  progressLoading: { [key: string]: { [key: string]: string } }
};

export const flightDataApi = createApi({
  reducerPath: 'flightDataApi',
  baseQuery: fetchAuthedQuery({
    baseUrl: process.env.VROUTE_BASE_URL,
  }),
  keepUnusedDataFor: 0,
  // refetchOnFocus: true,
  // refetchOnMountOrArgChange: true,
  // refetchOnReconnect: true,
  endpoints: (builder) => ({
    getFlightData2: builder.query<TFlightDataResponse, TGenerateFlightDataRequest>({
      query: (filter) => {
        const { sort, ...rest } = generateFlightDataRequest(filter);
        return `/v2/route/flight?${transformFilter(rest, sort)}`;
      },
      transformResponse: (response: TFlightDataResponse) => camelCaseDeep(response),
    }),
    getFlightData: builder.query<FlightData[], TGenerateFlightDataRequest>({
      query: (filter) => {
        const { sort, ...rest } = generateFlightDataRequest(filter);
        return `/v2/route/flight?${transformFilter(rest, sort)}`;
      },
      // transformResponse: (response: { data: FlightData[] }) => transformFlightQueryResponse(response),
      transformResponse: (response: { data: FlightData[] }) => camelCaseDeep(response.data),
    }),
    getFlightStatistics: builder.query<TFlightCountResponse, TGenerateFlightCountRequest>({
      query: (filter) => `/v2/route/flight/count?${transformFilter(generateFlightCountReqeust(filter), undefined, true, 'test1')}`,
      transformResponse: transformCountResponse,
    }),
    getFlightStatistics2: builder.query<
    TFlightCountResponse,
    TGenerateFlightCountRequest
    >({
      query: (filter) => `/v2/route/flight/count?${transformFilter(generateFlightCountReqeust(filter), undefined, true, 'test2')}`,
      transformResponse: transformCountResponse,
    }),
    getFlightById: builder.query<FlightData, { id: string }>({
      query: ({ id }) => `/v2/route/flight/${id}`,
      transformResponse: (response: { data: any }) => camelCaseDeep(response.data?.[0]),
    }),
    removeFlightById: builder.query<FlightData, { id: string, body: { from: string, to: string, date: string, airline: string } }>({
      query: ({ id, body }) => ({
        url: `/v2/route/flight/${id}`,
        method: 'DELETE',
        body,
      }),
    }),
    getFlightStatisticsSeoRoute: builder.query<TFlightCountSeoResponse, TFlightCountSeoRequest>({
      query: (filter) => `/v2/route/flight/count_seo_route?${transformFilter(filter, null, false)}`,
      transformResponse: (countResponse: { data: TFlightCountSeoResponse }) => countResponse.data,
    }),
    getFlightStatisticsSeoRoute2: builder.query<TFlightCountSeoResponse, TFlightCountSeoRequest>({
      query: (filter) => `/v2/route/flight/count_seo_route?${transformFilter(filter, null, false)}`,
      transformResponse: (countResponse: { data: TFlightCountSeoResponse }) => countResponse.data,
    }),
  }),
});

export const {
  useLazyGetFlightStatisticsQuery,
  useLazyGetFlightStatistics2Query,
  useLazyGetFlightByIdQuery,
  useGetFlightDataQuery,
  useLazyGetFlightDataQuery,
  useGetFlightData2Query,
  useLazyGetFlightData2Query,
  useLazyRemoveFlightByIdQuery,
  useLazyGetFlightStatisticsSeoRouteQuery,
  useLazyGetFlightStatisticsSeoRoute2Query,
  endpoints,
  usePrefetch,
  util,
} = flightDataApi;
