/* eslint-disable @typescript-eslint/no-var-requires */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TFareRule } from '../slices/type';

const camelcaseKeysDeep = require('camelcase-keys-deep');

type TFlightValue = {
  flightValue: string;
};

type TfareRuleApiParams = {
  session: string;
  listFlight: TFlightValue[];
  fareDataId: number;
};

export const fareRuleApi = createApi({
  reducerPath: 'fareRuleApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getFareFules: builder.query<TFareRule, TfareRuleApiParams>({
      query: (data) => ({
        url: '/api/getFareRule',
        method: 'POST',
        body: { ListFareData: [data] },
      }),
      transformResponse: (response) => camelcaseKeysDeep(response),
    }),
  }),
});

export const { useGetFareFulesQuery } = fareRuleApi;
