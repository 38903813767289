import { IBusTrip } from '../slices/bus/pickDropPoint/apis/type';
import { BusRouteData } from '@/slices/bus/busData/slices/type';
import { format } from 'date-fns';
import { get } from 'lodash';
import { getBusRoutePrice } from './getBusRoutePrice';
import { getMatchingSchedule } from './getMatchingSchedule';

export const getMappingBusRouteCardData = (busRoute: BusRouteData) => {
  const { route, company, hasCop } = busRoute;

  const {
    schedules, from, to, duration,
  } = route;

  const schedule = schedules.length > 1 ? getMatchingSchedule(schedules) : schedules[0];
  if (!schedule) {
    return null;
  }

  const {
    pickupDate, arrivalTime: dropoffDate, finalPrice, discountAmount: finalDiscountAmount, lastMinutePercent, seatType, totalAvailableSeats, totalSeats, tripCode, priceTypeArr,
    vehicleQuality,
    // vehicleType,
  } = schedule;
  const routePriceObj = getBusRoutePrice(schedule);
  if (!routePriceObj) {
    return null;
  }
  const price = routePriceObj.price || finalPrice;
  const discountAmount = routePriceObj.discountAmount || finalDiscountAmount;
  const { ratings, images } = company;
  const companyImageUrlObj = get(images, '[0].files') || {};
  const companyImgUrl = companyImageUrlObj[Object.keys(companyImageUrlObj)[0]];
  // const isVIPVehicle = /limousine/i.test(vehicleType);
  const isVIPVehicle = vehicleQuality === 2;

  const { dateTime: departureDateTime, date: departureDate, time: departureTime } = formatDateTime(pickupDate);
  const { dateTime: arrivalDateTime, date: arrivalDate, time: arrivalTime } = formatDateTime(dropoffDate);

  return {
    departureDateTime,
    arrivalDateTime,
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
    departurePlace: from.name,
    departureAddress: from.address,
    departureState: from.cityName,
    arrivalPlace: to.name,
    arrivalAddress: to.address,
    arrivalState: to.cityName,
    originalPrice: routePriceObj.originalPrice,
    price,
    discountAmount,
    companyName: company.name,
    companyImgUrl,
    route,
    duration,
    schedule,
    lastMinutePercent,
    seatType,
    isVIPVehicle,
    ratingScore: ratings.overall,
    ratingComment: ratings.comments,
    availableSeats: totalAvailableSeats,
    totalSeats,
    tripCode,
    priceTypeArr,
    isHasCOP: hasCop,
    vehicleType: schedule.vehicleType,
  };
};

export const getMappingBusRouteCardFromApi = (busRoute: IBusTrip) => {
  const { route, operator } = busRoute;
  const {
    departure, destination, duration, vehicleSeatType,
  } = route;
  return {
    departureTime: departure.time,
    arrivalTime: destination.time,
    departurePlace: departure.name,
    arrivalPlace: destination.name,
    companyName: operator.name,
    duration,
    imageUrl: operator?.images[0]?.files['1000x600'],
    ratingScore: operator.ratings.overall,
    ratingComment: operator.ratings.totalRating,
    seatType: vehicleSeatType,
  };
};

export const formatDateTime = (dateStr: string) => {
  const date = new Date(dateStr);
  return {
    dateTime: format(date, 'yyyy-MM-dd HH:mm'),
    date: format(date, 'yyyy-MM-dd'),
    time: format(date, 'HH:mm'),
  };
};

export const formatDate = (dateStr: string) => format(new Date(dateStr), 'yyyy-MM-dd');

export const formatTime = (dateStr: string) => format(new Date(dateStr), 'HH:mm');
