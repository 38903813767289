/* eslint-disable import/no-cycle */
// import { RECENT_AREAS_KEY } from '@/utils/constants';
import {
  createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import type { IFlightResultItem } from '../../../containers/SearchFlightTab/SearchFlightResultItem/index.d';

const recentAreas = [] as IFlightResultItem[];
// if (typeof window !== 'undefined') {
//   const { localStorage } = window;
//   const recentAreasString = localStorage.getItem(RECENT_AREAS_KEY);
//   recentAreas = JSON.parse(recentAreasString || '[]') as IFlightResultItem[];
// }

const recentSearchLimit = parseInt(process.env.NEXT_PUBLIC_RECENT_SEARCH_MONTH_LIMIT || '6', 10) - 1;
export const slice = createSlice({
  name: 'area',
  initialState: {
    currentArea: '',
    recentAreas: recentAreas || [],
  },
  reducers: {
    addArea: (state, action: PayloadAction<{ area: IFlightResultItem }>) => {
      // eslint-disable-next-line max-len
      const newArea = action.payload.area;
      if (newArea) {
        const sameAreaIdx = state.recentAreas.findIndex((area) => area.location === newArea?.location
        && area.airports.length === newArea.airports.length);
        if (sameAreaIdx !== -1) {
          state.recentAreas = state.recentAreas.slice(0, sameAreaIdx)
            .concat(state.recentAreas.slice(sameAreaIdx + 1));
        }

        if (state.recentAreas.length > recentSearchLimit) {
          state.recentAreas = state.recentAreas.slice(0, recentSearchLimit);
          // Max 6 items in recent searchs
        }
        state.recentAreas.unshift(action.payload.area);
        // localStorage.setItem(recentAreasKey, JSON.stringify(state.recentAreas));
      }
    },
    // eslint-disable-next-line max-len
    setCurrentArea: (state, action: PayloadAction<{ currentArea: string }>) => {
      state.currentArea = action.payload.currentArea;
    },
  },
});

export const { reducer: areaReducer } = slice;
export const { setCurrentArea, addArea } = slice.actions;
