/* eslint-disable import/no-duplicates */
import { PayloadAction } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { TSelectedBaggage, TSelectedBaggageItem } from './type';

export const baggageAdapter = createEntityAdapter<TSelectedBaggage[]>();
const initialState: TSelectedBaggage = {
  selectedBaggages: {
    depart: [],
    return: [],
  },
};

export const slice = createSlice({
  name: 'baggage',
  initialState,
  reducers: {

    setSelectedBaggage: (
      state,
      action: PayloadAction<{
        selectedBaggage: TSelectedBaggageItem;
        type: 'depart' | 'return';
      }>,
    ) => {
      const { type, selectedBaggage } = action.payload;
      const index = state.selectedBaggages[type].findIndex(
        (sb) => sb.contactId === selectedBaggage.contactId,
      );
      if (index !== -1) {
        if (state.selectedBaggages[type][index].baggage.code === selectedBaggage.baggage.code && state.selectedBaggages[type][index].baggage.value === selectedBaggage.baggage.value) {
          state.selectedBaggages[type].splice(index, 1);
          return;
        }
        state.selectedBaggages[type][index] = selectedBaggage;
      } else {
        state.selectedBaggages[type].push(selectedBaggage);
      }
    },

    setAllSelectedBaggage: (
      state,
      action: PayloadAction<TSelectedBaggage>,
    ) => {
      state = action.payload;
      return state;
    },

    resetSelectedBaggage: (state) => {
      state.selectedBaggages.depart = [];
      state.selectedBaggages.return = [];
    },

  },
});

export const { reducer: baggageReducer } = slice;
export const { setSelectedBaggage, resetSelectedBaggage, setAllSelectedBaggage } = slice.actions;
