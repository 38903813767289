import { fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query';
import axios from 'axios';
import type { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { decode } from 'jsonwebtoken';
import moment from 'moment';
import type { IAuthResponseData } from '../../utils/type.d';
import { authSession } from '../../utils/authSession';
import { getHostnameFromEnv2 } from '@/utils/getHostName';
import { RANDOM_AUTH_KEY } from '@/utils/constants';

const auth = authSession;

export const fetchAuthedQuery = (args: FetchBaseQueryArgs | undefined) => retry(fetchBaseQuery({
  ...args,
  prepareHeaders: async (headers) => {
    let token = '';
    const tokenObj = auth.getToken();
    if (tokenObj && tokenObj.access_token) {
      const decoded: any = await decode(tokenObj.access_token);
      const isValidToken = decoded && decoded.exp && moment().isBefore(moment(decoded.exp * 1000));
      if (isValidToken) {
        token = tokenObj.access_token;
      }
    }

    const env = process.env.APP_ENV;
    if (!token && env) {
      const host = env === 'development' ? getHostnameFromEnv2(env) : `https://${getHostnameFromEnv2(env)}`;
      const response = await axios.get<IAuthResponseData>(`${host}/api/getToken`,
        {
          params: { auth_key: RANDOM_AUTH_KEY },
        });
      if (response.data.access_token) {
        auth.setToken(response.data);
        token = response.data.access_token;
      }
    }

    headers.set('Authorization', `Bearer ${token}`);
    headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
}), { maxRetries: 0 });
