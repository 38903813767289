/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable @next/next/no-img-element */
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { GroupedByDateResponse } from '@/slices/flightData/apis/flightDataApi';
import FlexRowBetween from '../FlexRowBetween';
import {
  TFilterAirlineValue,
} from '../../slices/filterFlight/filterFlightSlice';
import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import FilterHeader from '../desktop/FilterHeader';
import Stack from '@mui/material/Stack';
import { isEmpty, isEqual } from 'lodash';
import { useAppSelector } from '@/utils/hook';

const dataAirlines: { code: TFilterAirlineValue; name: string }[] = [
  {
    code: 'QH',
    name: 'Bamboo Airways',
  },
  {
    code: 'VJ',
    name: 'Vietjet Air',
  },
  {
    code: 'VN',
    name: 'Vietnam Airlines',
  },
  {
    code: 'VU',
    name: 'Vietravel Airlines',
  },
  {
    code: '0V',
    name: 'Vietravel Airlines - Vasco',
  },
  // {
  //   code: 'BL',
  //   name: 'Pacific Airlines',
  // },
];

export const defaultAirlines = ['QH', 'VJ', 'VN', 'VU', 'BL', '0V'] as TFilterAirlineValue[];
const FilterAirline = ({
  airlines,
  oldAirlines,
  dateData,
  onChange,
}: {
  airlines?: TFilterAirlineValue[];
  oldAirlines?: any[];
  dateData?: GroupedByDateResponse;
  onChange?: (value: any) => any
}) => {
  const fliterAirline = useAppSelector((state) => state.filterFlight?.filter?.airline);
  const matchingAirlines = (airlines || []) as any[];
  const [airlineState, setAirlineState] = React.useState<any[]>(matchingAirlines);
  const handleChange = (e: any, type: TFilterAirlineValue) => {
    if (e.target.checked) {
      onChange?.({ airline: [...airlineState, type] });
      setAirlineState([...airlineState, type]);
    } else {
      onChange?.({ airline: airlineState.filter((al: any) => al !== type) });
      const newAirlineState = airlineState.filter((x) => x !== type);
      if (isEmpty(newAirlineState)) {
        setAirlineState(oldAirlines || matchingAirlines);
      } else {
        setAirlineState(newAirlineState);
      }
    }
  };

  useEffect(() => {
    if (JSON.stringify(airlineState) !== JSON.stringify(matchingAirlines)) {
      if (fliterAirline && fliterAirline?.length > 0) {
        setAirlineState(fliterAirline);
      } else {
        setAirlineState(matchingAirlines);
      }
    }
  }, [airlines && airlines.length, fliterAirline]);

  return (
    <Stack spacing={1}>
      <FilterHeader
        title="Hãng bay"
        type="airline"
        hideResetFilter={airlineState.length === (oldAirlines || matchingAirlines).length}
        onResetClick={() => {
          setAirlineState(matchingAirlines);
        }}
      />
      {(oldAirlines || []).map((code) => {
        const airlineObj = dataAirlines.find((obj) => obj.code === code) || {} as any;
        if (!defaultAirlines.includes(code)) return null;
        // const minFare = dateData.grouped_by_airlines[code]?.min_fare || 0;
        // if (!minFare) {
        //   return null;
        // }
        return (
          <FlexRowBetween
            key={code}
            style={{ marginBottom: pr`8px` }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                flex: 4,
              }}
            >
              <img
                src={`https://storage.googleapis.com/goyolo-uat/images/airlines/${code.toLowerCase() || 'vn'
                }.png`}
                alt=""
                width="22"
                height="22"
                className="lazyload"
              />

              <Typography
                variant="body2"
                style={{ whiteSpace: 'nowrap' }}
              >
                {airlineObj.name}
              </Typography>
            </Stack>
            <div style={{
              flex: 2,
              textAlign: 'right',
              marginRight: pr`16px`,
            }}
            />
            <div>
              <Checkbox
                checked={airlineState.includes(code)}
                onChange={(e) => {
                  handleChange(e, code);
                }}
                style={{
                  color: airlineState.includes(code) ? style.color.blue3 : '',
                  width: '16px',
                  height: '16px',
                }}
              />
            </div>
          </FlexRowBetween>
        );
      })}
    </Stack>
  );
};

export default FilterAirline;
