// import { LIST_BUS_BOOKING_SURVEY } from '@/utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TMyBookingSurveySlice = {
  code: string;
  surveyCount: number;
};

const myBookingSurveyData = [] as TMyBookingSurveySlice[];

// if (typeof window !== 'undefined') {
//   const { localStorage } = window;
//   const listBookingSurvey = localStorage.getItem(LIST_BUS_BOOKING_SURVEY);
//   myBookingSurveyData = JSON.parse(listBookingSurvey || '[]');
// }

// const setState = (state: TMyBookingSurveySlice[]) => {
//   localStorage.removeItem(LIST_BUS_BOOKING_SURVEY);
//   localStorage.setItem(LIST_BUS_BOOKING_SURVEY, JSON.stringify(state));
// };

const initialState: TMyBookingSurveySlice[] = myBookingSurveyData;

export const slice = createSlice({
  name: 'myBookingSurvey',
  initialState,
  reducers: {
    updateMyBookingSurvey: (state, action: PayloadAction<{ code: string, surveyCount: number }>) => {
      const { code, surveyCount } = action.payload;
      const iBookingSurvey = state.findIndex((busRoute) => busRoute.code === code);
      if (
        iBookingSurvey > -1
      ) {
        state[iBookingSurvey].surveyCount = surveyCount;
      } else {
        state.unshift(action.payload);
        state = state.slice(0, 30);
      }
      // setState(state);
    },
  },
});

export const { reducer: myBookingSurveyReducer } = slice;
export const {
  updateMyBookingSurvey,
} = slice.actions;
