/* eslint-disable @typescript-eslint/no-this-alias */
export default class FlightStatisticsInterval {
  timeoutId: any = null;

  isDone = false;

  isContinuousLoading = false;

  triggerTimeout() {
    const timeout = parseInt(process.env.NEXT_PUBLIC_VROUTE_FLIGHT_LOADING_TIMEOUT || '10', 10);
    if (!this.timeoutId) {
      const that = this;
      this.timeoutId = setTimeout(function () {
        that.setDone();
      }, timeout * 1000);
    }
  }

  resetTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.isDone = false;
  }

  setContinuousLoading = () => {
    this.isContinuousLoading = true;
  };

  resetContinuousLoading = () => {
    this.isContinuousLoading = false;
  };

  setDone = () => {
    this.isDone = true;
  };

  checkNew = () => !this.timeoutId;

  checkDone = () => this.isDone;

  generateInterval() {
    let pollTime = 0;
    if (!this.isDone) {
      pollTime = +(process.env.NEXT_PUBLIC_POLLING_INTERVAL_EMPTY_PAGE || '2');
    } else {
      pollTime = +(process.env.NEXT_PUBLIC_VROUTE_FLIGHT_REFRESH_INTERVAL || '30');
      const that = this;
      setTimeout(() => {
        if (that.isDone) {
          that.resetTimeout();
          that.triggerTimeout();
        }
      }, pollTime * 1000);
    }
    return pollTime * 1000;
  }
}
