import { keys, values } from 'lodash';
import { SeatClassType } from '../containers/SearchFlightTab/ChooseSeatClass/type.d';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';
import { AddonMappingReturnV2 } from './addonMapping';

export const fareClassMapping = {
  PT: [
    '.*Promo.*',
    '.*Eco.*',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'V',
    'T',
    'Y',
    'ECONOMYSAVER',
    'ECONOMYSAVERMAX',
    'ECONOMYSMART',
    'ECONOMYFLEX',
    'Bamboo Eco',
  ],
  PTDB: [
    '.*Deluxe.*',
    'U',
    'W',
    'Z',
    'PREMIUMSMART',
    'PREMIUMFLEX',
    'Bamboo Premium',
    'Economy Premium',
  ],
  TG: [
    '.*SBoss.*',
    '.*SkyBoss.*',
    'C',
    'D',
    'I',
    'J',
    'BUSINESSSMART',
    'BUSINESSFLEX',
    'Bamboo Business',
  ],
};

export const cabinMapping = {
  PT: ['economy', 'promo', 'eco', 'bamboo eco'],
  PTDB: ['special_economy', 'deluxe', 'bamboo premium', 'economy premium'],
  TG: ['business', 'skyboss', 'bamboo business'],
};

export const mappingTextFromSeatClass = (seatClass: SeatClassType, lang?: ILangObj) => {
  switch (seatClass) {
    case 'PT':
      return lang?.SearchBar?.flightsTab?.seatClass?.economy || 'Phổ Thông';
    case 'PTDB':
      return lang?.SearchBar?.flightsTab?.seatClass?.premiumEconomy || 'Phổ thông đặc biệt';
    case 'TG':
      return lang?.SearchBar?.flightsTab?.seatClass?.business || 'Thương gia';
    default:
      return lang?.SearchBar?.flightsTab?.seatClass?.economy || 'Phổ Thông';
  }
};

export const mappingTextFromSeatClassCode = (
  seatClassCode: string,
  operatingAirline: string,
  fareConfig: AddonMappingReturnV2[],
  cabin: string,
) => {
  if (operatingAirline === 'QH') {
    return fareConfig.find(
      (fc) => fc.cabin === cabin
        && (fc.operating_airline === operatingAirline)
        && (fc?.list_class_same_config?.map((c) => c.toUpperCase())?.includes(seatClassCode?.toUpperCase())),
    )?.seat_class || 'Phổ Thông';
  }

  return fareConfig.find(
    (fc) => (fc?.list_class_same_config?.map((c) => c.toUpperCase())?.includes(seatClassCode?.toUpperCase()))
      && (fc.operating_airline === operatingAirline),
  )?.seat_class || 'Phổ Thông';
};

export const fareClassDecode = (key: string) => {
  let type = '';
  values(fareClassMapping).forEach((x, index) => {
    if (x.includes(key) || x.includes(key?.toUpperCase())) { type = keys(fareClassMapping)?.[index]; }
  });

  return type;
};
