import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '@/slices/common/fetchAuthedQuery';
import {
  IOrder,
  IReserveddOrderResult,
  IOrderDetail,
  IGetOrdersStatusResponse,
  IGetOrdersStatusBody,
} from '.';

export const busOrderApi = createApi({
  reducerPath: 'busOrderApi',
  baseQuery: fetchAuthedQuery({
    baseUrl: `${process.env.GOM_SERVICE_URL}`,
  }),
  endpoints: (builder) => ({

    orderReserve: builder.mutation<IReserveddOrderResult, Partial<IOrder>>({
      query: (body) => ({
        url: '/v1/order/reserve',
        method: 'POST',
        body,
      }),
    }),

    getOrderByCode: builder.query<IOrderDetail[], any>({
      query: ({ code, id }) => {
        if (id) {
          return `/v1/order?id=${id}&is_get_all_same_base_code=1`;
        }
        if (code) {
          return `/v1/order?code=${code}&is_get_all_same_base_code=1`;
        }
        return '';
      },
    }),

    getOrdersStatus: builder.query<IGetOrdersStatusResponse[], IGetOrdersStatusBody>({
      query: (body) => ({
        url: '/v1/order/list-status',
        method: 'POST',
        body,
      }),

      keepUnusedDataFor: 1,
    }),

  }),
});

export const {
  useOrderReserveMutation,
  useLazyGetOrderByCodeQuery,
  useGetOrdersStatusQuery,
} = busOrderApi;
