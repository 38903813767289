import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITripDetail{
  seatSelected: any[],
  seatSelectedReturn: any[],
  currentTab?: string
}

const initialState: ITripDetail = {
  seatSelected: [],
  seatSelectedReturn: [],
};

export const tripDetailSlice = createSlice({
  name: 'busTripDetail',
  initialState,
  reducers: {
    addSeat: (state, action: PayloadAction<any[]>) => {
      state.seatSelected = action.payload;
    },
    addSeatReturn: (state, action: PayloadAction<any[]>) => {
      state.seatSelectedReturn = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
  },
});

export const tripDetailReducer = tripDetailSlice.reducer;
export const { addSeat, addSeatReturn, setCurrentTab } = tripDetailSlice.actions;
