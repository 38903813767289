export const getHostnameFromEnv = (env: string) => {
  let hostName = '';
  switch (env) {
    case 'development':
      hostName = 'uat-goyolo.vexere.net';
      break;
    case 'uat':
      hostName = 'uat-goyolo.vexere.net';
      break;
    case 'production':
      hostName = 'goyolo.com';
      break;
    case 'canary':
      hostName = 'canary-goyolo.vexere.com';
      break;
    default:
      hostName = 'goyolo.com';
      break;
  }
  return hostName;
};

export const getHostnameFromEnv2 = (env: string) => {
  let hostName = '';
  switch (env) {
    case 'development':
      hostName = 'http://localhost:8080';
      break;
    case 'uat':
      hostName = 'uat-goyolo.vexere.net';
      break;
    case 'production':
      hostName = 'goyolo.com';
      break;
    case 'canary':
      hostName = 'canary-goyolo.vexere.com';
      break;
    default:
      hostName = 'goyolo.com';
      break;
  }
  return hostName;
};

export const getHostnameFromSeo = (env: string) => {
  let host: { hostName?: string, slug?: string } = {};
  switch (env) {
    case 'development':
      host = {
        hostName: 'http://localhost:8080',
        slug: 'vex/',
      };
      break;
    case 'uat':
      host = {
        hostName: 'https://uat-goyolo.vexere.net',
        slug: 'vex/',
      };
      break;
    case 'production':
      host = {
        hostName: 'https://goyolo.com',
        slug: 'vex/',
      };
      break;
    case 'canary':
      host = {
        hostName: 'https://goyolo.com',
        slug: 'vex/',
      };
      break;
    default:
      host = {
        hostName: 'https://uat-fe.vexere.net',
        slug: '',
      };
      break;
  }
  return host;
};
