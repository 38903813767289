export type TPayInternalRequest = {
  phone: string,
  email: string,
  value: number,
  code: string,
  bankcode: string,
  payment_gateway: string,
  vxr_payment_type?: number,
  redirect_url?: string;
};

export type TPayRequest = TPayInternalRequest & {
  source: string,
  redirect_url: string,
  language: string,
  merchant_name: 'goyolo',
  client_id: string
};

export type TPayResult = {
  order: TPayOrder
  payment: TPayPayment,
};

export type TPayOrder = {
  id: number,
  status: string,
  app_tx_id: string,
  email: string,
  phone: string,
  value: number,
  source: string,
  redirect_url: string,
  vxr_payment_type: number,
  payment_gateway: string,
  bankcode: string | null,
  created_date: string,
  updated_date: string,
  client_id: string,
  merchant_name: 'goyolo'
};

export type TPayPayment = {
  cyberParams: { [key: string]: any },
  payUrl: string,
  redirect_url: string
};

export const paySource = {
  WEB: 'web',
  APP: 'app',
};

export const language = {
  VN: 'vi-VN',
};

export const payOrderStatus = {
  PAYMENT_PENDING: 'payment_pending',
};

export type TOrderParamsRequest = {
  order_code?: string;
  order_id?: number
  isNotFilterPaid?: boolean;
};
export type TOrderResult = {
  order: {
    id?: number;
    status?: string;
    merchant_name: string;
    created_date: string,
    updated_date: string,
    value: number;
    bankcode: string;
    email: string;
    phone: string;
    code: string;
    payment_gateway: string,
  }
};
