import { PAY_TIMER_LIMIT, BUS_PAY_TIMER_LIMIT } from '@/utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { PayMethod, BankProps } from '.';

export interface IPayMethodState {
  methods: PayMethod[];
  methodSelected: string | number | null;
  bankSelected: BankProps | null;
  timer: number;
  bankCode?: string;
  busTimer: number;
  paymentGateways: Record<string, { key: string; label: string }>;
}
// const isProd = process.env.APP_ENV === 'production';
export const BANKING_TRANSFER_ID = 3;
export const payMethods = [
  {
    id: 1,
    name: 'Thẻ tín dụng/Thẻ ghi nợ',
    image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/payment/card.png`,
    description: 'Visa, MasterCard, JCB',
    paymentGateway: 'cybersource',
    code: 'VISA_GOYOLO',
  },
  {
    id: 2,
    name: 'Thẻ ATM nội địa/Internet Banking',
    image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/payment/atm.png`,
    description: 'Thẻ đã đăng ký Internet Banking',
    paymentGateway: 'onepay',
    bankcode: 'DOMESTIC',
    code: 'IB_GOYOLO',
  },
  {
    id: BANKING_TRANSFER_ID,
    name: 'Chuyển khoản ngân hàng',
    image: 'https://storage.googleapis.com/fe-production/httpImage/bank_transfer.svg',
    description: 'Chuyển đến tài khoản của Goyolo',
    paymentGateway: 'bank_transfer',
    code: 'B_GOYOLO',
  },
  {
    id: 4,
    name: 'Ví Momo',
    image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/payment/momo-logo.png`,
    description: 'Bạn phải có tài khoản Momo',
    paymentGateway: 'momo',
    code: 'MOMO_PAY_APP_GOYOLO',
  },
  {
    id: 5,
    name: 'Ví Zalopay',
    image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/payment/zalo.png`,
    description: 'Bạn phải có tài khoản Zalo Pay',
    paymentGateway: 'zalo',
    bankcode: 'zalopayapp',
    code: 'ZALO_PAY_APP_GOYOLO',
  },
  {
    id: 6,
    name: 'Ví ShopeePay',
    image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/payment/shoppe.png`,
    description: 'Bạn phải có tài khoản Shopee Pay',
    paymentGateway: 'airpay',
    bankcode: 'airpay',
    code: 'AIR_PAY_GOYOLO',
  },
  // {
  //   id: 7,
  //   name: 'Ví Moca',
  //   image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/payment/moca.png`,
  //   description: 'Bạn phải có tài khoản Moca',
  //   paymentGateway: 'moca',
  //   code: 'MOCA_GOYOLO',
  // },
  {
    id: 8,
    name: 'VNPAY',
    image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/payment/vnpay.png`,
    description: 'Thanh toán qua Mobile Banking',
    paymentGateway: 'vnpay',
    bankcode: 'VNPAYQR',
    code: 'VNPAY_APP_GOYOLO',
  },
  {
    id: 100,
    name: 'Thanh toán tại nhà xe',
    image: '/images/bus-pay-at-store.png',
    description: 'Thanh toán cho tài xế khi lên xe hoặc tại văn phòng nhà xe ngay trước giờ khởi hành',
    paymentGateway: 'pay_later',
    bankcode: '',
    code: '',
  },
  // {
  //   id: 8,
  //   name: 'Chuyển khoản ngân hàng',
  //   image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/payment/banktranfer.png`,
  //   description: 'Bạn chuyển khoản đến tài khoản Goyolo. Sẽ mất vài phút để xác nhận thanh toán',
  //   paymentGateway: 'pay_later',
  // },
  // { temporary disabled
  //   id: 8,
  //   name: 'Chuyển khoản ngân hàng',
  //   image: '/images/bank.png',
  //   description: '',
  //   paymentGateway: 'bank',
  // },
  // {
  //   id: 9,
  //   name: 'Thanh toán tại cửa hàng',
  //   image: '/images/store.png',
  //   description: '',
  //   paymentGateway: 'pay_later',
  // },
] as PayMethod[];

export const payMethodsKey = keyBy(payMethods, 'id');

export const slice = createSlice({
  name: 'payMethod',
  initialState: {
    methods: payMethods,
    methodSelected: null,
    bankSelected: null,
    timer: PAY_TIMER_LIMIT,
    busTimer: BUS_PAY_TIMER_LIMIT,
    paymentGateways: {
      cybersource: {
        key: 'cybersource',
        label: 'Thẻ tín dụng/Thẻ ghi nợ',
      },
      onepay: {
        key: 'onepay',
        label: 'Thẻ ATM nội địa/Internet Banking',
      },
      momo: {
        key: 'momo',
        label: 'Ví Momo',
      },
      zalo: {
        key: 'zalo',
        label: 'Ví Zalopay',
      },
      airpay: {
        key: 'airpay',
        label: 'Ví ShopeePay',
      },
      // moca: {
      //   key: 'moca',
      //   label: 'Ví Moca',
      // },
      vnpay: {
        key: 'vnpay',
        label: 'VNPay',
      },
      paylayter: {
        key: 'pay_later',
        label: 'Tại nhà xe',
      },
      bank_transfer: {
        key: 'bank_transfer',
        label: 'Chuyển khoản',
      },
    },
  } as IPayMethodState,
  reducers: {
    selectMethodSaveLocal: (state, action: PayloadAction<{ id: string }>) => {
      state.methodSelected = action?.payload?.id;
      if (+action.payload.id !== BANKING_TRANSFER_ID) {
        state.bankSelected = null;
      }
      // window.localStorage.setItem(
      //   'LASTED_PAYMENT_METHOD',
      //   String(action?.payload?.id),
      // );
    },
    selectMethod: (state, action: PayloadAction<{ id: string | null }>) => {
      state.methodSelected = action?.payload?.id;
      if (!state.methodSelected && state.bankSelected) {
        state.bankSelected = null;
      }
    },
    selectBankSaveLocal: (
      state,
      action: PayloadAction<{ item: BankProps }>,
    ) => {
      state.bankSelected = action?.payload?.item;
      // window.localStorage.setItem(
      //   'LASTED_BANK_SELECTED',
      //   String(action?.payload?.item),
      // );
    },
    selectBank: (state, action: PayloadAction<{ item: BankProps }>) => {
      state.bankSelected = action?.payload?.item;
    },
    selectBankCode: (state, action: PayloadAction<{ bankCode?: string }>) => {
      state.bankCode = action?.payload.bankCode;
    },
    setTimer: (state, action: PayloadAction<{ timer: number, vehicleType?: 'bus' | 'flight' | undefined }>) => {
      const { timer, vehicleType } = action.payload;
      if (vehicleType === 'bus') {
        state.busTimer = timer;
      } else {
        state.timer = timer;
      }
    },
    resetTimer: (state) => {
      state.timer = PAY_TIMER_LIMIT;
      state.busTimer = BUS_PAY_TIMER_LIMIT;
    },
  },
});

export const { reducer: payMethodReducer } = slice;
export const {
  selectMethodSaveLocal,
  selectMethod,
  setTimer,
  selectBankCode,
  resetTimer,
  selectBankSaveLocal,
  selectBank,
} = slice.actions;
