import { isEmpty } from 'lodash';
import { IToken } from './type.d';

const tokenKey = 'accessToken';

function getTokenFromSession() {
  if (typeof window === 'undefined') {
    return null;
  }
  const tokenStr = sessionStorage.getItem(tokenKey);
  const token = tokenStr ? JSON.parse(tokenStr) as IToken : null;
  return token;
}
function setTokenToSession(token: IToken) {
  if (typeof window === 'undefined' || !token) {
    return;
  }
  sessionStorage.setItem(tokenKey, JSON.stringify(token));
}

export const authSession = {
  isTokenValid() {
    const token = getTokenFromSession();
    return !!token?.access_token;
  },
  setToken(token: IToken) {
    if (isEmpty(token)) {
      return;
    }
    setTokenToSession(token);
  },
  getToken() {
    return getTokenFromSession();
  },
  clear() {
    setTokenToSession({
      access_token: '',
      refresh_token: '',
      expires_in: 0,
    });
  },
};
