import { BusRouteSchedule } from '@/slices/bus/busData/slices/type';

export const getBusRoutePrice = (schedule?: BusRouteSchedule | null) => {
  const { fare } = schedule || {};
  if (!fare) {
    return null;
  }
  const { original, discount: discountPrice } = fare;
  const discount = discountPrice > 0 ? discountPrice : 0;
  const price = discount || original;
  const discountAmount = (original - (price || 0));
  return {
    price,
    originalPrice: original,
    discountAmount,
  };
};
