import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const userAdapter = createEntityAdapter();
const initialState = userAdapter.getInitialState({
  loading: false,
  count: 0,
});

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeUser: userAdapter.removeOne,
    incrementCount: (state) => {
      state.count += 1;
    },
  },
});

export const { reducer: userReducer } = slice;
export const { removeUser } = slice.actions;
