/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../utils/store';
import { TFareRule } from './type';
import { AddonMappingReturnV2, RefundPolicyConfigItem } from '@/utils/addonMapping';

type ConfigState = {
  fareConfig: AddonMappingReturnV2[];
  refundPolicyConfig: RefundPolicyConfigItem[];
};

const initialState: ConfigState = {
  fareConfig: [],
  refundPolicyConfig: [],
};

export const slice = createSlice({
  name: 'fareRule',
  initialState,
  reducers: {
    addFareConfig: (state, action: PayloadAction<any>) => {
      state.fareConfig = action.payload?.dataFareConfig;
      state.refundPolicyConfig = action.payload?.dataRefundPolicy;
      return state;
    },
  },
});

export const { reducer: fareRuleReducer } = slice;
export const { addFareConfig } = slice.actions;
