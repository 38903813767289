/* eslint-disable max-len */
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '../../common/fetchAuthedQuery';
import {
  TSurveyDimensionResult, TParamsSubmitSurvey, TResult, TCheckSurveyResult,
} from '.';

export const surveyApi = createApi({
  reducerPath: 'surveyApi',
  baseQuery: fetchAuthedQuery({
    baseUrl: `${process.env.USER_PROFILE_URL}`,
  }),
  endpoints: (builder) => ({
    getFormSurveyDimension: builder.query<TSurveyDimensionResult[], void>({
      query: () => '/sub-survey-dimension',
      transformResponse: (response: { items: TSurveyDimensionResult[] }) => (response.items || []).sort((a, b) => a?.priority - b?.priority),
      keepUnusedDataFor: 600,
    }),
    checkSurvey: builder.query<TCheckSurveyResult[], string>({
      query: (bookingCode) => `/survey/check?bookingCode=${bookingCode}`,
      transformResponse: (response: { data: TCheckSurveyResult[] }) => response.data,
      keepUnusedDataFor: 600,
    }),
    submitSurvey: builder.mutation<TResult, TParamsSubmitSurvey>({
      query: (body) => ({
        url: '/survey',
        method: 'POST',
        body: {
          ...body,
        },
      }),
    }),
  }),
});

export const {
  useGetFormSurveyDimensionQuery,
  useCheckSurveyQuery,
  useSubmitSurveyMutation,
} = surveyApi;
