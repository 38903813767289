import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthedQuery } from '../../common/fetchAuthedQuery';
import { Promotion } from './type';

export const vConfiguration = createApi({
  reducerPath: 'vConfiguration',
  baseQuery: fetchAuthedQuery({
    baseUrl: `${process.env.VCONFIGURATION}`,
  }),
  endpoints: (builder) => ({
    getListPromotions: builder.query<Promotion[], void>({
      query: () => '/cms_config/payment_method',
      transformResponse: (response: { data: Promotion[] }) => response.data,
      keepUnusedDataFor: 600,
    }),
  }),
});

export const { useGetListPromotionsQuery } = vConfiguration;
