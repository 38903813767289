/* eslint-disable import/no-cycle */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../utils/store';
import type { Contact } from './index.d';

export const contactAdapter = createEntityAdapter<Contact>();
const initialState = contactAdapter.getInitialState();

export const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    removeContact: contactAdapter.removeOne,
  },
});

export const { reducer: contactReducer } = slice;
export const {
  selectById: selectContactById,
  selectIds: selectContactIds,
  selectEntities: selectContactEntities,
  selectAll: selectAllContacts,
  selectTotal: selectTotalContacts,
} = contactAdapter.getSelectors((state: RootState) => state.contact);
