import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
// import { isAfter, sub } from 'date-fns';
import {
  BookingFlightStatusEnum,
  TMyBookingSlice,
  TMyFlightBooking,
  TMyFlightBookingInfo,
} from './type';

const myBookingData: TMyBookingSlice = {
  flights: [],
};

const initialState: TMyBookingSlice = myBookingData;
export const slice = createSlice({
  name: 'myBooking',
  initialState,
  reducers: {
    addFlightToMyBooking: (
      state,
      actionOriginal: PayloadAction<TMyFlightBooking>,
    ) => {
      const action = JSON.parse(JSON.stringify(actionOriginal));
      const { bookingInfo, id } = action.payload;
      if (!action.payload.id) {
        action.payload.id = get(action, 'payload.paymentQueryPages.id');
      }
      const existFlight = checkFlightExists(state.flights, {
        id: action.payload.id,
        code: action.payload.bookingCode,
      });
      if (existFlight) return;

      if (bookingInfo.status === BookingFlightStatusEnum.ISSUED) {
        state.flights = state.flights.filter((flight) => flight.id !== id);
      }
      state.flights = state.flights.slice(0, 10);
      action.payload.flightsData.flightId = action.payload.flightsData.flight?.idIndex;
      action.payload.flightsData.returnFlightId = action.payload.flightsData.returnFlight?.idIndex;
      state.flights.unshift(action.payload);
      // state.flights = state.flights.filter((flight) => isAfter(new Date(get(flight, 'flightsData.flight.route.departureDate') || null), sub(new Date(), { days: 3 })));
    },
    updateExpireFlight: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const updateFlight = state.flights.find((flight) => flight.id === id);
      if (
        updateFlight?.bookingInfo.status === BookingFlightStatusEnum.PENDING
      ) {
        updateFlight.bookingInfo = {
          ...updateFlight.bookingInfo,
          status: BookingFlightStatusEnum.CANCEL,
        };
      }
    },

    updatePendingFlightToNotIssued: (
      state,
      action: PayloadAction<{ id?: number; code?: string }>,
    ) => {
      const updateFlight = checkFlightExists(
        state.flights,
        action.payload,
        BookingFlightStatusEnum.PENDING,
      );

      if (updateFlight) {
        updateFlight.bookingInfo = {
          status: BookingFlightStatusEnum.NOT_ISSUED,
        };
      }
    },

    updateFlightToIssued: (
      state,
      action: PayloadAction<{ id?: number; code?: string }>,
    ) => {
      const updateFlight = checkFlightExists(
        state.flights,
        action.payload,
        BookingFlightStatusEnum.PENDING,
      );

      if (updateFlight) {
        updateFlight.bookingInfo = {
          status: BookingFlightStatusEnum.NOT_ISSUED,
        };
      }
    },

    updateIssuedFlight: (state, action: PayloadAction<{ ids: number[] }>) => {
      const { ids } = action.payload;

      const updateFlights = state.flights.filter((flight) => ids.includes(flight.id));

      updateFlights.forEach((updateFlight) => {
        updateFlight.bookingInfo = {
          status: BookingFlightStatusEnum.ISSUED,
        };
      });

      // if (updateFlights.length > 0) {
      // }
    },
    updatePaymentGateway: (
      state,
      action: PayloadAction<{
        id?: number;
        code?: string;
        paymentGateway: string;
      }>,
    ) => {
      const { paymentGateway, id } = action.payload;

      const updateFlight = state.flights.find(
        (flight) => flight.id.toString() === id?.toString(),
      );

      if (updateFlight) {
        updateFlight.paymentGateway = paymentGateway;
      }
    },
    updateBankCode: (
      state,
      action: PayloadAction<{
        id: number;
        code?: string;
        bankCode: string;
      }>,
    ) => {
      const { bankCode, id } = action.payload;

      const updateFlight = state.flights.find(
        (flight) => flight.id.toString() === id?.toString(),
      );

      if (updateFlight) {
        updateFlight.bankCode = bankCode;
      }
    },
    updateAlreadyPaid: (
      state,
      action: PayloadAction<{
        id: any;
        isAlreadyPaid: boolean;
      }>,
    ) => {
      const { isAlreadyPaid, id } = action.payload;

      const updateFlight = state.flights.find(
        (flight) => flight.id?.toString() === id?.toString()
          || flight.bookingCode?.toString() === id?.toString(),
      );
      if (updateFlight) {
        updateFlight.isComfirmAlreadyPaid = isAlreadyPaid;
      }
    },
    updateTicketStatus: (
      state,
      action: PayloadAction<{
        id: any;
        info: TMyFlightBookingInfo;
      }>,
    ) => {
      const { info, id } = action.payload;

      const updateFlight = state.flights.find(
        (flight) => flight.id?.toString() === id?.toString()
          || flight.bookingCode?.toString() === id?.toString(),
      );
      if (updateFlight) {
        updateFlight.bookingInfo = info;
      }
      return state;
    },

    updateSurvey: (
      state,
      action: PayloadAction<{
        surveyCount: number;
        bookingId: number | undefined;
      }>,
    ) => {
      const updateBooking = state.flights.map((booking) => {
        if (booking.id === action?.payload.bookingId) {
          return {
            ...booking,
            surveyCount: action?.payload.surveyCount,
          };
        }
        return booking;
      });

      state.flights = updateBooking;
    },
    addUnloadedFlight: (
      state,
      action: PayloadAction<{ flight: any; unloadedFlightId?: string }>,
    ) => {
      const unloadedId = action.payload.unloadedFlightId;
      state.flights.every((flight) => {
        if (flight.flightsData.flightId === unloadedId) {
          flight.flightsData.flight = action.payload.flight;
          return true;
        }
        if (flight.flightsData.returnFlightId === unloadedId) {
          flight.flightsData.returnFlight = action.payload.flight;
          return true;
        }
        return false;
      });
    },
    deleteMyBookingFlight: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const index = state.flights.findIndex(
        (flight) => flight?.id?.toString() === id,
      );
      if (index !== -1) {
        state.flights.splice(index, 1);
      }
      return state;
    },
  },
});

const checkFlightExists = (
  flights: any[],
  { id, code }: { id?: number; code?: string },
  checkingStatus?: any,
) => {
  if (!id && !code) {
    return false;
  }
  return flights.find((flight) => {
    const queryId = get(flight, 'paymentQueryPages.id');
    const queryCode = get(flight, 'paymentQueryPages.code');
    if (checkingStatus) {
      return (
        get(flight, 'bookingInfo.status') === checkingStatus
        && ((queryId && queryId.toString() === id?.toString())
          || (queryCode && queryCode === code))
      );
    }
    return (
      (queryId && queryId.toString() === id?.toString())
      || (queryCode && queryCode === code)
    );
  });
};

export const { reducer: myBookingReducer } = slice;
export const {
  addFlightToMyBooking,
  updateExpireFlight,
  updateAlreadyPaid,
  updateIssuedFlight,
  updatePaymentGateway,
  updatePendingFlightToNotIssued,
  updateSurvey,
  addUnloadedFlight,
  updateBankCode,
  updateTicketStatus,
  deleteMyBookingFlight,
} = slice.actions;
